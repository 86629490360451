import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'
import {getRequest} from 'relay-runtime'

import {makeResource} from '../../../../utils/makeResource'
import {pipelineHeadQuery} from '../PipelinesPages.queries'

import type {EditPipelinePageComponent} from './EditPipelinePage'

export const EditPipelinePageEntryPoint: EntryPoint<EditPipelinePageComponent, LoaderFunctionArgs> =
  {
    root: makeResource(
      'EditPipelinePage',
      () =>
        import(
          /* webpackChunkName: "EditPipelinePage", webpackPrefetch: true */ './EditPipelinePage'
        ),
    ),
    getPreloadProps: ({params}) => ({
      queries: {
        pipelineHead: {
          parameters: getRequest(pipelineHeadQuery),
          variables: {locator: `internalId:${params.id}`},
          options: {networkCacheConfig: {metadata: {essential: true}}},
        },
      },
    }),
  }
