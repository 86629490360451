/**
 * @generated SignedSource<<b6f445f6a57f3aefd0fbb8580f33bd71>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type OSType = "FreeBSD" | "Linux" | "Solaris" | "Unix" | "Unknown" | "Windows" | "macOS";
export type AgentsPagesNativeAgentTypesQuery$variables = {
  skip?: boolean | null | undefined;
};
export type AgentsPagesNativeAgentTypesQuery$data = {
  readonly agentTypes?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly agentPool: {
          readonly name: string | null | undefined;
          readonly rawId: string;
        };
        readonly cloudImage: {
          readonly id: string;
          readonly name: string | null | undefined;
        } | null | undefined;
        readonly environment: {
          readonly os: {
            readonly type: OSType;
          } | null | undefined;
        } | null | undefined;
        readonly isCloud: boolean;
        readonly name: string | null | undefined;
        readonly rawId: string;
      };
    }>;
  } | null | undefined;
};
export type AgentsPagesNativeAgentTypesQuery = {
  response: AgentsPagesNativeAgentTypesQuery$data;
  variables: AgentsPagesNativeAgentTypesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": true,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rawId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCloud",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "CloudImage",
  "kind": "LinkedField",
  "name": "cloudImage",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "AgentEnvironment",
  "kind": "LinkedField",
  "name": "environment",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OS",
      "kind": "LinkedField",
      "name": "os",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentsPagesNativeAgentTypesQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AgentTypesConnection",
            "kind": "LinkedField",
            "name": "agentTypes",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AgentTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AgentType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "agentPool",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AgentsPagesNativeAgentTypesQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AgentTypesConnection",
            "kind": "LinkedField",
            "name": "agentTypes",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AgentTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AgentType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "agentPool",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v1/*: any*/),
                          (v2/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "ac2c11428e691c7a8dae6a69789f71a4",
    "id": null,
    "metadata": {},
    "name": "AgentsPagesNativeAgentTypesQuery",
    "operationKind": "query",
    "text": "query AgentsPagesNativeAgentTypesQuery(\n  $skip: Boolean = true\n) {\n  agentTypes @skip(if: $skip) {\n    edges {\n      node {\n        rawId\n        name\n        isCloud\n        agentPool {\n          __typename\n          rawId\n          name\n          id\n        }\n        cloudImage {\n          id\n          name\n        }\n        environment {\n          os {\n            type\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7e910e883ec20c29e1d139448fc8acd6";

export default node;
