/**
 * @generated SignedSource<<e77f8585b0825937bb3373109de3276b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuildApprovalInlineQuery$variables = {
  locator: string;
};
export type BuildApprovalInlineQuery$data = {
  readonly approvalInfo: {
    readonly status: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"BuildApprovalControls_approvalInfo" | "BuildApprovalReviewers_approvalInfo" | "BuildApprovalReviewers_canViewApprovalInfo">;
  } | null | undefined;
};
export type BuildApprovalInlineQuery = {
  response: BuildApprovalInlineQuery$data;
  variables: BuildApprovalInlineQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locator"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "buildLocator",
    "variableName": "locator"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "username",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "rawId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "UserAvatars",
    "kind": "LinkedField",
    "name": "avatars",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "urlToSize20",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "urlToSize28",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "urlToSize32",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "urlToSize40",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "urlToSize56",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "urlToSize64",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "urlToSize80",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BuildApprovalInlineQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ApprovalInfo",
        "kind": "LinkedField",
        "name": "approvalInfo",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BuildApprovalReviewers_canViewApprovalInfo"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BuildApprovalReviewers_approvalInfo"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BuildApprovalControls_approvalInfo"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BuildApprovalInlineQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ApprovalInfo",
        "kind": "LinkedField",
        "name": "approvalInfo",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "configurationValid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserApprovals",
            "kind": "LinkedField",
            "name": "userApprovals",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserApprovalRule",
                "kind": "LinkedField",
                "name": "userApproval",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "approved",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GroupApprovals",
            "kind": "LinkedField",
            "name": "groupApprovals",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GroupApprovalRule",
                "kind": "LinkedField",
                "name": "groupApproval",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Group",
                    "kind": "LinkedField",
                    "name": "group",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "key",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "requiredApprovalsCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Users",
                    "kind": "LinkedField",
                    "name": "currentlyApprovedBy",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": true,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canBeApprovedByCurrentUser",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timeoutTimestamp",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cd147cc0dfce8d498afec1929102ed79",
    "id": null,
    "metadata": {},
    "name": "BuildApprovalInlineQuery",
    "operationKind": "query",
    "text": "query BuildApprovalInlineQuery(\n  $locator: String!\n) {\n  approvalInfo(buildLocator: $locator) {\n    status\n    ...BuildApprovalReviewers_canViewApprovalInfo\n    ...BuildApprovalReviewers_approvalInfo\n    ...BuildApprovalControls_approvalInfo\n  }\n}\n\nfragment AvatarFragment on User {\n  rawId\n  name\n  username\n  avatars {\n    urlToSize20\n    urlToSize28\n    urlToSize32\n    urlToSize40\n    urlToSize56\n    urlToSize64\n    urlToSize80\n  }\n}\n\nfragment BuildApprovalControls_approvalInfo on ApprovalInfo {\n  canBeApprovedByCurrentUser\n  status\n  timeoutTimestamp\n}\n\nfragment BuildApprovalReviewers_approvalGroups on GroupApprovals {\n  groupApproval {\n    group {\n      key\n    }\n    ...BuildApprovalReviewers_groupApproval\n  }\n}\n\nfragment BuildApprovalReviewers_approvalInfo on ApprovalInfo {\n  userApprovals {\n    ...BuildApprovalReviewers_groupedByStatusApprovals\n  }\n  groupApprovals {\n    ...BuildApprovalReviewers_approvalGroups\n  }\n}\n\nfragment BuildApprovalReviewers_approvalUser on User {\n  name\n  username\n  rawId\n  ...AvatarFragment\n}\n\nfragment BuildApprovalReviewers_canViewApprovalInfo on ApprovalInfo {\n  configurationValid\n  userApprovals {\n    count\n  }\n  groupApprovals {\n    count\n  }\n}\n\nfragment BuildApprovalReviewers_groupApproval on GroupApprovalRule {\n  group {\n    name\n  }\n  requiredApprovalsCount\n  currentlyApprovedBy {\n    user {\n      id\n      ...BuildApprovalReviewers_approvalUser\n    }\n  }\n}\n\nfragment BuildApprovalReviewers_groupedByStatusApprovals on UserApprovals {\n  userApproval {\n    approved\n    user {\n      id\n      ...BuildApprovalReviewers_approvalUser\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "891200237aca540fc0c2e4f6abcc896a";

export default node;
