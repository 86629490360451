import type {Pager} from './Pager.types'

const itemsOnPage = (page: number, pager: Pager): number =>
  Math.min(pager.pageSize, pager.total - (page - 1) * pager.pageSize)

export const hasItemsOnPage = (page: number, pager: Pager): boolean => itemsOnPage(page, pager) > 0
export const getOffset = (pager: Pager, page: number): number =>
  Math.max(0, page - 1) * pager.pageSize
export const getLastPageToCount = (pager: Pager, page: number): number =>
  page - 1 + (pager.precountedPages ?? 1)
export const needsLoadMore = (page: number, pager: Pager): boolean => {
  const {hasMore, total, pageSize} = pager
  const lastPage = Math.ceil(total / pageSize)
  return Boolean(hasMore) && page > lastPage
}
