import {createAction} from '@reduxjs/toolkit'

import type {NormalizedBuilds, NormalizedProjects} from '../rest/schemata'
import type {GetAllBuildsNormalizedApiArg, GetAllProjectsNormalizedApiArg} from '../services/rest'

const generateSWActionType = (name: string) => `${name}_SW`
const generateDefaultMeta = <T>(originalArgs: T = {} as T) => ({arg: {originalArgs}})
export const getAllProjectsNormalized = createAction(
  generateSWActionType('getAllProjectsNormalized'),
  (payload: NormalizedProjects, originalArgs: GetAllProjectsNormalizedApiArg) => ({
    payload,
    meta: generateDefaultMeta<GetAllProjectsNormalizedApiArg>(originalArgs),
  }),
)

export const getAllBuildsNormalized = createAction(
  generateSWActionType('getAllBuildsNormalized'),
  (payload: NormalizedBuilds, originalArgs: GetAllBuildsNormalizedApiArg) => ({
    payload,
    meta: generateDefaultMeta<GetAllBuildsNormalizedApiArg>(originalArgs),
  }),
)
