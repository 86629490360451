import {branch, mapProps, renderNothing} from 'recompose'

import compose from 'lodash/flowRight'
import type {ComponentType} from 'react'
import {connect} from 'react-redux'

import type {DialogProps} from '../../../../hocs/withDialogContainer'
import withDialogContainer from '../../../../hocs/withDialogContainer'
import type {State} from '../../../../reducers/types'
import {getBuildType, getIsSakuraUI, getProject} from '../../../../selectors'
import {DialogType, stringifyId} from '../../../../types'
import type {BuildTypeId, Id, ProjectInternalId} from '../../../../types'
import InvestigationHistoryPopup from '../InvestigationHistoryPopup/InvestigationHistoryPopup'

type OwnProps = {
  id: Id | null | undefined
  buildTypeId: BuildTypeId | null | undefined
}
type StateProps = {
  projectInternalId: ProjectInternalId | null | undefined
  isSakuraUI?: boolean
}
type OwnPropsDummy = OwnProps & StateProps

const mapStateToProps: (arg0: State, arg1: OwnProps) => StateProps = (state, {buildTypeId}) => {
  const buildType = getBuildType(state, buildTypeId)
  return {
    projectInternalId: getProject(state, buildType?.projectId)?.internalId,
    isSakuraUI: getIsSakuraUI(state),
  }
}

export const BuildTypeInvestigationHistoryPopupDummy: ComponentType<OwnPropsDummy> = compose(
  withDialogContainer(DialogType.INVESTIGATION_HISTORY, (state, props: OwnPropsDummy) =>
    stringifyId(props.id),
  ),
  mapProps(({id, ...restProps}: DialogProps<OwnPropsDummy>) => ({
    ...restProps,
    locator: `buildTypeId=${stringifyId(restProps.buildTypeId)}`,
  })),
  branch((props: DialogProps<OwnPropsDummy>) => !props.isOpened, renderNothing),
)(InvestigationHistoryPopup)
export default connect(mapStateToProps, {})(BuildTypeInvestigationHistoryPopupDummy)
