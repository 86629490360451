/**
 * @generated SignedSource<<c7a2e3c322b8391c7cc9b4883e068420>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BuildArtifactStorageInfoFeatureIdQuery$variables = {
  buildLocator: string;
};
export type BuildArtifactStorageInfoFeatureIdQuery$data = {
  readonly build: {
    readonly resultingProperties: {
      readonly property: ReadonlyArray<{
        readonly name: string | null | undefined;
        readonly value: string | null | undefined;
      }> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type BuildArtifactStorageInfoFeatureIdQuery = {
  response: BuildArtifactStorageInfoFeatureIdQuery$data;
  variables: BuildArtifactStorageInfoFeatureIdQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "buildLocator"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "buildLocator",
    "variableName": "buildLocator"
  }
],
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "locator",
      "value": "name:teamcity.storage.feature.id"
    }
  ],
  "concreteType": "Properties",
  "kind": "LinkedField",
  "name": "resultingProperties",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Property",
      "kind": "LinkedField",
      "name": "property",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "resultingProperties(locator:\"name:teamcity.storage.feature.id\")"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BuildArtifactStorageInfoFeatureIdQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Build",
        "kind": "LinkedField",
        "name": "build",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BuildArtifactStorageInfoFeatureIdQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Build",
        "kind": "LinkedField",
        "name": "build",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cd3359e137082cdd3d52ad641be6184a",
    "id": null,
    "metadata": {},
    "name": "BuildArtifactStorageInfoFeatureIdQuery",
    "operationKind": "query",
    "text": "query BuildArtifactStorageInfoFeatureIdQuery(\n  $buildLocator: String!\n) {\n  build(buildLocator: $buildLocator) {\n    resultingProperties(locator: \"name:teamcity.storage.feature.id\") {\n      property {\n        name\n        value\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9dd2e1f2de20edc061363942773d70c2";

export default node;
