import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'
import {getRequest} from 'relay-runtime'

import {branchExistsInBuildTypeQueryDefinition} from '../../../queries/branchExistsInBuildType'
import {getBuildTypeHasNonDefaultBranchesVariables, Policy} from '../../../rest/branches'
import {
  BuildTypePageTabNamesEnum,
  defaultBuildTypePageTabName,
  getBuildTypeFilter,
} from '../../../types'
import type {
  ThinNestedEntryPointParamsObject,
  ThinQueryParamsObject,
} from '../../../types/entryPoints'
import {parseBranch} from '../../../utils/branchNames'
import {makeResource} from '../../../utils/makeResource'
import {queryToObject} from '../../../utils/queryParams'
import {
  branchesQuery,
  getBranchesQueryVariables,
} from '../../common/BranchSelect/BranchSelect.queries'
import {
  getPermalinksQueryVariables,
  permalinksQuery,
} from '../../common/Permalinks/Permalinks.queries'
import {TagsFiltersEntryPoint} from '../../common/TagsList/TagsFilters.entryPoint'

import {
  buildTypeHistoryQuery,
  getBuildTypeHistoryQueryVariables,
} from './BuildTypeOverviewTab/BuildTypeHistory/BuildTypeHistory.queries'
import {defaultMode, Modes} from './BuildTypeOverviewTab/BuildTypeOverviewTab.modes'
import type {BuildTypePageContainerComponent, NestedEntryPoints, Queries} from './BuildTypePage'
import {query} from './BuildTypePage.queries'

export const BuildTypePageEntryPoint: EntryPoint<
  BuildTypePageContainerComponent,
  LoaderFunctionArgs
> = {
  root: makeResource(
    'BuildTypePage',
    () => import(/* webpackChunkName: "BuildTypePage", webpackPrefetch: true */ './BuildTypePage'),
  ),
  getPreloadProps: ({params, request}) => {
    const {buildTypeId = ''} = params
    const url = new URL(request.url)
    const {
      branch,
      buildTypeTab = defaultBuildTypePageTabName,
      mode = defaultMode,
    } = queryToObject(url.search)
    const queries: ThinQueryParamsObject<Queries> = {
      main: {
        parameters: getRequest(query),
        variables: {locator: `id:${buildTypeId}`},
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
      branches: {
        parameters: getRequest(branchesQuery),
        variables: getBranchesQueryVariables({
          node: getBuildTypeFilter(buildTypeId),
          policy: Policy.ALL_BRANCHES,
        }),
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
      withBranches: {
        parameters: getRequest(branchExistsInBuildTypeQueryDefinition),
        variables: getBuildTypeHasNonDefaultBranchesVariables(buildTypeId),
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
    }
    const entryPoints: ThinNestedEntryPointParamsObject<NestedEntryPoints> = {}
    if (
      buildTypeId != null &&
      buildTypeTab === BuildTypePageTabNamesEnum.OVERVIEW &&
      mode === Modes.BUILDS
    ) {
      queries.buildTypeHistory = {
        parameters: getRequest(buildTypeHistoryQuery),
        variables: getBuildTypeHistoryQueryVariables({
          buildTypeId,
          branch: parseBranch(branch),
          withRunningAndQueued: true,
        }),
        options: {networkCacheConfig: {metadata: {essential: true}}},
      }
      queries.permalinks = {
        parameters: getRequest(permalinksQuery),
        variables: getPermalinksQueryVariables(buildTypeId, branch),
        options: {networkCacheConfig: {metadata: {essential: true}}},
      }
      entryPoints.tagsFilters = {
        entryPoint: TagsFiltersEntryPoint,
        entryPointParams: buildTypeId,
      }
    }
    return {queries, entryPoints}
  },
}
