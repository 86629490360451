import type {JSResourceReference} from 'react-relay'

const cache = new Map()

export function makeResource<T>(
  moduleId: string,
  loader: () => Promise<{default: T}>,
): JSResourceReference<T> {
  let resource = cache.get(moduleId)
  if (resource == null) {
    let module: T | null = null
    let loadingPromise
    resource = {
      getModuleId: () => moduleId,
      getModuleIfRequired: () => module,
      load() {
        loadingPromise ??= loader().then(result => {
          module = result.default
          return module
        })
        return loadingPromise
      },
    }
    cache.set(moduleId, resource)
  }
  return resource
}
