import type {SWGeneratorReturn} from '../../types/generators'
import {noop} from '../../utils/empty'
import {UPDATE_MESSAGE_TYPE} from '../../workers/sw.consts'
import {requestSWUpdate} from '../../workers/sw.lifecycle'

export const subscribeOnServiceWorkerMessage = <ResultType>({
  type = UPDATE_MESSAGE_TYPE,
  url,
  handler,
}: {
  type?: string
  url: string
  handler: (response: SWGeneratorReturn<ResultType>) => void
}): (() => void) => {
  if (navigator.serviceWorker?.controller != null) {
    const wrappedHandler = ({data}: MessageEvent) => {
      if (data != null && data.type === type) {
        if (data.meta.url === url) {
          const {payload, meta} = data

          if (!meta || meta.version !== window.ReactUI.buildNumber) {
            requestSWUpdate()
          }

          if (meta.NO_NEW_DATA) {
            handler({meta})
            return
          }

          handler({payload, meta})
        }
      }
    }

    navigator.serviceWorker.addEventListener('message', wrappedHandler)
    return () => navigator.serviceWorker.removeEventListener('message', wrappedHandler)
  } else {
    handler({
      meta: {
        category: '',
        timeout: true,
        url,
      },
    })
    return noop
  }
}
