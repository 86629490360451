import {dslFragment, dslOptions, dslPortable, dslVersion, toggleDsl} from './Dsl.slices'

export const showDsl = (controlId: string) => toggleDsl.actions.show({controlId})
export const hideDsl = (controlId: string) => toggleDsl.actions.hide({controlId})
export const setDslVersion = (controlId: string, version: string) =>
  dslVersion.actions.set({controlId, version})
export const setDslPortable = (controlId: string, portable: boolean) =>
  dslPortable.actions.set({
    controlId,
    portable,
  })
export const initDslOptions = dslOptions.actions.init
export const storeDslFragment = dslFragment.actions.store
