import {castDraft} from 'immer'

import {createReducer} from '@reduxjs/toolkit'

import {restApi} from '../services/rest'
import type {FederationServerData, FederationServerId} from '../types'
import fastDeepMerge from '../utils/fastDeepMerge'
import type {KeyValue} from '../utils/object'

const NOT_AUTH_HTTP_STATUS = 401

export default createReducer<KeyValue<FederationServerId, FederationServerData>>({}, builder => {
  builder.addMatcher(
    restApi.endpoints.getFederationProjectsNormalized.matchFulfilled,
    (state, action) => {
      const {federationServerUrl} = action.meta.arg.originalArgs
      const prevState = state[federationServerUrl]
      const nextState = {
        authorized: true,
        projects: castDraft(action.payload),
      }
      if (prevState != null) {
        fastDeepMerge(prevState, nextState)
      } else {
        state[federationServerUrl] = nextState
      }
    },
  )
  builder.addMatcher(
    restApi.endpoints.getFederationProjectsNormalized.matchRejected,
    (state, action) => {
      if (action.meta.condition) {
        // request was aborted due to condition (another query already running)
        return
      }
      const {federationServerUrl} = action.meta.arg.originalArgs
      state[federationServerUrl] = {
        authorized: !('status' in action.error) || action.error.status !== NOT_AUTH_HTTP_STATUS,
      }
    },
  )
})
