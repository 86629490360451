import {useParams} from 'react-router-dom'

import {useAppSelector} from '../../../hooks/react-redux'
import {useBranch} from '../../../hooks/routes'
import {getSingleBuildArg} from '../../../rest/builds'
import {getBuild, getBuildsData} from '../../../selectors'
import {restApi} from '../../../services/rest'
import {stringifyId, toBuildId} from '../../../types'

import {getBuildOrPermalinkLocator} from './BuildPage.utils'

export const useBuildPageData = () => {
  const {buildId, buildTypeId} = useParams()
  const branch = useBranch()
  const parsedBuildId = toBuildId(buildId!)
  const buildLocator = getBuildOrPermalinkLocator(buildId, buildTypeId, branch)
  const {actualBuildId, buildError, buildLoaded} =
    restApi.endpoints.getBuildNormalizedAsList.useQuery(getSingleBuildArg(buildLocator), {
      selectFromResult: ({data, error}) => ({
        actualBuildId: data?.result[0],
        buildError: error,
        buildLoaded: data != null,
      }),
    })
  const actualBuildTypeId = useAppSelector(state => getBuild(state, actualBuildId)?.buildType)
  const buildIdChanged =
    parsedBuildId != null && !isNaN(parsedBuildId) && buildLoaded && actualBuildId !== parsedBuildId
  const buildTypeIdChanged = actualBuildTypeId != null && actualBuildTypeId !== buildTypeId

  const listError = useAppSelector(
    state => getBuildsData(state, `id:${stringifyId(buildId)}`).error,
  )

  return {
    buildTypeId,
    actualBuildTypeId,
    buildTypeIdChanged,

    buildIdChanged,
    actualBuildId,
    buildLoaded,

    buildLocator,

    buildError,
    listError,
    branch,
  }
}
