import {SakuraUIEnabledEnum} from '../types'
import {internalProps} from '../types/BS_types'
import {UserProperties} from '../utils/userProperties'

import {useUserProperty} from './useUserProperty'

export function useIsSakuraSelected() {
  const isSakuraSelected = useUserProperty(UserProperties.USE_EXPERIMENTAL_OVERVIEW)

  switch (internalProps['teamcity.ui.experimental']) {
    case SakuraUIEnabledEnum.ENABLE_ALL:
      return true

    case SakuraUIEnabledEnum.ENABLE_DEFAULT:
      return isSakuraSelected !== 'false'

    case SakuraUIEnabledEnum.DISABLE_DEFAULT:
    default:
      return isSakuraSelected === 'true'
  }
}
