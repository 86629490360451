import shallowequal from 'shallowequal'

import {SyncStorageKey} from '../hooks/useStorageSyncValue'
import type {State} from '../reducers/types'
import type {WritableKeyValue} from '../utils/object'
import {safeLocalStorage} from '../utils/safeStorages'

const currentStorageVersion = '0.6'
const oldStorageVersions: ReadonlyArray<string> = ['0.1', '0.2', '0.3', '0.4', '0.5']
const storageKey = 'jb.teamcity.state.v.'
const storageItemName = `${storageKey}${currentStorageVersion}`
export const loadState = (): Partial<State> => {
  if (IS_STORYBOOK) {
    return Object.freeze({})
  }

  oldStorageVersions.forEach((version: string) => {
    safeLocalStorage.removeItem(`${storageKey}${version}`)
  })
  const syncStorageValues: WritableKeyValue<string, string> = {}

  for (const key of Object.values(SyncStorageKey)) {
    const value = safeLocalStorage.getItem(key)

    if (value != null) {
      syncStorageValues[key] = value
    }
  }

  return {...safeLocalStorage.getItemInJSON(storageItemName), syncStorageValues}
}
let prevState = {}
export const saveState = (state: Partial<State>) => {
  if (shallowequal(state, prevState) || IS_STORYBOOK) {
    return
  }

  prevState = state
  safeLocalStorage.setItem(storageItemName, JSON.stringify(state))
}
