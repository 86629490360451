import classNames from 'classnames'
import type {SyntheticEvent, ReactNode, KeyboardEvent} from 'react'
import {memo, useCallback, useContext, useEffect, useState} from 'react'

import {closest, focusSelfOrChildLink} from '../../../../utils/dom'
import filesize from '../../../../utils/filesize'
import {
  PipelinesTargetContext,
  PipelinesTarget,
} from '../../../pages/PipelinesPages/contexts/targetContext'
import Link from '../../Link/Link'
import SvgIcon from '../../SvgIcon/SvgIcon'

import type {Props} from './FileTreeNode.types'
import {FileTreeNodeExtension} from './FileTreeNodeExtension/FileTreeNodeExtension'

import styles from './FileTreeNode.css'

export const MIN_OFFSET = 0
const ARROW_OFFSET = 22
export const STEP = 16
const TREE_SELECTOR = '[role=tree]'
export const ITEM_SELECTOR = '[role=treeitem]'

function stop(e: SyntheticEvent) {
  e.stopPropagation()
}

function FileTreeNode({
  className,
  buildId,
  name,
  href,
  size,
  icon,
  expandable = false,
  defaultExpanded = false,
  level = 0,
  path = '/',
  type = 'file',
  compact = true,
  children,
  itemRef,
  extensions,
  onSelect,
  onExpand,
}: Props) {
  const pipelineTarget = useContext(PipelinesTargetContext)

  const [expanded, setExpanded] = useState(defaultExpanded)
  const toggle = useCallback(() => setExpanded(!expanded), [expanded, setExpanded])

  useEffect(() => {
    if (onExpand != null) {
      onExpand(path, expanded)
    }
  }, [onExpand, expanded, path])

  const handleToggleClick = useCallback(
    (e: SyntheticEvent) => {
      toggle()
      stop(e)
    },
    [toggle],
  )

  const handleNameClick = useCallback(
    (e: SyntheticEvent) => {
      if (onSelect) {
        e.preventDefault()
        onSelect(path.slice(1), type)
      }
      stop(e)
    },
    [onSelect, path, type],
  )

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const currentTarget = e.currentTarget

      if (e.target !== currentTarget) {
        return
      }

      const item = closest(currentTarget, ITEM_SELECTOR)
      const tree = closest(item, TREE_SELECTOR)

      if (!tree) {
        return
      }

      const items = [...tree.querySelectorAll(ITEM_SELECTOR)]
      const currentIndex = item != null ? items.indexOf(item) : -1
      let itemToFocus: Element | null | undefined

      switch (e.key) {
        case 'Home':
          itemToFocus = items[0]
          break

        case 'ArrowLeft':
          if (expandable && expanded) {
            setExpanded(false)
          } else {
            // Focus parent
            itemToFocus = closest(item?.parentElement, ITEM_SELECTOR)
          }

          break

        case 'ArrowUp':
          itemToFocus = items[currentIndex - 1]
          break

        case 'ArrowRight':
          if (expandable && !expanded) {
            setExpanded(true)
            break
          }

          // Focus first child
          itemToFocus = item?.querySelector(ITEM_SELECTOR)
          break

        case 'ArrowDown':
          itemToFocus = items[currentIndex + 1]
          break

        case 'End':
          itemToFocus = items[items.length - 1]
          break

        case 'Enter':
          if (expandable) {
            toggle()
          } else {
            return
          }

          break

        default:
          return
      }

      e.stopPropagation()
      e.preventDefault()
      focusSelfOrChildLink(itemToFocus)
    },
    [expandable, expanded, toggle],
  )

  const getOffset = useCallback(() => MIN_OFFSET + level * STEP, [level])

  const renderIcon = useCallback(
    () => (
      <span style={{marginLeft: getOffset() + ARROW_OFFSET}}>
        <SvgIcon className={styles.icon} icon={icon} />
      </span>
    ),
    [getOffset, icon],
  )

  const renderSize = useCallback(
    () => size != null && <span className={styles.size}>{filesize(size) as ReactNode}</span>,
    [size],
  )

  const renderExtensions = useCallback(() => {
    const pathWithoutLeadingSlash = path.slice(1)

    return extensions?.map(({endpoint}) => (
      <FileTreeNodeExtension
        key={endpoint}
        endpoint={endpoint}
        buildId={buildId}
        path={pathWithoutLeadingSlash}
      />
    ))
  }, [buildId, extensions, path])

  return (
    <li
      ref={itemRef}
      role="treeitem"
      aria-selected={false}
      aria-expanded={expandable ? expanded : undefined}
      tabIndex={expandable ? 0 : -1}
      className={classNames(styles.item, className, {
        [styles.compact]: compact,
        [styles.jobDetailsSidebarTarget]: pipelineTarget === PipelinesTarget.JOB_DETAILS_SIDEBAR,
      })}
      onClick={handleToggleClick}
      onKeyDown={expandable ? handleKeyDown : undefined}
    >
      {expandable && (
        <span className={styles.heading}>
          {renderIcon()}
          {href != null || onSelect ? (
            <Link relative href={href} className={styles.innerLink} onClick={handleNameClick}>
              {name}
            </Link>
          ) : (
            <span className={styles.name}>{name}</span>
          )}
          {renderSize()}
          {renderExtensions()}
          <SvgIcon
            style={{left: getOffset()}}
            className={styles.chevronIcon}
            icon={expanded ? 'chevron-down' : 'chevron-right'}
          />
        </span>
      )}
      {!expandable && href != null && (
        <Link
          relative
          href={href}
          className={styles.link}
          onClick={handleNameClick}
          onKeyDown={handleKeyDown}
        >
          <span>{renderIcon()}</span>
          <span className={styles.name}>{name}</span>
          {renderSize()}
          {renderExtensions()}
        </Link>
      )}
      {expandable && expanded && children}
    </li>
  )
}

export default memo(FileTreeNode)
