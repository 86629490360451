import {graphql} from 'react-relay'

export const buildTypeTagsQuery = graphql`
  query TagsListBuildTypeTagsQuery($btLocator: String!) {
    buildTypeBuildTags(btLocator: $btLocator) {
      tag {
        name
      }
    }
  }
`
