import {createSlice, isAnyOf} from '@reduxjs/toolkit'

import * as serviceWorkerUpdateActions from '../actions/serviceWorkers'
import {restApi} from '../services/rest'

type BuildsFiltersState = {
  updating?: boolean | null | undefined
}
const initialState: BuildsFiltersState = {
  updating: false,
}
const buildsFilters = createSlice({
  name: 'buildsFilters',
  initialState,
  reducers: {
    updateResults(state) {
      state.updating = true
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getAllBuildsNormalized.matchFulfilled,
        restApi.endpoints.getAllBuildsNormalized.matchRejected,
        serviceWorkerUpdateActions.getAllBuildsNormalized.match,
      ),
      state => {
        state.updating = false
      },
    )
  },
})

export default buildsFilters
