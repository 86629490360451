import {graphql} from 'react-relay'

export const UseIsReadonlyQuery = graphql`
  query CleanupProjectPageUseIsReadOnlyQuery($locator: String!) {
    projectsRest(locator: $locator) {
      ...CleanupProjectPageUseIsReadOnlyFragment
    }
  }
`

export const getUseIsReadonlyQueryVariables = (projectId: string) => ({
  locator: `item(readOnlyUI:true,affectedProject:(id:${projectId})),item(readOnlyUI:true,id:${projectId})`,
})
