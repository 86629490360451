export const copyClipboard = (data: Node | string | null | undefined): Promise<void> => {
  if (data == null) {
    return Promise.resolve()
  }
  const text = typeof data === 'string' ? data : data.textContent ?? ''
  if (navigator.clipboard != null) {
    return navigator.clipboard.writeText(text)
  }

  return Promise.reject()
}
