import {graphql} from 'react-relay'

export const query = graphql`
  query QueueSidebarQuery {
    ...QueueSidebarPoolIdsFragment
    agentPoolsRest {
      ...QueueSidebarPoolsFragment
    }
  }
`
