import {createSelector} from 'reselect'

import type {State} from '../../../../reducers/types'
import {emptyArray, getEmptyHash} from '../../../../utils/empty'
import type {KeyValue} from '../../../../utils/object'
import type {StepTypes} from '../types'

import {generatedFields} from './SettingsSidebarContent/JobSettingsSidebar/JobSteps/JobStep/JobStepHotFields/JobStepHotFields.descriptors'
import {errorValidators} from './SettingsSidebarContent/JobSettingsSidebar/JobSteps/JobStep/SmartDetectors/CliErrorMessage/useCliErrorMessage.consts'
import type {ErrorValidatorsKeys} from './SettingsSidebarContent/JobSettingsSidebar/JobSteps/JobStep/SmartDetectors/CliErrorMessage/useCliErrorMessage.consts'
import type {FormType} from './slices/EditPipelinePage.slices.types'

const getPipelineDraftDeleted = (id: string) => (state: State) =>
  state.pipelines.pipelineDraft[id]?.deleted

export const getPipelineOriginalIntegrations = (id: string) => (state: State) =>
  state.pipelines.pipelineDraft[id]?.original?.integrations

export const getOriginalPipelineTriggers = (id: string) => (state: State) =>
  state.pipelines.pipelineDraft[id]?.original?.triggers

export const getOriginalPipelineSettings = (id: string) => (state: State) =>
  state.pipelines.pipelineDraft[id]?.original?.settings

export const getOriginalPipelineAdditionalVcsRoots = (id: string) => (state: State) =>
  state.pipelines.pipelineDraft[id]?.original?.additionalVcsRoots

export const getDraft = (state: State, id: string) => state.pipelines.pipelineDraft[id]?.draft

export const getDraftPipelineAdditionalVcsRoots = (id: string) => (state: State) =>
  getDraft(state, id)?.additionalVcsRoots

export const getDraftJobs = (state: State, id: string) => getDraft(state, id)?.settings.jobs

const makePipelineErrorsSelector = () =>
  createSelector(
    [
      (state: State, props: {pipelineId: string}) =>
        getDraftJobs(state, props.pipelineId) ?? getEmptyHash(),
      (state: State, props: {pipelineId: string}) =>
        getPipelineDraftDeleted(props.pipelineId)(state)?.jobs ?? (emptyArray as string[]),
      (state: State, props: {pipelineId: string}) =>
        getPipelineDraftDeleted(props.pipelineId)(state)?.steps ??
        (getEmptyHash() as Record<string, number[]>),
    ],
    (jobs, deletedJobs, deletedSteps) =>
      Object.fromEntries(
        Object.entries(jobs)
          .filter(([jobId]) => !deletedJobs.includes(jobId))
          .map(([jobId, job]) => {
            const jobErrors: Array<Record<string, Array<string>>> = []

            const deletedStepsInCurrentJob = deletedSteps[jobId]
            const steps =
              deletedStepsInCurrentJob != null && deletedStepsInCurrentJob.length > 0
                ? job?.steps?.filter((step, index) => !deletedStepsInCurrentJob.includes(index))
                : job?.steps

            if (steps != null) {
              for (const step of steps) {
                const defaultFields = generatedFields[step.type]
                const stepWithDefaultValues = {
                  ...(defaultFields &&
                    Object.fromEntries(defaultFields.map(field => [field.name, '']))),
                  ...step,
                }

                const stepErrors = Object.fromEntries(
                  Object.entries(stepWithDefaultValues).map(([name, value]) => {
                    const result = (errorValidators[name as ErrorValidatorsKeys] ?? [])
                      .filter(({validator}) => !validator({value}))
                      .map(({message}) => message)

                    return [name, result]
                  }),
                )

                jobErrors.push(stepErrors)
              }
            }
            return [jobId, jobErrors]
          }),
      ),
  )

export const makeTotalCountPipelineErrorsSelector = () =>
  createSelector([makePipelineErrorsSelector()], errors =>
    Object.values(errors).reduce(
      (totalPipeline, jobErrors) =>
        totalPipeline +
        jobErrors?.reduce((acc, stepErrors) => acc + Object.values(stepErrors).flat().length, 0),
      0,
    ),
  )

export const makePipelineFormErrorsSelector = () =>
  createSelector(
    [makePipelineErrorsSelector(), (state: State) => state.pipelines.pipelineDraftForm.submitted],
    (errors, submitted): KeyValue<string, Array<Record<string, Array<string>>>> =>
      submitted ? errors : getEmptyHash(),
  )

export const makeTotalCountPipelineFormErrorsSelector = () =>
  createSelector(
    [
      makeTotalCountPipelineErrorsSelector(),
      (state: State) => state.pipelines.pipelineDraftForm.submitted,
    ],
    (count, submitted) => (submitted ? count : 0),
  )

const makeDraftStepFormErrorsSelector = () =>
  createSelector(
    [makePipelineFormErrorsSelector(), (_: State, props: {jobId: string}) => props.jobId],
    (errors, jobId) => errors[jobId] ?? [],
  )
export const getJobName = (state: State, pipelineId: string, id: string) => {
  const currentName = getDraftJobs(state, pipelineId)?.[id]?.name
  const renamed = state.pipelines.pipelineDraft[pipelineId]?.renamed?.jobs?.[id]
  return renamed ?? currentName ?? id
}

export const makeHasJobFormErrorSelector = () =>
  createSelector([makeDraftStepFormErrorsSelector()], stepErrors =>
    stepErrors.some(errors => Object.values(errors).flat().length > 0),
  )

export const getJobAgent = (jobId: string, id: string) => (state: State) =>
  getDraft(state, id)?.settings?.jobs?.[jobId]?.['runs-on']

export const getJobCheckoutWorkingDirectoriesOnly = (jobId: string, id: string) => (state: State) =>
  getDraft(state, id)?.settings?.jobs?.[jobId]?.['checkout-working-directories-only']

export const isPipelineFormOpened = (state: State, formId: string, formType: FormType) => {
  const {id, type, isOpened} = state.pipelines.pipelineForm

  return id === formId && type === formType && isOpened === true
}

export const getSkippedSuggestions = (state: State, stepId: string) =>
  state.pipelines.suggestions.skippedSuggestions[stepId]

export const isSuggestionSkipped = (state: State, stepId: string, type: string) =>
  getSkippedSuggestions(state, stepId)?.includes(type)

export const isSuccessMessageVisible = (state: State, stepId: string, type: StepTypes) =>
  state.pipelines.suggestions.successMessages[stepId] === type
