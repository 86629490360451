import {createSlice, type PayloadAction} from '@reduxjs/toolkit'

import type {ProblemOccurrenceId} from '../../../../types'

const selectedBuildProblemsInitialState: {selectedIds: ProblemOccurrenceId[]} = {
  selectedIds: [],
}

export const selectedBuildProblems = createSlice({
  name: 'selectedBuildProblems',
  initialState: selectedBuildProblemsInitialState,
  reducers: {
    setSelectedIds(state, action: PayloadAction<ProblemOccurrenceId[]>) {
      state.selectedIds = action.payload
    },
  },
})
