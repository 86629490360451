import {getBuild} from '../selectors'
import type {BuildId} from '../types'

import {useAppSelector} from './react-redux'
import useMyId from './useMyId'

export function useIsMine(buildId: BuildId | null | undefined) {
  const userId = useAppSelector(state => getBuild(state, buildId)?.user?.id)
  const myId = useMyId()
  return userId != null && userId === myId
}
