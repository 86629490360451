import moment from 'moment'
import * as React from 'react'
import {useMemo} from 'react'
import {graphql, useFragment, useMutation} from 'react-relay'

import type {BuildId} from '../../../types'
import {emptyArray, noop} from '../../../utils/empty'

import type {BuildApprovalReviewers_approveMutation} from './__generated__/BuildApprovalReviewers_approveMutation.graphql'
import type {BuildApprovalReviewers_canViewApprovalInfo$key} from './__generated__/BuildApprovalReviewers_canViewApprovalInfo.graphql'
import type {
  BuildApprovalReviewers_groupedByStatusApprovals$data,
  BuildApprovalReviewers_groupedByStatusApprovals$key,
} from './__generated__/BuildApprovalReviewers_groupedByStatusApprovals.graphql'

const groupedByStatusApprovalsFragment = graphql`
  fragment BuildApprovalReviewers_groupedByStatusApprovals on UserApprovals {
    userApproval {
      approved
      user {
        id
        ...BuildApprovalReviewers_approvalUser
      }
    }
  }
`

type UserApproval = NonNullable<
  BuildApprovalReviewers_groupedByStatusApprovals$data['userApproval']
>[0]

export function useGroupedByStatusApprovals(
  approvalsKey: BuildApprovalReviewers_groupedByStatusApprovals$key | null,
) {
  const userApproval =
    useFragment(groupedByStatusApprovalsFragment, approvalsKey)?.userApproval ?? emptyArray
  return React.useMemo(
    () =>
      userApproval.reduce(
        (
          accumulator: {
            approved: Array<UserApproval>
            nonApproved: Array<UserApproval>
          },
          current,
        ) => {
          if (current.approved) {
            accumulator.approved.push(current)
          } else {
            accumulator.nonApproved.push(current)
          }

          return accumulator
        },
        {approved: [], nonApproved: []},
      ),
    [userApproval],
  )
}

const approveMutation = graphql`
  mutation BuildApprovalReviewers_approveMutation($buildLocator: String!) {
    approveQueuedBuild(buildLocator: $buildLocator) {
      status
      ...BuildApprovalReviewers_canViewApprovalInfo
      ...BuildApprovalReviewers_approvalInfo
      ...BuildApprovalControls_approvalInfo
      ...BuildApprovalDetailsPopup_anchor
      ...BuildApprovalDetailsPopupContent_approvalInfo
    }
  }
`

export function useBuildApprove(buildId: BuildId) {
  const [approveBuild] = useMutation<BuildApprovalReviewers_approveMutation>(approveMutation)
  const variables = useMemo(() => ({buildLocator: `id:${buildId}`}), [buildId])
  const approve = React.useCallback(
    () =>
      approveBuild({
        variables,
        updater: store => {
          const response = store.getRootField('approveQueuedBuild')
          store.getRoot().setLinkedRecord(response, 'approvalInfo', variables)
        },
      }),
    [approveBuild, variables],
  )

  return {approve}
}
export function useTimeoutInfo(expiresAt: string | null) {
  const [secondsLeft, setSecondsLeft] = React.useState<number | null>(null)
  const [timeLeft, setTimeLeft] = React.useState<string | null>(null)

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout

    if (!expiresAt) {
      return noop
    }

    function tick() {
      clearTimeout(timeoutId)
      const now = moment()
      const diff = moment(expiresAt).diff(now)

      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      setSecondsLeft(diff / 1000)
      setTimeLeft(moment.utc(diff).format('HH:mm:ss'))

      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      timeoutId = setTimeout(tick, 1000)
    }

    tick()

    return () => {
      clearTimeout(timeoutId)
    }
  }, [expiresAt])

  return {secondsLeft, timeLeft}
}

const canViewApprovalInfoFragment = graphql`
  fragment BuildApprovalReviewers_canViewApprovalInfo on ApprovalInfo {
    configurationValid
    userApprovals {
      count
    }
    groupApprovals {
      count
    }
  }
`

export function useCheckIfUserCanViewApprovalInfo(
  approvalInfoKey: BuildApprovalReviewers_canViewApprovalInfo$key | null,
) {
  const approvalInfo = useFragment(canViewApprovalInfoFragment, approvalInfoKey)
  return (
    approvalInfo?.configurationValid &&
    (approvalInfo.userApprovals?.count || approvalInfo.groupApprovals?.count)
  )
}
