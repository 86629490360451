import classNames from 'classnames'
import compose from 'lodash/flowRight'
import * as React from 'react'

import {withActiveEntityId, withQueryParam} from '../../../hooks/routes'

import {usePlugins} from './Plugin.hooks'
import type {Props} from './Plugin.types'
import SakuraUIPlugin from './SakuraUIPlugin/SakuraUIPlugin'

import styles from './Plugin.css'

const IGNORED_PLUGINS = [
  'FOOTER_JS_CONTAINER',
  'GLOBAL_HEALTH_ITEMS_CONTAINER',
  'HEALTH_STATUS_ITEM',
  'PAGE_HEADER',
]

function Plugin(props: Props) {
  const [activeEntities, pluginsToRender] = usePlugins(props)
  const {placeId, pluginDevelopmentMode, className, inline} = props
  const renderPlugin = React.useCallback(
    (name: string) => (
      <SakuraUIPlugin name={name} placeId={placeId} activeEntities={activeEntities} key={name} />
    ),
    [activeEntities, placeId],
  )
  const isHiddenContainer = IGNORED_PLUGINS.includes(placeId)
  const showDevelopmentInfo = pluginDevelopmentMode != null && !isHiddenContainer
  return (
    <div
      id={placeId}
      title={showDevelopmentInfo ? placeId : undefined}
      className={classNames('placeIdModule', className, {
        [styles.inline]: inline,
        [styles.developmentMode]: showDevelopmentInfo,
      })}
    >
      {showDevelopmentInfo && <span className={styles.positionName}>{placeId}</span>}
      {pluginsToRender.map(name => renderPlugin(name))}
    </div>
  )
}

Plugin.displayName = 'Plugin'
export default compose(
  React.memo,
  withQueryParam('pluginDevelopmentMode'),
  withActiveEntityId,
)(Plugin)
