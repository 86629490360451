import Button from '@jetbrains/ring-ui/components/button/button'
import classNames from 'classnames'
import * as React from 'react'

import {stringifyId} from '../../../../types'
import type {BuildId, BuildTypeId} from '../../../../types'
import {resolveRelative} from '../../../../utils/url'
import SvgIcon from '../../SvgIcon/SvgIcon'
import {showDownloadAllArtifactsLink} from '../BuildArtifactsTree.settings'

import styles from './ArtifactsDownloadAll.css'

type Props = {
  className?: string
  buildId?: BuildId
  buildType?: BuildTypeId | null | undefined
  showHidden?: boolean | null | undefined
}

export default function ArtifactsDownloadAll({className, buildId, buildType, showHidden}: Props) {
  if (!showDownloadAllArtifactsLink) {
    return null
  }

  return (
    <Button
      href={resolveRelative(
        `/repository/downloadAll/${stringifyId(buildType)}/${stringifyId(
          buildId,
        )}:id/artifacts.zip${showHidden === true ? '?showAll=true' : ''}`,
      )}
      className={classNames(styles.downloadLink, className)}
    >
      <SvgIcon className={styles.downloadIcon} icon="download" />
      {'Download all'}
    </Button>
  )
}
