import {stringifyId} from '../types'
import type {Id} from '../types'

export enum Namespace {
  CHANGES = 'changes',
  ARTIFACTS = 'artifacts',
  AGENTS = 'agents',
  HAS_DEPENDANTS = 'has-dependants',
  TEST_OCCURRENCES = 'test-occurrences',
  HTML = 'html',
  CHANGE_STATUS = 'change-status',
  CHANGE_BRANCHES = 'change-branches',
}

export const getEntityId = (ns: Namespace, id: Id): string => `${ns}:${stringifyId(id)}`
