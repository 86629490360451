import {createAction} from '@reduxjs/toolkit'

import type {AppThunk} from '../../../../../../actions/types'
import {createAppAsyncThunk} from '../../../../../../reducers/fetchable'
import {getExtensionEndpoint} from '../../../../../../selectors'
import {restApi} from '../../../../../../services/rest'
import type {BuildId} from '../../../../../../types'
import asyncTimeout from '../../../../../../utils/asyncTimeout'
import type {
  BuildLogMessage,
  BuildLogMessagesResponse,
  FullLogTarget,
  LogFilter,
  LogView,
} from '../../../BuildLog.types'
import {SearchDirection} from '../../../BuildLog.types'
import {getSearchRegExp, processMessageText} from '../../../BuildLog.utils'
import {navigateToMessage} from '../../FullBuildLog.actions'

import {getBuilLogSearchState} from './BuildLogSearch.selectors'

const SEARCH_INTERVAL_TIMEOUT = 100
const SEARCH_MESSAGES_REQUEST_LIMIT = 10000
export const ONE_TIME_SEARCH_LIMIT = 100000
type SearchMessageInBuildLogArg = {
  target: FullLogTarget
  buildId: BuildId
  query: string
  startId?: number
  filter?: LogFilter | null | undefined
  applyLimit?: boolean
  searchDirection: SearchDirection
  logView?: LogView
}
export const searchMessageInBuildLogAction = createAppAsyncThunk(
  'searchMessageInBuildLog',
  async (
    {
      target,
      buildId,
      query,
      startId,
      filter,
      applyLimit,
      searchDirection,
      logView,
    }: SearchMessageInBuildLogArg,
    {getState, dispatch},
  ) => {
    const state = getState()
    const extension = getExtensionEndpoint(state, 'fetchExpandedBuildLogMessage')! // checked in searchMessageInBuildLog

    const {
      matchesCount: previousMatchesCount,
      matchPosition: previousMatchPosition,
      foundId: previousFoundId,
    } = getBuilLogSearchState(state, target)

    let nextStartId: number | null | undefined = startId
    let notFound = false
    let limitReached = false
    let foundMessage: BuildLogMessage | null | undefined = null
    let scannedCount = 0
    let foundId: number | null | undefined
    let matchesCount = 0
    let matchPosition: number | null = null
    let isCycle = false

    while (foundMessage == null && nextStartId != null && !notFound && !limitReached) {
      if (nextStartId !== startId) {
        await asyncTimeout(SEARCH_INTERVAL_TIMEOUT)
        const actualSearchState = getBuilLogSearchState(getState(), target)
        if (
          actualSearchState.query !== query ||
          actualSearchState.searchDirection !== searchDirection
        ) {
          // stop search if query or direction was changed
          throw new Error('Cancelled')
        }
      }

      const {data, error} = await dispatch(
        restApi.endpoints.getBuildLogMessages.initiate({
          endpoint: extension.endpoint,
          buildId,
          options: {
            searchQuery: query,
            count:
              SEARCH_MESSAGES_REQUEST_LIMIT * (searchDirection === SearchDirection.NEXT ? 1 : -1),
            logAnchor: nextStartId,
            filter,
            logView,
          },
          buildLogKey: '',
        }),
      )

      if (error != null) {
        throw error
      }

      const response: Partial<BuildLogMessagesResponse> = data?.data ?? {}

      foundMessage = response.messages?.[0]
      if (foundMessage != null) {
        foundId = foundMessage.id
        matchesCount =
          processMessageText(foundMessage.text).match(getSearchRegExp(query))?.length ?? 0
        if (searchDirection === SearchDirection.NEXT) {
          nextStartId = foundMessage.id + 1
          matchPosition = 0
        } else {
          nextStartId = foundMessage.id - 1
          matchPosition = matchesCount - 1
        }
      } else {
        foundId = previousFoundId
        matchesCount = previousMatchesCount
        matchPosition = previousMatchPosition ?? null
        if (searchDirection === SearchDirection.NEXT) {
          if (isCycle || foundId == null) {
            notFound = Boolean(response.lastMessageIncluded)
            nextStartId = response.nextVisible
          } else {
            isCycle = true
            nextStartId = response.lastMessageIncluded ? 0 : response.nextVisible
          }
        } else if (isCycle || foundId == null) {
          nextStartId = response.prevVisible
          notFound = response.prevVisible === 0
        } else {
          isCycle = true
          nextStartId = response.prevVisible || -1
        }
      }

      scannedCount += SEARCH_MESSAGES_REQUEST_LIMIT
      limitReached =
        applyLimit === true &&
        !notFound &&
        foundMessage == null &&
        scannedCount >= ONE_TIME_SEARCH_LIMIT
    }

    if (foundMessage != null) {
      dispatch(navigateToMessage(target, foundMessage.id))
    }

    return {
      foundId,
      nextStartId,
      matchPosition,
      matchesCount,
      limitReached,
      notFound,
    }
  },
)
export const changeBuildlogSearchMatchPosition = createAction<{
  target: FullLogTarget
  query: string
  matchPosition: number | null | undefined
}>('changeBuildlogSearchMatchPosition')
export const searchMessageInBuildLog =
  (arg: SearchMessageInBuildLogArg): AppThunk<void> =>
  (dispatch, getState) => {
    const {target, query, searchDirection} = arg
    const state = getState()
    const extension = getExtensionEndpoint(state, 'fetchExpandedBuildLogMessage')

    if (!extension) {
      return
    }

    const {
      loading,
      query: previousQuery,
      matchesCount: previousMatchesCount,
      matchPosition: previousMatchPosition,
      searchDirection: previousSearchDirection,
    } = getBuilLogSearchState(state, target)

    if (previousQuery === query && searchDirection === previousSearchDirection && loading) {
      return
    }

    if (
      previousQuery === query &&
      previousMatchPosition != null &&
      (searchDirection === SearchDirection.NEXT
        ? previousMatchPosition + 1 < previousMatchesCount
        : previousMatchPosition > 0)
    ) {
      dispatch(
        changeBuildlogSearchMatchPosition({
          matchPosition:
            previousMatchPosition + (searchDirection === SearchDirection.NEXT ? 1 : -1),
          target,
          query,
        }),
      )
      return
    }

    dispatch(searchMessageInBuildLogAction(arg))
  }
export const clearBuildLogSearch = createAction<FullLogTarget>('clearBuildLogSearch')
