import {isValueNotEmptyMessage} from '../../../../../../../utils/validators'
import {
  DOCKER_IMAGE_PROPERTY_NAME,
  DOCKERFILE_PATH_PROPERTY_NAME,
  DOCKERFILE_SOURCE_PROPERTY_NAME,
} from '../../JobStepRunInDocker/JobStepRunInDocker.consts'

const cliErrorValidators = [
  {
    validator: isValueNotEmptyMessage,
    message: 'Script content should not be empty',
  },
]

const dockerImageErrorValidators = [
  {
    validator: isValueNotEmptyMessage,
    message: 'Docker image name should not be empty',
  },
]

const dockerfileSourceErrorValidators = [
  {
    validator: isValueNotEmptyMessage,
    message: 'Dockerfile content should not be empty',
  },
]
const dockerfilePathErrorValidators = [
  {
    validator: isValueNotEmptyMessage,
    message: 'Dockerfile path should not be empty',
  },
]

export const errorValidators = {
  'script-content': cliErrorValidators,
  [DOCKER_IMAGE_PROPERTY_NAME]: dockerImageErrorValidators,
  [DOCKERFILE_SOURCE_PROPERTY_NAME]: dockerfileSourceErrorValidators,
  [DOCKERFILE_PATH_PROPERTY_NAME]: dockerfilePathErrorValidators,
}

export type ErrorValidatorsKeys = keyof typeof errorValidators
