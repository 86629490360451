import * as React from 'react'

import {subscribeOnBuild} from '../actions/builds'
import {getSingleBuildArg} from '../rest/builds'
import {getBuildLocator} from '../rest/locators'
import {getBuild, getBuildType} from '../selectors'
import {restApi} from '../services/rest'
import type {BuildId} from '../types'
import {noop} from '../utils/empty'
import {subscribeOnBuildTypeEvents} from '../utils/subscriber'
import {BUILD_FINISHED, BUILD_INTERRUPTED} from '../utils/subscriptionEvents'

import {useAppDispatch, useAppSelector} from 'src/hooks/react-redux'

type Props = {
  buildId: BuildId
}

const countSubscriptions: Record<BuildId, number> = {}
const unsubscribeFunctions: Record<BuildId, () => unknown> = {}
export function useRunningBuildUpdater(buildId?: BuildId, disable = false) {
  const dispatch = useAppDispatch()
  const buildTypeInternalId = useAppSelector(state => {
    const {buildType} = getBuild(state, buildId) || {}
    return getBuildType(state, buildType)?.internalId
  })
  const [fetchSingleBuildData] =
    restApi.endpoints.getBuildNormalizedAsList.useLazyQuerySubscription()
  const subscribeHandler = React.useCallback(() => {
    if (buildId == null) {
      return noop
    }

    let unsubscribeFromBuildType = noop
    const unsubscribeFromBuild = dispatch(subscribeOnBuild(buildId))

    if (buildTypeInternalId != null) {
      unsubscribeFromBuildType = subscribeOnBuildTypeEvents(
        buildTypeInternalId,
        [BUILD_FINISHED, BUILD_INTERRUPTED],
        () => fetchSingleBuildData(getSingleBuildArg(getBuildLocator(buildId))),
      )
    }

    return () => {
      unsubscribeFromBuildType()
      unsubscribeFromBuild()
    }
  }, [buildId, buildTypeInternalId, dispatch, fetchSingleBuildData])
  React.useEffect(() => {
    if (!disable && buildId != null) {
      const prevCount = countSubscriptions[buildId] ?? 0

      if (prevCount === 0) {
        unsubscribeFunctions[buildId] = subscribeHandler()
      }

      countSubscriptions[buildId] = prevCount + 1
      return () => {
        countSubscriptions[buildId] -= 1

        if (countSubscriptions[buildId] === 0) {
          unsubscribeFunctions[buildId]()
          delete countSubscriptions[buildId]
          delete unsubscribeFunctions[buildId]
        }
      }
    }

    return undefined
  }, [buildId, disable, subscribeHandler])
}
export default function RunningBuildUpdater({buildId}: Props) {
  useRunningBuildUpdater(buildId)
  return null
}
