import {createReducer} from '@reduxjs/toolkit'

import {restApi} from '../../../../services/rest'
import {submitPager} from '../Pager.actions.base'
import type {Pager} from '../Pager.types'
import {PagerGroup} from '../Pager.types'

const defaultPager = {
  hasMore: true,
  loadedLessThanRequested: null,
  openTotal: false,
  pageSize: 25,
  total: 0,
}

const pagerReducer = createReducer<Pager>(defaultPager, builder => {
  builder.addCase(submitPager, (state, action) => {
    if (action.meta.group === PagerGroup.TEST_PROBLEM) {
      Object.assign(state, action.payload)
    }
  })
  builder.addMatcher(
    restApi.endpoints.getAllTestProblemsNormalized.matchFulfilled,
    (state, action) => {
      const {data: actionData, hasMore = false, loadedLessThanRequested} = action.payload
      if (actionData == null) {
        state.total = 0
        state.openTotal = false
        return
      }

      const data = actionData.result
      state.total = data.length
      state.hasMore = hasMore
      state.loadedLessThanRequested = loadedLessThanRequested
      state.openTotal = state.hasMore && !state.loadedLessThanRequested
    },
  )
})

export default pagerReducer
