import {createReducer} from '@reduxjs/toolkit'

import {restApi} from '../../../../services/rest'
import {submitPager} from '../Pager.actions.base'
import type {Pager} from '../Pager.types'
import {PagerGroup} from '../Pager.types'

const defaultPager: Pager = {
  hasMore: true,
  loadedLessThanRequested: null,
  openTotal: false,
  pageSize: 100,
  total: 0,
}

const pagerReducer = createReducer(defaultPager, builder => {
  builder.addCase(submitPager, (state, action) => {
    if (action.meta.group === PagerGroup.TEST_SCOPE) {
      Object.assign(state, action.payload)
    }
  })
  builder.addMatcher(restApi.endpoints.getTestScopes.matchFulfilled, (state, action) => {
    const {nextHref, testScope} = action.payload

    if (testScope == null) {
      state.total = 0
      state.openTotal = false
      return
    }

    state.hasMore = nextHref != null
    state.total = testScope.length
    state.loadedLessThanRequested = testScope.length < action.meta.arg.originalArgs.count
    state.openTotal = state.hasMore && !state.loadedLessThanRequested
  })
})

export default pagerReducer
