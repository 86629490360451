import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {Policy} from '../../../rest/branches'
import type {BuildTypeId} from '../../../types'
import {makeResource} from '../../../utils/makeResource'
import {branchesQuery, getBranchesQueryVariables} from '../BranchSelect/BranchSelect.queries'

import type {BuildStatusWidgetComponent} from './BuildStatusWidget.container'
import {query} from './BuildStatusWidget.queries'

export const BuildStatusWidgetEntryPoint: EntryPoint<BuildStatusWidgetComponent, BuildTypeId> = {
  root: makeResource(
    'BuildStatusWidget',
    () =>
      import(
        /* webpackChunkName: "BuildStatusWidget", webpackPrefetch: true */ './BuildStatusWidget.container'
      ),
  ),
  getPreloadProps: buildTypeId => ({
    queries: {
      main: {parameters: getRequest(query), variables: {btLocator: `id:${buildTypeId}`}},
      branches: {
        parameters: getRequest(branchesQuery),
        variables: getBranchesQueryVariables({
          node: {
            nodeType: 'bt',
            id: buildTypeId,
          },
          policy: Policy.ALL_BRANCHES,
          excludeGroups: true,
        }),
      },
    },
  }),
}
