import type {User} from '../../../services/rest'
import type {BuildId, BuildState, ProjectId} from '../../../types'

export enum StopButtonType {
  DEFAULT_BUTTON = 'defaultButton',
  ICON_BUTTON = 'iconButton',
}

export type OwnProps = {
  buildId: BuildId
  withAgent?: boolean
  className?: string
  buttonType?: StopButtonType
  primary?: boolean
  buttonText?: string
  actualStopMessage?: string
  withBorder?: boolean
}
export type StateProps = {
  projectId?: ProjectId | null | undefined
  agentName?: string | null | undefined
  state: BuildState | void
  isInterrupted: boolean
  isStopping: boolean
  stoppedWithComment?: string | null | undefined
  stoppedBy?: User | undefined
}
export type ActionProps = {
  onStop: (arg0: BuildId) => unknown
}
type UpdateProps = {
  updateResults: () => unknown
}
export type Props = OwnProps & StateProps & Partial<UpdateProps> & Partial<ActionProps>
