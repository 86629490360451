import {useIsSakuraSelected} from '../../../hooks/useIsSakuraSelected'
import {useBooleanUserProperty} from '../../../hooks/useUserProperty'
import {internalProps} from '../../../types/BS_types'
import {isEmbedded} from '../../../utils/isEmbedded'
import {UserProperties} from '../../../utils/userProperties'

const isEnabledServerWide = internalProps['teamcity.ui.sakura.releaseBanner.enabled'] === true

export const useSakuraReleaseBanner = () => {
  const sakuraSelected = useIsSakuraSelected()
  const isEnabledForUser = useBooleanUserProperty(UserProperties.SHOW_SAKURA_RELEASE_BANNER, true)

  if (!isEnabledForUser || !isEnabledServerWide) {
    return false
  }

  return !isEmbedded && !sakuraSelected
}
