import type {SerializedError} from '@reduxjs/toolkit'
import type {FetchBaseQueryError} from '@reduxjs/toolkit/src/query/fetchBaseQuery'

import HTTPError from '../rest/errors/HTTPError'

export function extractError(
  error: SerializedError | FetchBaseQueryError | undefined,
): Error | string | null | undefined {
  if (error == null) {
    return null
  } else if ('status' in error) {
    if (typeof error.status === 'number' && typeof error.data === 'string') {
      return new HTTPError(error.data, error.status)
    }
    if (error.data instanceof Error || typeof error.data === 'string') {
      return error.data
    } else if ('error' in error) {
      return error.error
    } else {
      return null
    }
  } else {
    return error.message
  }
}

export function extractErrorMessage(
  error: SerializedError | FetchBaseQueryError | string | null | undefined,
): string | null | undefined {
  if (typeof error === 'string' || error == null) {
    return error
  }
  const extractedError = extractError(error)
  return typeof extractedError === 'string' ? extractedError : extractedError?.message
}
