/**
 * @generated SignedSource<<726c3d79b655da11452fcc85ea90884b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BuildPageQuery$variables = {
  btLocator: string;
  headLocator: string;
};
export type BuildPageQuery$data = {
  readonly buildType: {
    readonly project: {
      readonly buildTypes: {
        readonly buildType: ReadonlyArray<{
          readonly rawId: string | null | undefined;
        }> | null | undefined;
      } | null | undefined;
      readonly internalId: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type BuildPageQuery = {
  response: BuildPageQuery$data;
  variables: BuildPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "btLocator"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "headLocator"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "btLocator",
    "variableName": "btLocator"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalId",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "locator",
    "variableName": "headLocator"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rawId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BuildPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BuildTypeRest",
        "kind": "LinkedField",
        "name": "buildType",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectRest",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "BuildTypes",
                "kind": "LinkedField",
                "name": "buildTypes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BuildTypeRest",
                    "kind": "LinkedField",
                    "name": "buildType",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BuildPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BuildTypeRest",
        "kind": "LinkedField",
        "name": "buildType",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectRest",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "BuildTypes",
                "kind": "LinkedField",
                "name": "buildTypes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BuildTypeRest",
                    "kind": "LinkedField",
                    "name": "buildType",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "27da7b5c3e5fb04846a4c741f407663f",
    "id": null,
    "metadata": {},
    "name": "BuildPageQuery",
    "operationKind": "query",
    "text": "query BuildPageQuery(\n  $btLocator: String!\n  $headLocator: String!\n) {\n  buildType(btLocator: $btLocator) {\n    project {\n      internalId\n      buildTypes(locator: $headLocator) {\n        buildType {\n          rawId\n          id\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4345e5522c7c700f9083711f3b1fe98b";

export default node;
