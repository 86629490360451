import type {ReactElement} from 'react'
import {memo, cloneElement, Children, useCallback} from 'react'
import type {NavigateOptions} from 'react-router-dom'
import {useNavigate} from 'react-router-dom'

import {getIsSakuraUI} from '../../../selectors'

import {shouldNavigate} from './RouterLinkWrapper.utils'

import {useAppSelector} from 'src/hooks/react-redux'

type Props = {
  children: ReactElement
  options?: NavigateOptions
  scrollToTop?: boolean
  insideApp?: boolean
}

function RouterLinkWrapper({children, options, scrollToTop = true, insideApp = true}: Props) {
  const navigate = useNavigate()
  const isSakuraUI = useAppSelector(getIsSakuraUI)

  const onClick = useCallback(
    (event: Event & React.KeyboardEvent) => {
      const child = Children.only(children)

      if (child.props.onClick != null) {
        child.props.onClick(event)
      }

      if (isSakuraUI && insideApp && child.props.href != null && shouldNavigate(event)) {
        event.preventDefault()
        if (child.props.href.startsWith(window.location.origin)) {
          const {pathname, search, hash} = new URL(child.props.href)
          navigate({pathname, search, hash}, options)
        } else {
          navigate(child.props.href, options)
        }

        if (scrollToTop) {
          window.scrollTo(0, 0)
        }
      }
    },
    [children, insideApp, navigate, options, scrollToTop, isSakuraUI],
  )

  return cloneElement(Children.only(children), {onClick})
}

export default memo(RouterLinkWrapper)
