import Theme, {applyTheme} from '@jetbrains/ring-ui/components/global/theme'

import * as darkThemeAdapter from '../services/theme'
import {iframeManager} from '../services/theme'

const isDarkThemeAdapterEnabled =
  window.internalProps['teamcity.ui.darkTheme.adapter.enabled'] === true

const adaptDarkTheme = (theme: Theme.DARK | Theme.LIGHT) => {
  if (!isDarkThemeAdapterEnabled) {
    return
  }

  switch (theme) {
    case Theme.DARK:
      darkThemeAdapter.enable()
      break
    case Theme.LIGHT:
    default:
      darkThemeAdapter.disable()
      break
  }
}

const setTheme = (theme: Theme.DARK | Theme.LIGHT): void => {
  adaptDarkTheme(theme)
  applyTheme(theme, document.documentElement)
  iframeManager.sendChangeThemeRequest(theme)
}

export const setGlobalTheme = (theme: Theme): (() => void) => {
  if (theme === Theme.AUTO) {
    const darkMatcher = window.matchMedia('(prefers-color-scheme: dark)')
    const handler = () => setTheme(darkMatcher.matches ? Theme.DARK : Theme.LIGHT)
    handler()
    darkMatcher.addEventListener('change', handler)
    return () => {
      darkMatcher.removeEventListener('change', handler)
      setTheme(Theme.LIGHT)
    }
  } else {
    setTheme(theme)
    return () => setTheme(Theme.LIGHT)
  }
}
