import {createSelector} from 'reselect'

import type {State} from '../../../reducers/types'
import type {GetProblemOccurrenceTreeApiArg, ProblemOccurrence} from '../../../services/rest'
import {restApi} from '../../../services/rest'
import type {ProblemOccurrenceId, ProblemsTreeNodeId} from '../../../types'
import {emptyArray, getEmptyHash} from '../../../utils/empty'
import type {KeyValue} from '../../../utils/object'
import {getLeavesHash, mergeTreeAndSubTrees, sortByDepthSubtreesKeys} from '../Tests/Tests.utils'

import type {
  ProblemOccurrencesTreeLeaf,
  ProblemOccurrencesTreeNode,
  ProblemOccurrencesSubtree,
} from './BuildProblems.types'

const getProblemOccurrencesSubtrees: (
  state: State,
  locator: string,
) => KeyValue<string, ProblemOccurrencesSubtree> | undefined | null = (state, locator) =>
  state.buildProblems.problemOccurrencesSubtree[locator]

const getSortedByDepthSubtreesKeys: (state: State, locator: string) => ReadonlyArray<string> =
  createSelector([getProblemOccurrencesSubtrees], subtrees =>
    sortByDepthSubtreesKeys(subtrees, item => item?.depth),
  )

export const getProblemOccurrencesTreeNodes: (
  state: State,
  arg: GetProblemOccurrenceTreeApiArg,
) => ReadonlyArray<ProblemOccurrencesTreeNode> | undefined | null = createSelector(
  [
    (state: State, arg: GetProblemOccurrenceTreeApiArg) =>
      restApi.endpoints.getProblemOccurrenceTree.select(arg)(state).data?.tree.node,
    (state: State, arg: GetProblemOccurrenceTreeApiArg) =>
      getProblemOccurrencesSubtrees(state, arg.treeLocator),
    (state: State, arg: GetProblemOccurrenceTreeApiArg) =>
      getSortedByDepthSubtreesKeys(state, arg.treeLocator),
  ],
  (treeNodes, subtrees, keys) =>
    mergeTreeAndSubTrees(treeNodes, keys, key => subtrees?.[key]?.data?.node),
)

export const getProblemsLeavesHash: (
  state: State,
  arg: GetProblemOccurrenceTreeApiArg,
) => KeyValue<ProblemsTreeNodeId, ProblemOccurrencesTreeLeaf> = createSelector(
  [
    (state: State, arg: GetProblemOccurrenceTreeApiArg) =>
      restApi.endpoints.getProblemOccurrenceTree.select(arg)(state).data?.tree.leaf,
    (state: State, arg: GetProblemOccurrenceTreeApiArg) =>
      getProblemOccurrencesSubtrees(state, arg.treeLocator),
    (state: State, arg: GetProblemOccurrenceTreeApiArg) =>
      getSortedByDepthSubtreesKeys(state, arg.treeLocator),
  ],
  (treeLeaves, subtrees, keys) =>
    getLeavesHash(treeLeaves, keys, key => subtrees?.[key]?.data?.leaf),
)

export const getProblemsNodesHash: (
  state: State,
  arg: GetProblemOccurrenceTreeApiArg,
) => KeyValue<ProblemsTreeNodeId, ProblemOccurrencesTreeNode> = createSelector(
  [getProblemOccurrencesTreeNodes],
  (
    nodes: ReadonlyArray<ProblemOccurrencesTreeNode> | undefined | null,
  ): KeyValue<ProblemsTreeNodeId, ProblemOccurrencesTreeNode> =>
    nodes != null ? nodes.reduce((acc, node) => ({...acc, [node.id]: node}), {}) : getEmptyHash(),
)

const getProblemOccurrencesHash: (
  state: State,
) => KeyValue<ProblemOccurrenceId, ProblemOccurrence> = state => state.entities.problemOccurrences

export const getProblemOccurrencesById: (
  state: State,
  problemOccurrenceId: ProblemOccurrenceId,
) => ProblemOccurrence | null | undefined = (state, problemOccurrenceId) =>
  getProblemOccurrencesHash(state)[problemOccurrenceId]

export const makeGetProblemOccurrencesByProblemOccurranceIds: () => (
  state: State,
  ids: ReadonlyArray<ProblemOccurrenceId>,
) => ReadonlyArray<ProblemOccurrence> = () =>
  createSelector(
    [getProblemOccurrencesHash, (state: State, ids: ReadonlyArray<ProblemOccurrenceId>) => ids],
    (
      problemOccurrences: KeyValue<ProblemOccurrenceId, ProblemOccurrence>,
      ids: ReadonlyArray<ProblemOccurrenceId>,
    ) =>
      ids.length > 0
        ? ids.map((id: ProblemOccurrenceId) => problemOccurrences[id]).filter(Boolean)
        : emptyArray,
  )
