import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'

import {ROOT_PROJECT_ID} from '../../../types'
import {makeResource} from '../../../utils/makeResource'
import {ProjectPageEntryPoint} from '../ProjectPage/ProjectPage.entryPoint'

import type {FavoriteProjectsPageOuterContainerComponent} from './FavoriteProjectsPage.container'

export const FavoriteProjectsPageEntryPoint: EntryPoint<
  FavoriteProjectsPageOuterContainerComponent,
  LoaderFunctionArgs
> = {
  root: makeResource(
    'FavoriteProjectsPage',
    () =>
      import(
        /* webpackChunkName: "FavoriteProjectsPage", webpackPrefetch: true */ './FavoriteProjectsPage.container'
      ),
  ),
  getPreloadProps: arg => ({
    entryPoints: {
      main: {
        entryPoint: ProjectPageEntryPoint,
        entryPointParams: {...arg, params: {...arg.params, projectId: ROOT_PROJECT_ID}},
      },
    },
  }),
}
