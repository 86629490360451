/**
 * @generated SignedSource<<308ac634f4e612ef1f0b87624f422d52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuildApprovalReviewers_groupApproval$data = {
  readonly currentlyApprovedBy: {
    readonly user: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"BuildApprovalReviewers_approvalUser">;
    }> | null | undefined;
  } | null | undefined;
  readonly group: {
    readonly name: string | null | undefined;
  } | null | undefined;
  readonly requiredApprovalsCount: number | null | undefined;
  readonly " $fragmentType": "BuildApprovalReviewers_groupApproval";
};
export type BuildApprovalReviewers_groupApproval$key = {
  readonly " $data"?: BuildApprovalReviewers_groupApproval$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuildApprovalReviewers_groupApproval">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuildApprovalReviewers_groupApproval",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Group",
      "kind": "LinkedField",
      "name": "group",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requiredApprovalsCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Users",
      "kind": "LinkedField",
      "name": "currentlyApprovedBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BuildApprovalReviewers_approvalUser"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GroupApprovalRule",
  "abstractKey": null
};

(node as any).hash = "95d50823cb45363909bae43b0562438d";

export default node;
