import {graphql} from 'react-relay'

export const projectPageQuery = graphql`
  query ProjectPageQuery($locator: String!) {
    project(projectLocator: $locator) {
      internalId
      ...ProjectPageFragment
    }
  }
`
