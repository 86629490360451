/**
 * @generated SignedSource<<2e0c64839a81baf45a52f545a9d7b936>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PipelineRunPagePipelineQuery$variables = {
  locator: string;
};
export type PipelineRunPagePipelineQuery$data = {
  readonly project: {
    readonly " $fragmentSpreads": FragmentRefs<"PipelineRunHeaderFragment">;
  } | null | undefined;
};
export type PipelineRunPagePipelineQuery = {
  response: PipelineRunPagePipelineQuery$data;
  variables: PipelineRunPagePipelineQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locator"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "projectLocator",
    "variableName": "locator"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rawId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PipelineRunPagePipelineQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProjectRest",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PipelineRunHeaderFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PipelineRunPagePipelineQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProjectRest",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BuildTypes",
            "kind": "LinkedField",
            "name": "buildTypes",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BuildTypeRest",
                "kind": "LinkedField",
                "name": "buildType",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "internalId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Properties",
                    "kind": "LinkedField",
                    "name": "parameters",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Property",
                        "kind": "LinkedField",
                        "name": "property",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3f7078aa45de7831376bdf8fbbe46c8b",
    "id": null,
    "metadata": {},
    "name": "PipelineRunPagePipelineQuery",
    "operationKind": "query",
    "text": "query PipelineRunPagePipelineQuery(\n  $locator: String!\n) {\n  project(projectLocator: $locator) {\n    ...PipelineRunHeaderFragment\n    id\n  }\n}\n\nfragment PipelineRunHeaderFragment on ProjectRest {\n  rawId\n  ...pipelineHeadFragment\n}\n\nfragment pipelineHeadFragment on ProjectRest {\n  buildTypes {\n    buildType {\n      rawId\n      internalId\n      parameters {\n        property {\n          name\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b6578804482e89c11c4fe9d1866f5e39";

export default node;
