export enum HTTPCodes {
  OK = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
}

export interface HTTPError {
  statusCode: number | undefined
  url: string | null | undefined
  response: Response | undefined
}

export function instanceOf<T>(object: any, member: string): object is T {
  return member in object
}

export enum ParameterNames {
  PIPELINE_HEAD = 'teamcity.internal.pipelines.isPipelineHead',
}

export enum StepTypes {
  CLI = 'script',
  MAVEN = 'maven',
  NODE = 'node-js',
  GRADLE = 'gradle',
}

export type Step = Record<string, string>

type DependencySettings = {
  files?: string[]
}

export type Dependency = string | Record<string, DependencySettings>

export type JobRepositorySettings = {
  enabled: boolean
  path?: string
}

export type Job = {
  steps?: Step[]
  name?: string
  dependencies?: Dependency[]
  integrations?: string[]
  repositories?: Record<string, JobRepositorySettings>[]
  'files-publication'?: string[]
  'runs-on'?: string
  'checkout-working-directories-only'?: boolean
  parallelism?: number
}

export type PipelineSettings = {
  name: string
  jobs?: Record<string, Job>
  parameters?: Record<string, string>
  secrets?: Record<string, string>
}

export type YamlDiagnostic = {
  from: number
  to: number
  severity: 'info' | 'warning' | 'error'
  message: string
}
