import * as React from 'react'

import {useIsAutodetectTimeZone} from '../../../../hooks/useIsAutodetectTimeZone'
import type {BuildId} from '../../../../types'
import {formatDuration, parseDateToObj} from '../../../../utils/dateTimeUtils'
import BuildAgentLink from '../../AgentLink/BuildAgentLink.container'
import Icon from '../../Icon/Icon'

import styles from './BuildDurationDetails.css'

type Row = {
  key: string
  label: React.ReactNode
  value: React.ReactNode
}
const MIN_LEFT_WITH_OVERTIME = 60
type Props = {
  buildId: BuildId
  started: string | null | undefined
  finished?: string | null | undefined
  // seconds
  elapsed: number
  // seconds
  estimated?: number
  // seconds
  left?: number
  //seconds
  overtime?: number
  showAgent?: boolean
}
export default function BuildDurationDetails({
  buildId,
  started,
  finished,
  elapsed,
  estimated,
  left,
  overtime,
  showAgent,
}: Props) {
  const isAutodetectTimeZone = useIsAutodetectTimeZone()

  const startedObj = started != null ? parseDateToObj(started, isAutodetectTimeZone) : null
  const finishedObj = finished != null ? parseDateToObj(finished, isAutodetectTimeZone) : null
  const rows: Array<Row> = []

  if (startedObj) {
    rows.push({
      key: 'started',
      label: 'Started',
      value: startedObj.format('lll'),
    })
  }

  if (finishedObj) {
    rows.push({
      key: 'finished',
      label: 'Finished',
      value: finishedObj.format('lll'),
    })

    if (startedObj) {
      rows.push({
        key: 'duration',
        label: 'Duration',
        value: formatDuration(finishedObj.diff(startedObj, 'seconds'), {
          alwaysShowSeconds: true,
        }),
      })
    }
  } else {
    rows.push({
      key: 'passed',
      label: 'Passed',
      value: formatDuration(elapsed, {
        alwaysShowSeconds: true,
      }),
    })

    if (estimated != null && estimated > 0) {
      rows.push({
        key: 'estimated',
        label: 'Estimated',
        value: formatDuration(estimated, {
          alwaysShowSeconds: true,
        }),
      })
    }

    const hasOvertime = overtime != null && overtime > 0

    if (left != null && left > 0 && (!hasOvertime || left >= MIN_LEFT_WITH_OVERTIME)) {
      rows.push({
        key: 'left',
        label: 'Left',
        value: `${hasOvertime ? '> ' : ''}${formatDuration(left || 0, {
          alwaysShowSeconds: !hasOvertime,
          alwaysHideSeconds: hasOvertime,
        })}`,
      })
    }

    if (overtime != null && overtime > 0) {
      rows.push({
        key: 'overtime',
        label: (
          <React.Fragment>
            <Icon name="overtime" className={styles.overtimeIcon} />
            {'Overtime'}
          </React.Fragment>
        ),
        value: formatDuration(overtime, {
          alwaysShowSeconds: true,
        }),
      })
    }
  }

  if (showAgent === true) {
    rows.push({
      key: 'agent',
      label: 'Agent',
      value: <BuildAgentLink buildId={buildId} />,
    })
  }

  return (
    <div className={styles.buildDurationDetails}>
      {rows.map(row => (
        <div key={row.key} className={styles.row}>
          <div className={styles.label}>{row.label}</div>

          <div className={styles.value}>{row.value}</div>
        </div>
      ))}
    </div>
  )
}
