/**
 * @generated SignedSource<<5942de36e24493f59f4e026e0072b3eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClassicUIQueryLoaderQuery$variables = Record<PropertyKey, never>;
export type ClassicUIQueryLoaderQuery$data = {
  readonly user: {
    readonly " $fragmentSpreads": FragmentRefs<"currentUserKeyFragment">;
  } | null | undefined;
};
export type ClassicUIQueryLoaderQuery = {
  response: ClassicUIQueryLoaderQuery$data;
  variables: ClassicUIQueryLoaderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "userLocator",
    "value": "current"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClassicUIQueryLoaderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "currentUserKeyFragment"
          }
        ],
        "storageKey": "user(userLocator:\"current\")"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ClassicUIQueryLoaderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rawId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Properties",
            "kind": "LinkedField",
            "name": "properties",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Property",
                "kind": "LinkedField",
                "name": "property",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAvatars",
            "kind": "LinkedField",
            "name": "avatars",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlToSize20",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlToSize28",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlToSize32",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlToSize40",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlToSize56",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlToSize64",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlToSize80",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": "user(userLocator:\"current\")"
      }
    ]
  },
  "params": {
    "cacheID": "5bd2908bc1875fa465214a92194e1600",
    "id": null,
    "metadata": {},
    "name": "ClassicUIQueryLoaderQuery",
    "operationKind": "query",
    "text": "query ClassicUIQueryLoaderQuery {\n  user(userLocator: \"current\") {\n    ...currentUserKeyFragment\n    id\n  }\n}\n\nfragment AllUserSelectFragment on User {\n  rawId\n  avatars {\n    urlToSize40\n  }\n  name\n  username\n}\n\nfragment AvatarFragment on User {\n  rawId\n  name\n  username\n  avatars {\n    urlToSize20\n    urlToSize28\n    urlToSize32\n    urlToSize40\n    urlToSize56\n    urlToSize64\n    urlToSize80\n  }\n}\n\nfragment HeaderUserFragment on User {\n  rawId\n  name\n  username\n  ...AvatarFragment\n}\n\nfragment currentUserKeyFragment on User {\n  ...useMyIdFragment\n  ...useMyUsernameFragment\n  ...useUserPropertyFragment\n  ...HeaderUserFragment\n  ...AllUserSelectFragment\n}\n\nfragment useMyIdFragment on User {\n  rawId\n}\n\nfragment useMyUsernameFragment on User {\n  username\n}\n\nfragment useUserPropertyFragment on User {\n  properties {\n    property {\n      name\n      value\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dff036f867ed1eca4c6068bb4d828b05";

export default node;
