import * as React from 'react'
import type {RouteObject} from 'react-router'
import {createBrowserRouter, redirect} from 'react-router-dom'
import type {IEnvironment} from 'relay-runtime'

import {subscribeOnRemainingAgents} from '../../actions'
import {fetchBuildsData} from '../../actions/builds'
import {fetchProjectWithAllParentsData, fetchSingleProjectData} from '../../actions/projects'
import type {ActionCreator, AppThunk} from '../../actions/types'
import getEnvironment from '../../relay/getEnvironment'
import {getChangesArg} from '../../rest/arguments'
import {getSingleBuildArg} from '../../rest/builds'
import {getBuildTypeArg} from '../../rest/buildTypes'
import HTTPError, {HTTPCodesEnum} from '../../rest/errors/HTTPError'
import {getBuildLocator, getLocator} from '../../rest/locators'
import {getTabsArg} from '../../rest/tabs'
import Routes from '../../routes'
import type {EntryPointRoute} from '../../routes/entryPointRoute'
import {entryPointRoute} from '../../routes/entryPointRoute'
import {
  getBuildsInited,
  getBuildType,
  getExtensionEndpoint,
  isBuildTypeLoaded,
} from '../../selectors'
import {restApi} from '../../services/rest'
import store from '../../store'
import type {BuildId, BuildTypeId} from '../../types'
import {
  AGENTS_TAB_PARAMS_KEY,
  BuildPageTabNamesEnum,
  BuildTypePageTabNamesEnum,
  defaultBuildTypePageTabName,
  ROOT_PROJECT_ID,
  STARRED_LOCATOR,
  stringifyId,
  toAgentId,
  toAgentPoolId,
  toAgentTypeId,
  toBuildId,
  toBuildTypeId,
  toChangeId,
  toProjectId,
} from '../../types'
import {internalProps} from '../../types/BS_types'
import {parseBranch} from '../../utils/branchNames'
import {noop} from '../../utils/empty'
import {getAvailabilityErrorMessage} from '../../utils/getAvailabilityErrorMessage'
import {notNull} from '../../utils/guards'
import type {KeyValue} from '../../utils/object'
import {getPermalinkLocator} from '../../utils/permalinks'
import type {QueryParams} from '../../utils/queryParams'
import {queryToObject} from '../../utils/queryParams'
import {combineRefetchables} from '../../utils/refetchable'
import {getTabParamsKey} from '../../utils/tabs'
import ErrorPage from '../common/ErrorPage/ErrorPage'
import {submitPager} from '../common/Pager/Pager.actions.base'
import {getPager} from '../common/Pager/Pager.selectors'
import {PagerGroup} from '../common/Pager/Pager.types'
import {getChangeStatusArg} from '../packages/Changes/Changes.rest'
import {AgentPageEntryPoint} from '../pages/AgentsPages/AgentPage/AgentPage.entryPoint'
import {agentPageOptions} from '../pages/AgentsPages/AgentPage/AgentPage.rest'
import {AgentPoolPageEntryPoint} from '../pages/AgentsPages/AgentPoolPage/AgentPoolPage.entryPoint'
import {AgentsPoolsFavoritePageEntryPoint} from '../pages/AgentsPages/AgentPoolsFavoritePage/AgentsPoolsFavoritePage.entryPoint'
import {AgentsOverviewPageEntryPoint} from '../pages/AgentsPages/AgentsOverviewPage/AgentsOverviewPage.entryPoint'
import {subscribeOnAgent, subscribeOnAgents} from '../pages/AgentsPages/AgentsPages.actions'
import {AgentsPagesEntryPoint} from '../pages/AgentsPages/AgentsPages.entryPoint'
import {AgentTypePageEntryPoint} from '../pages/AgentsPages/AgentTypePage/AgentTypePage.entryPoint'
import {UnauthorizedAgentsPageEntryPoint} from '../pages/AgentsPages/UnauthorizedAgentsPage/UnauthorizedAgentsPage.entryPoint'
import {BuildPageEntryPoint} from '../pages/BuildPage/BuildPage.entryPoint'
import BuildUnknownBuildTypePage from '../pages/BuildPage/BuildPageUnknownBuildType/BuildUnknownBuildTypePage'
import {SnapshotDependenciesModes} from '../pages/BuildPage/DependenciesTab/DependenciesTab.modes'
import {getSnapshotDependenciesLocator} from '../pages/BuildPage/DependenciesTab/DependenciesTab.utils'
import {mapStateToBuildTypeHistoryProps} from '../pages/BuildTypePage/BuildTypeOverviewTab/BuildTypeHistory/BuildTypeHistory.selectors'
import {
  defaultMode,
  Modes as OverviewBuildTypeModes,
} from '../pages/BuildTypePage/BuildTypeOverviewTab/BuildTypeOverviewTab.modes'
import {BuildTypePageEntryPoint} from '../pages/BuildTypePage/BuildTypePage.entryPoint'
import {FavoriteProjectsPageEntryPoint} from '../pages/FavoriteProjectsPage/FavoriteProjectsPage.entryPoint'
import {NotificationsPageEntryPoint} from '../pages/NotificationsPage/NotificationsPage.entryPoint'
import {pipelinesPagesRoute} from '../pages/PipelinesPages/PipelinesPages.routes'
import {isPipelinesEnabledInExclusiveMode} from '../pages/PipelinesPages/utils/featureToggles'
import {ProjectPageEntryPoint} from '../pages/ProjectPage/ProjectPage.entryPoint'
import {QueuePageEntryPoint} from '../pages/QueuePage/QueuePage.entryPoint'
import {getQueuePageLocator} from '../pages/QueuePage/QueuePage.utils'
import {TestHistoryPageEntryPoint} from '../pages/TestHistoryPage/TestHistoryPage.entryPoint'

import {AppEntryPoint} from './App.entryPoint'
import {getAvailabilityResponse, getIsAvailabilityError} from './App.selectors'

const FavoriteBuildsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "FavoriteBuildsPage", webpackPrefetch: true */
      '../pages/FavoriteBuildsPage/FavoriteBuildsPage'
    ),
)
const CompareBuildsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CompareBuildsPage", webpackPrefetch: true */
      '../pages/CompareBuildsPage/CompareBuildsPage.container'
    ),
)
const GuidesPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "GuidesPage", webpackPrefetch: true */
      '../pages/GuidesPage/GuidesPage'
    ),
)
const ChangePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ChangePage", webpackPrefetch: true */
      '../pages/ChangePage/ChangePage'
    ),
)
const ChangesPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ChangesPage", webpackPrefetch: true */
      '../pages/ChangesPage/ChangesPage'
    ),
)
const InvestigationsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "InvestigationsPage", webpackPrefetch: true */
      '../pages/InvestigationsPage/InvestigationsPage'
    ),
)
type PreloadParams = Readonly<{
  params: KeyValue<string, string>
  queryParams: QueryParams
  path: string
  context: Symbol
}>
type PreloadableRoute = EntryPointRoute<any, any> & {
  preload?: ActionCreator<PreloadParams>
}
type Redirect = Readonly<{
  from: string
  to: string
}>
const USE_NEW_QUEUE_PAGE: boolean = internalProps['teamcity.ui.sakuraQueuePage.enabled']
const USE_NEW_INVESTIGATIONS_PAGE: boolean = internalProps['teamcity.ui.newInvestigationsPage']

type Subscriptions = {
  previous: Record<string, () => void>
  current: Record<string, () => void>
  currentContext: Symbol | null
  add(context: Symbol, key: string, subscribe: () => () => void): () => void
  clear(context: Symbol, isInterrupt?: boolean): void
  reset(): void
}

export const subscriptions: Subscriptions = {
  previous: {},
  current: {},
  currentContext: null,
  add(context, key, subscribe) {
    if (context !== this.currentContext) {
      return noop
    }
    if (this.current[key]) {
      throw new Error(
        `Subscription keys for preloaded route data must be unique, got duplicate key ${key}`,
      )
    }
    if (this.previous[key]) {
      this.current[key] = this.previous[key]
      delete this.previous[key]
    } else {
      this.current[key] = subscribe()
    }
    return this.current[key]
  },
  clear(context, isInterrupt) {
    if (context !== this.currentContext) {
      return
    }
    if (isInterrupt) {
      this.previous = {...this.previous, ...this.current}
    } else {
      Object.values(this.previous).forEach(fn => fn())
      this.previous = this.current
    }
    this.current = {}
    this.currentContext = null
  },
  reset() {
    Object.values(this.previous).forEach(fn => fn())
    this.previous = {}
    this.current = {}
    this.currentContext = null
  },
}

const preloadAgentData =
  ({context}: PreloadParams): AppThunk =>
  dispatch => {
    subscriptions.add(context, 'agents', () => dispatch(subscribeOnAgents()))

    subscriptions.add(
      context,
      'poolPermissions',
      () => dispatch(restApi.endpoints.getPoolPermissions.initiate()).unsubscribe,
    )
  }

const preloadLicensingData =
  (context: Symbol): AppThunk =>
  dispatch =>
    subscriptions.add(context, 'licensingData', () => dispatch(subscribeOnRemainingAgents()))

const preloadBuildTypeData =
  (context: Symbol, buildTypeId: BuildTypeId): AppThunk =>
  async (dispatch, getState) => {
    const buildTypeArg = getBuildTypeArg(buildTypeId)
    const request = new Promise(resolve =>
      subscriptions.add(context, `buildType:${buildTypeId}`, () => {
        const result = dispatch(restApi.endpoints.getBuildTypeNormalized.initiate(buildTypeArg))
        resolve(result)
        return result.unsubscribe
      }),
    )

    if (!isBuildTypeLoaded(getState(), buildTypeId)) {
      const runningQueryThunk = await dispatch(
        restApi.util.getRunningQueryThunk('getBuildTypeNormalized', buildTypeArg),
      )
      if (runningQueryThunk === undefined) {
        await request
      }
    }

    const {projectId} = getBuildType(getState(), buildTypeId) ?? {}

    if (projectId != null) {
      subscriptions.add(
        context,
        `fetchProjectWithAllParentsData:${projectId}`,
        () => dispatch(fetchProjectWithAllParentsData(projectId)).unsubscribe,
      )
    }
  }

const preloadTabs =
  (context: Symbol, tabParamsKey: string, cacheTabs = true): AppThunk =>
  dispatch => {
    subscriptions.add(
      context,
      `tabs:${tabParamsKey}`,
      () =>
        dispatch(restApi.endpoints.getTabs.initiate(getTabsArg(tabParamsKey, cacheTabs)))
          .unsubscribe,
    )
  }

const redirects: ReadonlyArray<Redirect> = [
  {
    from: Routes.DEPRICATED_FAVORITE_BUILDS,
    to: Routes.FAVORITE_BUILDS,
  },
  {
    from: Routes.DEPRICATED_FAVORITE_PROJECTS,
    to: Routes.FAVORITE_PROJECTS,
  },
  {
    from: '*',
    to: isPipelinesEnabledInExclusiveMode
      ? Routes.PIPELINES.slice(0, -'/*'.length)
      : Routes.FAVORITE_PROJECTS,
  },
]

const favoriteProjectsRoute: PreloadableRoute = {
  path: Routes.FAVORITE_PROJECTS,
  entryPoint: FavoriteProjectsPageEntryPoint,
  preload: () => fetchSingleProjectData(ROOT_PROJECT_ID),
}

// https://reactjs.org/blog/2019/11/06/building-great-user-experiences-with-concurrent-mode-and-suspense.html#fetch-in-event-handlers
const preloadableRoutes: ReadonlyArray<PreloadableRoute> = [
  favoriteProjectsRoute,
  {
    path: Routes.AGENTS,
    entryPoint: AgentsPagesEntryPoint,
    preload: preloadAgentData,
  },
  {
    path: Routes.FAVORITE_AGENT_POOLS,
    entryPoint: AgentsPoolsFavoritePageEntryPoint,
    preload:
      (params: PreloadParams): AppThunk =>
      dispatch => {
        dispatch(preloadAgentData(params))
        dispatch(preloadLicensingData(params.context))
      },
  },
  {
    path: Routes.AGENTS_OVERVIEW,
    entryPoint: AgentsOverviewPageEntryPoint,
    preload:
      (params: PreloadParams): AppThunk =>
      dispatch => {
        dispatch(preloadTabs(params.context, AGENTS_TAB_PARAMS_KEY))
        dispatch(preloadAgentData(params))
        dispatch(preloadLicensingData(params.context))
      },
  },
  {
    path: Routes.DISCONNECTED_AGENTS_OVERVIEW,
    entryPoint: AgentsOverviewPageEntryPoint,
    preload:
      (params: PreloadParams): AppThunk =>
      dispatch => {
        dispatch(preloadTabs(params.context, AGENTS_TAB_PARAMS_KEY))
        dispatch(preloadAgentData(params))
        dispatch(preloadLicensingData(params.context))
      },
  },
  {
    path: Routes.AGENTS_UNAUTHORIZED,
    entryPoint: UnauthorizedAgentsPageEntryPoint,
    preload:
      (params: PreloadParams): AppThunk =>
      dispatch => {
        dispatch(preloadAgentData(params))
        dispatch(preloadLicensingData(params.context))
      },
  },
  {
    path: Routes.AGENT,
    entryPoint: AgentPageEntryPoint,
    preload:
      (preloadParams: PreloadParams): AppThunk =>
      (dispatch, getState) => {
        const {params, path, context} = preloadParams
        if (!getIsAvailabilityError(getState(), path)) {
          const agentId = toAgentId(params.agentId ?? 0)
          subscriptions.add(context, `agent:${agentId}`, () =>
            dispatch(subscribeOnAgent(agentId, agentPageOptions)),
          )
        }

        dispatch(preloadAgentData(preloadParams))
        dispatch(preloadLicensingData(context))
      },
  },
  {
    path: Routes.AGENT_POOL,
    entryPoint: AgentPoolPageEntryPoint,
    preload: preloadAgentData,
  },
  {
    path: Routes.CLOUD_IMAGE,
    entryPoint: AgentTypePageEntryPoint,
    preload: preloadAgentData,
  },
  {
    path: Routes.PROJECT,
    entryPoint: ProjectPageEntryPoint,
    preload:
      ({params, path, context}: PreloadParams): AppThunk =>
      (dispatch, getState) => {
        if (getIsAvailabilityError(getState(), path)) {
          return null
        }

        const projectId = toProjectId(params.projectId ?? '')

        dispatch(preloadTabs(context, getTabParamsKey({projectId})))
        return dispatch(fetchSingleProjectData(projectId))
      },
  },
  {
    path: Routes.FAVORITE_BUILDS,
    Component: FavoriteBuildsPage,
    preload:
      ({context, queryParams}: PreloadParams): AppThunk =>
      dispatch => {
        const page = Number(queryParams.page ?? 1)
        subscriptions.add(
          context,
          `favoriteBuilds:${page}`,
          () =>
            dispatch(
              fetchBuildsData({
                locator: getLocator({
                  withRunningAndQueued: true,
                  baseLocator: STARRED_LOCATOR,
                }),
                withPager: true,
                page,
                requestOptions: {
                  withBuildTypeDetails: true,
                  withSnapshotDependencies: false,
                  withQueuedInfo: true,
                  withRunningInfo: true,
                  essential: true,
                },
              }),
            ).unsubscribe,
        )
      },
  },
  {
    path: Routes.BUILD_TYPE,
    entryPoint: BuildTypePageEntryPoint,
    preload:
      ({params, queryParams, path, context}: PreloadParams): AppThunk =>
      async (dispatch, getState) => {
        const {
          buildTypeTab = defaultBuildTypePageTabName,
          mode = defaultMode,
          branch,
          state: buildState,
          tag,
          'agent-name-pattern': agentPattern,
          page = '1',
        } = queryParams
        const state = getState()

        if (getIsAvailabilityError(state, path)) {
          return
        }

        const pageSize = 50

        if (getPager(state, PagerGroup.BUILD).pageSize !== pageSize) {
          dispatch(
            submitPager({
              pageSize,
            }),
          )
        }

        const buildTypeId = toBuildTypeId(params.buildTypeId ?? '')

        const preloadBuildTypePromise = dispatch(preloadBuildTypeData(context, buildTypeId))

        const tabParamsKey = getTabParamsKey({buildTypeId, branch: parseBranch(branch)})
        subscriptions.add(
          context,
          `buildTypeTabs:${buildTypeId}:${branch}`,
          () => dispatch(restApi.endpoints.getTabs.initiate(getTabsArg(tabParamsKey))).unsubscribe,
        )

        if (
          buildTypeTab === BuildTypePageTabNamesEnum.OVERVIEW &&
          mode === OverviewBuildTypeModes.BUILDS
        ) {
          const getProps = () =>
            mapStateToBuildTypeHistoryProps(getState(), {
              buildTypeId,
              branch: parseBranch(branch),
              withCollapsedQueued: true,
              withRunningAndQueued: true,
              buildState: buildState ?? undefined,
              tag,
              agentPattern,
            })

          let unsubscribeCache = noop
          const fetch = (locator: string | null | undefined) => {
            if (locator != null) {
              unsubscribeCache()
              const result = dispatch(
                fetchBuildsData({
                  locator,
                  withPager: true,
                  page: Number(page),
                  requestOptions: {
                    withBuildTypeDetails: true,
                    withSnapshotDependencies: false,
                    withQueuedInfo: true,
                    withRunningInfo: true,
                    essential: !getBuildsInited(state, locator),
                  },
                }),
              )
              unsubscribeCache = result.unsubscribe
            }
          }

          subscriptions.add(
            context,
            `buildTypeBuildLocator:${buildTypeId}:${branch}:${buildState}:${tag}:${agentPattern}:${page}`,
            () => {
              let prevLocator: string | null | undefined = null
              const unsubscribeStore = store.subscribe(() => {
                const {locator} = getProps()
                if (locator !== prevLocator) {
                  prevLocator = locator
                  fetch(locator)
                }
              })
              return () => {
                unsubscribeStore()
                unsubscribeCache()
              }
            },
          )
        }

        await preloadBuildTypePromise
      },
  },
  {
    path: Routes.BUILD,
    entryPoint: BuildPageEntryPoint,
    preload:
      ({params, queryParams, path, context}: PreloadParams): AppThunk =>
      async (dispatch, getState) => {
        if (getIsAvailabilityError(getState(), path)) {
          return
        }

        const {
          buildTab = BuildPageTabNamesEnum.OVERVIEW,
          mode = SnapshotDependenciesModes.TIMELINE,
          branch,
        } = queryParams
        const buildTypeId = toBuildTypeId(params.buildTypeId ?? '')

        let buildId: BuildId | null | undefined
        const permalinkLocator = getPermalinkLocator(
          params.buildId ?? '',
          buildTypeId,
          parseBranch(branch),
        )

        if (permalinkLocator != null) {
          const key = `fetchPermalinkBuild:${permalinkLocator}`
          const isSubscribed = subscriptions.previous[key] != null
          const result = dispatch(
            restApi.endpoints.getBuildNormalizedAsList.initiate(
              getSingleBuildArg(permalinkLocator, {
                withBuildTypeDetails: true,
              }),
              {subscribe: !isSubscribed},
            ),
          )
          subscriptions.add(context, key, () => result.unsubscribe)
          const {data} = await result
          buildId = data?.result[0]
        } else {
          buildId = toBuildId(params.buildId ?? 0)
        }

        if (buildTab === BuildPageTabNamesEnum.DEPENDENCIES) {
          const dependenciesLocator = getLocator({
            baseLocator: getSnapshotDependenciesLocator(buildId, mode),
            withRunningAndQueued: true,
            buildState: queryParams.state ?? undefined,
          })

          if (dependenciesLocator != null) {
            subscriptions.add(
              context,
              `buildDependencies:${dependenciesLocator}`,
              () =>
                dispatch(
                  fetchBuildsData({
                    locator: dependenciesLocator,
                    requestOptions: {
                      withBuildTypeDetails: true,
                      withSnapshotDependencies: true,
                      withQueuedInfo: true,
                      withRunningInfo: true,
                      essential: true,
                    },
                  }),
                ).unsubscribe,
            )
          }
        }

        const tabParamsKey = getTabParamsKey({buildId})
        subscriptions.add(
          context,
          `buildTabs:${buildId}`,
          () =>
            dispatch(restApi.endpoints.getTabs.initiate(getTabsArg(tabParamsKey, false)))
              .unsubscribe,
        )

        if (buildId != null) {
          subscriptions.add(
            context,
            `fetchBuild:${buildId}`,
            () =>
              dispatch(
                restApi.endpoints.getBuildNormalizedAsList.initiate(
                  getSingleBuildArg(getBuildLocator(buildId!), {
                    withBuildTypeDetails: true,
                  }),
                ),
              ).unsubscribe,
          )
        }

        await dispatch(preloadBuildTypeData(context, buildTypeId))
      },
  },
  {
    path: Routes.BUILD_UNKNOWN_BUILDTYPE,
    Component: BuildUnknownBuildTypePage,
  },
  {
    path: Routes.COMPARE_BUILDS,
    Component: CompareBuildsPage,
    preload:
      ({params, queryParams, context}: PreloadParams): AppThunk =>
      (dispatch, getState) => {
        const sourceId = toBuildId(params.buildIdSource!)
        subscriptions.add(
          context,
          `fetchSourceBuild:${sourceId}`,
          () =>
            dispatch(
              restApi.endpoints.getBuildNormalizedAsList.initiate(
                getSingleBuildArg(getBuildLocator(sourceId)),
              ),
            ).unsubscribe,
        )
        if (queryParams.withBuildId != null) {
          const compareWithId = toBuildId(queryParams.withBuildId)
          subscriptions.add(
            context,
            `fetchCompareWithBuild:${compareWithId}`,
            () =>
              dispatch(
                restApi.endpoints.getBuildNormalizedAsList.initiate(
                  getSingleBuildArg(getBuildLocator(compareWithId)),
                ),
              ).unsubscribe,
          )
        }
        if (queryParams.withBuildId != null) {
          const compareWithId = toBuildId(queryParams.withBuildId)
          const {endpoint} = getExtensionEndpoint(getState(), 'builds-diff') ?? {}
          if (endpoint != null) {
            subscriptions.add(
              context,
              `fetchCompareBuildsList:${sourceId}:${compareWithId}`,
              () =>
                dispatch(
                  restApi.endpoints.getCompareBuildsList.initiate({
                    endpoint,
                    sourceId,
                    compareWithId,
                  }),
                ).unsubscribe,
            )
          }
        }
      },
  },
  {
    path: Routes.TEST,
    entryPoint: TestHistoryPageEntryPoint,
  },
  {
    path: Routes.CHANGE,
    Component: ChangePage,
    preload:
      ({params, queryParams, context}: PreloadParams): AppThunk =>
      dispatch => {
        const changeId = toChangeId(params.changeId ?? '')
        const personal = queryParams?.personal === 'true'
        const locator = `id:${stringifyId(changeId)},personal:${personal}`

        subscriptions.add(
          context,
          `fetchChanges:${locator}`,
          () =>
            combineRefetchables<unknown>([
              dispatch(restApi.endpoints.getAllChanges.initiate(getChangesArg(locator))),
              dispatch(restApi.endpoints.getAllChangesKeyed.initiate(getChangeStatusArg(locator))),
            ]).unsubscribe,
        )
        dispatch(preloadTabs(context, getTabParamsKey({changeId, personal})))
      },
  },
  {
    path: Routes.CHANGES,
    Component: ChangesPage,
  },
  {
    path: Routes.GUIDES,
    Component: GuidesPage,
  },
  USE_NEW_QUEUE_PAGE
    ? {
        path: Routes.QUEUE,
        entryPoint: QueuePageEntryPoint,
        preload:
          ({queryParams, context}: PreloadParams): AppThunk =>
          (dispatch, getState) => {
            const state = getState()

            const page = (queryParams.page && parseInt(queryParams.page, 10)) || 1
            dispatch(
              submitPager({
                precountedPages: 3,
                pageSize: 50,
                lookupLimit: 10000,
                lookupDelta: 10000,
              }),
            )
            const activeAgentPoolIdString = queryParams.agentPoolId
            const activeAgentPoolId =
              activeAgentPoolIdString != null ? toAgentPoolId(activeAgentPoolIdString) : null
            const onlyMyPersonal = queryParams.onlyMyPersonal === 'true'
            const locator = getQueuePageLocator(activeAgentPoolId, onlyMyPersonal)

            if (!getBuildsInited(state, locator)) {
              subscriptions.add(
                context,
                `buildQueue:${locator}:${page}`,
                () =>
                  dispatch(
                    fetchBuildsData({
                      locator,
                      withPager: true,
                      page,
                      requestOptions: {
                        customEndpoint: '/buildQueue',
                        withBuildTypeDetails: true,
                        withSnapshotDependencies: false,
                        withQueuedInfo: true,
                        essential: true,
                      },
                    }),
                  ).unsubscribe,
              )
            }
          },
      }
    : null,
  USE_NEW_INVESTIGATIONS_PAGE
    ? {
        path: Routes.INVESTIGATIONS,
        Component: InvestigationsPage,
      }
    : null,
  pipelinesPagesRoute,
  {path: Routes.NOTIFICATIONS, entryPoint: NotificationsPageEntryPoint},

  // !!! DON'T FORGET !!!
  // !!! 1) ADD the mapping to web-startup-webapp/webapp/WEB-INF/web.xml AND /react-ui/src/routes/shared-routes.json !!!
  // !!! 2) EXECUTE the prepareRingRouting(gradle task) !!!
].filter(notNull)

export const getRoutes = (environment: IEnvironment) =>
  preloadableRoutes.map(({preload, ...route}): RouteObject => {
    const {loader, ...rest} = entryPointRoute(route, environment)
    return {
      ...rest,
      ErrorBoundary: ErrorPage,
      loader(arg) {
        const {params, request} = arg
        const url = new URL(request.url)

        if (subscriptions.currentContext != null) {
          subscriptions.clear(subscriptions.currentContext, true)
        }
        const context = Symbol()
        subscriptions.currentContext = context
        const availabilityResponse = getAvailabilityResponse(store.getState(), url.pathname)
        if (availabilityResponse != null && availabilityResponse.statusCode !== HTTPCodesEnum.OK) {
          subscriptions.clear(context)
          const {buildId, buildTypeId, projectId, agentId, agentTypeId, agentPoolId} = params
          throw new HTTPError(
            getAvailabilityErrorMessage({
              error: availabilityResponse,
              buildId: buildId != null ? toBuildId(buildId) : null,
              buildTypeId: buildTypeId != null ? toBuildTypeId(buildTypeId) : null,
              projectId: projectId != null ? toProjectId(projectId) : null,
              agentId: agentId != null ? toAgentId(agentId) : null,
              agentTypeId: agentTypeId != null ? toAgentTypeId(agentTypeId) : null,
              agentPoolId: agentPoolId != null ? toAgentPoolId(agentPoolId) : null,
            }) ?? '',
            availabilityResponse.statusCode,
          )
        }
        ;(async () => {
          if (preload != null) {
            await store.dispatch(
              preload({
                params,
                queryParams: queryToObject(url.search),
                path: url.pathname,
                context,
              }),
            )
          }
          subscriptions.clear(context)
        })()

        return loader?.(arg) ?? {}
      },
    }
  })
export const createAppRouter = () =>
  createBrowserRouter([
    entryPointRoute(
      {
        path: Routes.BASE,
        entryPoint: AppEntryPoint,
        ErrorBoundary: ErrorPage,
        children: [
          ...redirects.map(({from, to}) => ({
            path: from,
            loader() {
              throw redirect(to)
            },
            element: null,
          })),
          ...getRoutes(getEnvironment()),
        ],
      },
      getEnvironment(),
    ),
    {
      path: '*',
      loader() {
        throw new HTTPError('Page not found', HTTPCodesEnum.NOT_FOUND)
      },
      ErrorBoundary: ErrorPage,
    },
  ])
export type Router = ReturnType<typeof createBrowserRouter>
