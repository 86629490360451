import Theme, {ThemeProvider} from '@jetbrains/ring-ui/components/global/theme'
import type {ReactNode} from 'react'

import styles from './HeaderBanner.css'

type Props = {
  content: ReactNode
  actions?: ReactNode
}

function HeaderBanner({content, actions}: Props) {
  return (
    <ThemeProvider theme={Theme.DARK}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.description}>{content}</div>
          <div className={styles.actions}>{actions}</div>
        </div>
      </div>
    </ThemeProvider>
  )
}

export default HeaderBanner
