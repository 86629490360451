import {graphql} from 'react-relay'

export const query = graphql`
  query AgentTypePageQuery($locator: String!) {
    ...AgentsPagesCanViewAgentDetailsFragment
    agentType(agentTypeLocator: $locator) {
      ...AgentTypePage_agentType
    }
  }
`
