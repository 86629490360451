import {castDraft} from 'immer'

import type {PayloadAction} from '@reduxjs/toolkit'
import {createReducer, isAnyOf} from '@reduxjs/toolkit'
import * as Redux from 'redux'

import {resetState} from '../actions'
import * as serviceWorkerUpdateActions from '../actions/serviceWorkers'
import {
  allProjectsExpandState,
  searchProjectsExpandState,
  sidebar,
} from '../components/App/SidebarPanel/SidebarPanelContent/ProjectsSidebar/ProjectsSidebar.slices'
import {queueSidebar} from '../components/App/SidebarPanel/SidebarPanelContent/QueueSidebar/QueueSidebar.reducers'
import {toolPanelFooter} from '../components/App/ToolPanelFooter/ToolPanelFooter.slices'
import pagerReducer from '../components/common/Pager/Pager.reducer'
import {
  getTriggerBuildKey,
  runCustomBuildAction,
} from '../components/common/RunBuild/RunBuild.actions'
import {buildLogReducers} from '../components/packages/BuildLog/BuildLog.reducers'
import buildProblems from '../components/packages/BuildProblems/BuildProblems.reducers'
import changeFiles from '../components/packages/Changes/ChangeFiles/ChangeFiles.reducers'
import changesDropdown from '../components/packages/Changes/ChangesDropdown/ChangesDropdown.reducers'
import type {DslActionPayload} from '../components/packages/Dsl/Dsl.slices'
import {
  dslFragment,
  dslOptions,
  dslPortable,
  dslVersion,
  toggleDsl,
} from '../components/packages/Dsl/Dsl.slices'
import {hints} from '../components/packages/Hints/Hints.slices'
import {parameterGroups} from '../components/packages/Parameters/Parameters.slice'
import buildProblemsSubtree from '../components/packages/Problems/BuildProblems/BuildProblems.reducers'
import {selectedBuildProblems} from '../components/packages/Problems/BuildProblems/BuildProblems.slices'
import {selectedTestProblems} from '../components/packages/Problems/TestProblems/TestProblems.slices'
import {shortcuts} from '../components/packages/Shortcuts/Shortcuts.slices'
import tests from '../components/packages/Tests/Tests.reducers'
import type {TestFlaky} from '../components/packages/Tests/Tests.types'
import {agentsPagesReducers} from '../components/pages/AgentsPages/AgentsPages.reducers'
import {getDeliveredArtifactsKey} from '../components/pages/BuildPage/DependenciesTab/DependenciesTab.utils'
import {dependenciesTimelineSearch} from '../components/pages/BuildPage/DependenciesTab/DependenciesTimeline/DependenciesTimeline.slice'
import cleanupPolicies from '../components/pages/CleanupProjectPage/CleanupProjectPage.reducers'
import cleanup from '../components/pages/CleanupProjectPage/Listings/Rules/Rules.reducers'
import {compareBuildsPageReducers} from '../components/pages/CompareBuildsPage/CompareBuildsPage.reducers'
import httpsConfigurationPageReducer from '../components/pages/HttpsConfigurationPage/HttpsConfigurationPage.reducers'
import {pipelines} from '../components/pages/PipelinesPages/PipelinesPages.reducers'
import projectPage from '../components/pages/ProjectPage/ProjectPage.reducers'
import type {StatusKey} from '../rest/schemata'
import {restApi} from '../services/rest'
import {
  agentsInCloud,
  blocks,
  builds as buildsSlice,
  buildTab,
  buildTypesLimit,
  buildTypeTab,
  cachedPlugins,
  dialog,
  dummyCalls,
  haveDependants,
  isSakuraUI,
  queuedTogglerAutoExpand,
  routeAvailabilityResponse,
  serverInfo,
  showQueuedBuildsCount,
  showQueuedBuildsInBuildsList,
  showQueuedBuildsInProject,
  showQueuedBuildsPerBranch,
  sorting,
  stoppingBuilds,
  syncStorageValues,
  urlExtensions,
} from '../slices'
import {buildSelections} from '../slices/buildSelections'
import buildsFilters from '../slices/buildsFilters'
import {initialTheme} from '../slices/initialTheme'
import type {BuildId, BuildTypeId, ProjectId, TestId, DslOptions} from '../types'
import type {KeyValue} from '../utils/object'

import {combineReducers} from './combineReducers'
import entities from './entities'
import federationServersDataReducer from './federationServersData'
import type {ProjectsState, State} from './types'
import {clientId} from './types'
import {keyValueReducer} from './utils'

const mainReducer = combineReducers({
  clientId: () => clientId,

  entities,

  buildTypeStatuses: createReducer({}, builder => {
    builder.addMatcher(restApi.endpoints.getStatuses.matchFulfilled, (state, action) => {
      Object.assign(state, action.payload)
    })
  }),

  buildsDetailsLoading: createReducer<Record<string, boolean>>({}, builder => {
    builder.addMatcher(
      restApi.endpoints.getAllBuildsDetailsNormalized.matchPending,
      (state, action) => {
        const {detailsLocatorToStore} = action.meta.arg.originalArgs
        if (detailsLocatorToStore != null) {
          state[detailsLocatorToStore] = true
        }
      },
    )
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getAllBuildsDetailsNormalized.matchFulfilled,
        restApi.endpoints.getAllBuildsDetailsNormalized.matchRejected,
        serviceWorkerUpdateActions.getAllBuildsNormalized.match,
      ),
      (state, action) => {
        const {detailsLocatorToStore} = action.meta.arg.originalArgs
        if (detailsLocatorToStore != null) {
          state[detailsLocatorToStore] = false
        }
      },
    )
  }),

  federationServersData: federationServersDataReducer,

  isSakuraUI: isSakuraUI.reducer,

  urlExtensions: urlExtensions.reducer,

  routeAvailabilityResponse: routeAvailabilityResponse.reducer,
  pager: pagerReducer,
  blocks: blocks.reducer,

  dialog: dialog.reducer,

  builds: buildsSlice.reducer,

  flakyTests: createReducer<KeyValue<BuildId, KeyValue<TestId, TestFlaky>>>({}, builder => {
    builder.addMatcher(restApi.endpoints.getFlakyTests.matchFulfilled, (state, action) => {
      action.payload.forEach(test => {
        const {buildId} = action.meta.arg.originalArgs
        state[buildId] ??= {}
        state[buildId]![test.id] = castDraft(test)
      })
    })
  }),
  projects: createReducer<KeyValue<ProjectId, ProjectsState>>({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getAllProjectsNormalized.matchFulfilled,
        serviceWorkerUpdateActions.getAllProjectsNormalized,
      ),
      (state, action) => {
        const {projectId, projectReceiveMeta, archived} = action.meta.arg.originalArgs
        if ((archived == null || archived === 'false') && projectId != null) {
          state[projectId] = castDraft({
            data: action.payload.result,
            loaded: true,
            receiveMeta: {...state[projectId]?.receiveMeta, ...projectReceiveMeta},
          })
        }
      },
    )
  }),
  projectsWithArchived: createReducer<KeyValue<ProjectId, ProjectsState>>({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getAllProjectsNormalized.matchFulfilled,
        serviceWorkerUpdateActions.getAllProjectsNormalized,
      ),
      (state, action) => {
        const {projectId, projectReceiveMeta, archived} = action.meta.arg.originalArgs
        if (archived === 'any' && projectId != null) {
          state[projectId] = castDraft({
            data: action.payload.result,
            loaded: true,
            receiveMeta: {...state[projectId]?.receiveMeta, ...projectReceiveMeta},
          })
        }
      },
    )
  }),

  stoppingBuilds: stoppingBuilds.reducer,

  startingBuilds: createReducer<Record<StatusKey, boolean>>({}, builder => {
    builder.addCase(runCustomBuildAction.pending, (state, action) => {
      state[getTriggerBuildKey(action.meta.arg)] = true
    })
    builder.addMatcher(
      isAnyOf(runCustomBuildAction.fulfilled, runCustomBuildAction.rejected),
      (state, action) => {
        state[getTriggerBuildKey(action.meta.arg)] = false
      },
    )
    builder.addMatcher(restApi.endpoints.runBuild.matchPending, (state, action) => {
      state[getTriggerBuildKey(action.meta.arg.originalArgs)] = true
    })
    builder.addMatcher(
      isAnyOf(restApi.endpoints.runBuild.matchFulfilled, restApi.endpoints.runBuild.matchRejected),
      (state, action) => {
        state[getTriggerBuildKey(action.meta.arg.originalArgs)] = false
      },
    )
  }),

  togglingOverview: combineReducers({
    project: createReducer<Record<ProjectId, boolean | null>>({}, builder => {
      builder.addMatcher(restApi.endpoints.toggleFavoriteProject.matchPending, (state, action) => {
        const {projectId, on} = action.meta.arg.originalArgs
        state[projectId] = on
      })
      builder.addMatcher(
        isAnyOf(
          restApi.endpoints.toggleFavoriteProject.matchFulfilled,
          restApi.endpoints.toggleFavoriteProject.matchRejected,
        ),
        (state, action) => {
          const {projectId} = action.meta.arg.originalArgs
          state[projectId] = null
        },
      )
    }),
    bt: createReducer<Record<BuildTypeId, boolean | null>>({}, builder => {
      builder.addMatcher(
        restApi.endpoints.toggleFavoriteBuildType.matchPending,
        (state, action) => {
          const {buildTypeId, on} = action.meta.arg.originalArgs
          state[buildTypeId] = on
        },
      )
      builder.addMatcher(
        isAnyOf(
          restApi.endpoints.toggleFavoriteBuildType.matchFulfilled,
          restApi.endpoints.toggleFavoriteBuildType.matchRejected,
        ),
        (state, action) => {
          const {buildTypeId} = action.meta.arg.originalArgs
          state[buildTypeId] = null
        },
      )
      builder.addMatcher(restApi.endpoints.setFavoriteBuildTypes.matchPending, (state, action) => {
        action.meta.arg.originalArgs.buildTypeIds.forEach(buildTypeId => {
          state[buildTypeId] = true
        })
      })
      builder.addMatcher(
        isAnyOf(
          restApi.endpoints.setFavoriteBuildTypes.matchFulfilled,
          restApi.endpoints.setFavoriteBuildTypes.matchRejected,
        ),
        (state, action) => {
          action.meta.arg.originalArgs.buildTypeIds.forEach(buildTypeId => {
            state[buildTypeId] = null
          })
        },
      )
    }),
  }),

  buildsFilters: buildsFilters.reducer,

  sorting: sorting.reducer,

  agentsInCloud: agentsInCloud.reducer,

  haveDependants: haveDependants.reducer,

  buildTypeTab: buildTypeTab.reducer,

  buildTab: buildTab.reducer,

  projectPage,

  sidebar: sidebar.reducer,

  toolPanelFooter: toolPanelFooter.reducer,

  dslOptions: createReducer<KeyValue<string, DslOptions>>({}, builder => {
    builder.addDefaultCase(
      keyValueReducer(
        (action: PayloadAction<DslActionPayload>) => action.payload.controlId,
        dslOptions.reducer,
        [
          toggleDsl.actions.show,
          toggleDsl.actions.hide,
          dslVersion.actions.set,
          dslPortable.actions.set,
          dslOptions.actions.init,
        ],
      ),
    )
  }),

  dslFragment: dslFragment.reducer,

  showQueuedBuildsPerBranch: showQueuedBuildsPerBranch.reducer,
  showQueuedBuildsCount: showQueuedBuildsCount.reducer,
  showQueuedBuildsInProject: showQueuedBuildsInProject.reducer,

  showQueuedBuildsInBuildsList: showQueuedBuildsInBuildsList.reducer,

  serverInfo: serverInfo.reducer,

  dummyCalls: dummyCalls.reducer,

  cachedPlugins: cachedPlugins.reducer,
  syncStorageValues: syncStorageValues.reducer,
  queuedToggler: Redux.combineReducers({
    autoExpand: queuedTogglerAutoExpand.reducer,
    hasTriggeredByMeBuilds: createReducer<KeyValue<BuildTypeId, boolean | null>>({}, builder => {
      builder.addCase(runCustomBuildAction.fulfilled, (state, action) => {
        state[action.meta.arg.buildTypeId] = true
      })
      builder.addMatcher(restApi.endpoints.runBuild.matchFulfilled, (state, action) => {
        state[action.meta.arg.originalArgs.buildTypeId] = true
      })
    }),
  }),
  compareBuilds: compareBuildsPageReducers,
  cleanup,
  cleanupPolicies,
  buildLog: buildLogReducers,
  agentsPage: agentsPagesReducers,
  changesDropdown,
  changeFiles,
  tests,
  buildProblems,
  buildProblemsSubtree,
  queueSidebar,
  hints: hints.reducer,
  shortcuts: shortcuts.reducer,
  https: httpsConfigurationPageReducer,

  buildTypesLimit: buildTypesLimit.reducer,

  deliveredArtifacts: createReducer<KeyValue<string, readonly string[]>>({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getAllBuildsNormalized.matchFulfilled,
        serviceWorkerUpdateActions.getAllBuildsNormalized.match,
      ),
      (state, action) => {
        const from = action.meta.arg.originalArgs.withDownloadedArtifactsFrom
        if (from != null) {
          for (const to of action.payload.result) {
            const download =
              action.payload.entities.builds?.[to]?.downloadedArtifacts?.downloadInfo[0]
            if (download != null) {
              state[getDeliveredArtifactsKey(from, to)] = download.artifactInfo?.map(
                item => item.path ?? '',
              )
            }
          }
        }
      },
    )
  }),
  buildSelections: buildSelections.reducer,
  pipelines,
  [restApi.reducerPath]: restApi.reducer,
  dependenciesTimelineSearch: dependenciesTimelineSearch.reducer,
  initialTheme: initialTheme.reducer,

  allProjectsExpandState: allProjectsExpandState.reducer,

  searchProjectsExpandState: searchProjectsExpandState.reducer,

  parameterGroups: parameterGroups.reducer,

  selectedBuildProblems: selectedBuildProblems.reducer,

  selectedTestProblems: selectedTestProblems.reducer,
})
export type MainReducer = typeof mainReducer
export default (state: Partial<State> | undefined, action: PayloadAction<unknown>): State =>
  resetState.match(action) ? action.payload : mainReducer(state, action)
