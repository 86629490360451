import {createAction} from '@reduxjs/toolkit'

import {restApi} from '../services/rest'
import type {AgentId} from '../types'

import type {AppThunk} from './types'

export const removeAgentAction = createAction<AgentId>('removeAgent')
export const removeAgent =
  (id: AgentId): AppThunk =>
  dispatch => {
    dispatch(removeAgentAction(id))
    dispatch(restApi.util.invalidateTags([{type: 'Agent', id}]))
  }
