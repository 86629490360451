import Dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown'
import classnames from 'classnames'
import type {ReactElement, ReactNode} from 'react'
import * as React from 'react'

import Icon from '../Icon/Icon'
import Link from '../Link/Link'
import MiddleEllipsis from '../MiddleEllipsis/MiddleEllipsis'
import Popup from '../Popup/Popup.lazy'

import styles from './LinkWithIcon.css'

type Props = {
  children?: string | null
  icon?: string | null
  className?: string
  iconClassName?: string
  iconInnerClassName?: string
  iconPlaceholder: ReactElement | null
  iconTitle?: ReactNode | null
  textTitle?: string | null
  title?: string
  tailLength: number
  side: 'left' | 'right'
  href: string
  relative?: boolean
  onPlainLeftClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
  rightNodes?: ReactNode
  noLink?: boolean
}

function LinkWithIcon({
  children,
  icon,
  className,
  iconClassName,
  iconInnerClassName,
  iconPlaceholder,
  side,
  tailLength,
  iconTitle,
  textTitle,
  rightNodes,
  noLink,
  ...restProps
}: Props) {
  const iconClasses = classnames(styles.icon, iconClassName, styles[side])
  const classes = classnames(styles.link, className)
  const popupClasses = classnames(styles.popup, 'popupDiv')
  const iconDiv =
    icon != null ? (
      <div className={iconClasses}>
        <Icon className={iconInnerClassName} name={icon} />
      </div>
    ) : (
      iconPlaceholder
    )
  const childrenWithEllipsis = React.useMemo(
    () =>
      children != null ? (
        <MiddleEllipsis title={textTitle} tailLength={tailLength}>
          {children}
        </MiddleEllipsis>
      ) : null,
    [children, tailLength, textTitle],
  )

  const props = {
    ...restProps,
    className: classes,
    'data-test-link-with-icon': icon != null ? icon : 'none',
  }
  const content = (
    <>
      {iconTitle != null ? (
        <Dropdown anchor={iconDiv ?? ''} hoverMode clickMode={false}>
          <Popup className={popupClasses}>{iconTitle}</Popup>
        </Dropdown>
      ) : (
        iconDiv
      )}
      {children != null && <div className={styles.text}>{childrenWithEllipsis}</div>}
      {rightNodes}
    </>
  )

  return noLink ? <span {...props}>{content}</span> : <Link {...props}>{content}</Link>
}

LinkWithIcon.defaultProps = {
  side: 'left',
  tailLength: 5,
  iconPlaceholder: null,
}
export default LinkWithIcon
