import {noop} from './empty'

interface ISafeStorage {
  storage?: Storage
  getItem(key: string): string | null | undefined
  getItemInJSON<T>(key: string): T | null | undefined
  setItem(key: string, data: string): void
  setItemInJSON(key: string, data: unknown): void
  removeItem(key: string): void
}
const undefinedStorage: ISafeStorage = {
  getItem: () => null,
  getItemInJSON: () => null,
  setItem: noop,
  setItemInJSON: noop,
  removeItem: noop,
}

class SafeStorage implements ISafeStorage {
  /* $FlowFixMe This comment suppresses an error found when upgrading Flow to
   * v0.146.0. To view the error, delete this comment and run Flow. */
  storage: Storage
  constructor(storage: Storage) {
    this.storage = storage
  }

  getItem(key: string): string | null | undefined {
    try {
      return this.storage.getItem(key)
    } catch (e) {
      return null
    }
  }

  getItemInJSON<T>(key: string): T | null | undefined {
    try {
      const data: string | null | undefined = this.storage.getItem(key)

      if (data != null) {
        return JSON.parse(data)
      }

      return null
    } catch (e) {
      return null
    }
  }

  setItem(key: string, data: string): void {
    try {
      this.storage.setItem(key, data)
    } catch (e) {
      // ignore
    }
  }

  setItemInJSON(key: string, data: unknown) {
    this.setItem(key, JSON.stringify(data))
  }

  removeItem(key: string): void {
    try {
      this.storage.removeItem(key)
    } catch (e) {
      // ignore
    }
  }
}

function getSafeStorage(storageName: 'localStorage' | 'sessionStorage') {
  try {
    return new SafeStorage(window[storageName])
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(`${storageName} not supported`)
    return undefinedStorage
  }
}

export const safeLocalStorage: ISafeStorage = getSafeStorage('localStorage')
