/**
 * @generated SignedSource<<f09ee6a9ab979fc8757afc2be6cb2405>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AgentPoolPageProjectsConnectionFragment$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly agentPools: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly name: string | null | undefined;
            readonly rawId: string;
          };
        }>;
      } | null | undefined;
      readonly ancestorProjects: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly archived: boolean | null | undefined;
            readonly id: string;
            readonly name: string | null | undefined;
            readonly rawId: string;
          };
        }>;
      } | null | undefined;
      readonly archived: boolean | null | undefined;
      readonly id: string;
      readonly name: string | null | undefined;
      readonly permissions: {
        readonly manageAgentPoolsForProject: boolean | null | undefined;
      } | null | undefined;
      readonly rawId: string;
    };
  }>;
  readonly " $fragmentType": "AgentPoolPageProjectsConnectionFragment";
};
export type AgentPoolPageProjectsConnectionFragment$key = {
  readonly " $data"?: AgentPoolPageProjectsConnectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AgentPoolPageProjectsConnectionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rawId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "archived",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AgentPoolPageProjectsConnectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AgentPoolProjectEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Project",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectPermissions",
              "kind": "LinkedField",
              "name": "permissions",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "manageAgentPoolsForProject",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectAgentPoolsConnection",
              "kind": "LinkedField",
              "name": "agentPools",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectAgentPoolEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectsConnection",
              "kind": "LinkedField",
              "name": "ancestorProjects",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Project",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        (v2/*: any*/),
                        (v3/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AgentPoolProjectsConnection",
  "abstractKey": null
};
})();

(node as any).hash = "7126e9589b5cf71a3a98a2aeed295ad2";

export default node;
