/**
 * @generated SignedSource<<1f12cfbeaba38bc2baaff752b6c2c457>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AgentPoolPageProjectsQuery$variables = {
  withPermissions?: boolean | null | undefined;
};
export type AgentPoolPageProjectsQuery$data = {
  readonly projects: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly agentPools: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly name: string | null | undefined;
              readonly rawId: string;
            };
          }>;
        } | null | undefined;
        readonly ancestorProjects: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly archived: boolean | null | undefined;
              readonly id: string;
              readonly name: string | null | undefined;
              readonly rawId: string;
            };
          }>;
        } | null | undefined;
        readonly archived: boolean | null | undefined;
        readonly id: string;
        readonly name: string | null | undefined;
        readonly permissions?: {
          readonly manageAgentPoolsForProject: boolean | null | undefined;
        } | null | undefined;
        readonly rawId: string;
      };
    }>;
  } | null | undefined;
};
export type AgentPoolPageProjectsQuery = {
  response: AgentPoolPageProjectsQuery$data;
  variables: AgentPoolPageProjectsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": false,
    "kind": "LocalArgument",
    "name": "withPermissions"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "archived": false
    }
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rawId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "archived",
  "storageKey": null
},
v6 = {
  "condition": "withPermissions",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectPermissions",
      "kind": "LinkedField",
      "name": "permissions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manageAgentPoolsForProject",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectsConnection",
  "kind": "LinkedField",
  "name": "ancestorProjects",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Project",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentPoolPageProjectsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProjectsConnection",
        "kind": "LinkedField",
        "name": "projects",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectAgentPoolsConnection",
                    "kind": "LinkedField",
                    "name": "agentPools",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectAgentPoolEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "projects(filter:{\"archived\":false})"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AgentPoolPageProjectsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProjectsConnection",
        "kind": "LinkedField",
        "name": "projects",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectAgentPoolsConnection",
                    "kind": "LinkedField",
                    "name": "agentPools",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectAgentPoolEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              },
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "projects(filter:{\"archived\":false})"
      }
    ]
  },
  "params": {
    "cacheID": "23b257b81103a25f75cf03d6ad2efeb3",
    "id": null,
    "metadata": {},
    "name": "AgentPoolPageProjectsQuery",
    "operationKind": "query",
    "text": "query AgentPoolPageProjectsQuery(\n  $withPermissions: Boolean = false\n) {\n  projects(filter: {archived: false}) {\n    edges {\n      node {\n        id\n        rawId\n        name\n        archived\n        permissions @include(if: $withPermissions) {\n          manageAgentPoolsForProject\n        }\n        agentPools {\n          edges {\n            node {\n              __typename\n              id\n              rawId\n              name\n            }\n          }\n        }\n        ancestorProjects {\n          edges {\n            node {\n              id\n              rawId\n              name\n              archived\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1ad832cff6f455ceb2ad0b072dd2b53f";

export default node;
