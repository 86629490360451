import {createAction} from '@reduxjs/toolkit'

import type {AppThunk} from '../../../../actions/types'
import {
  EXPAND_FAVORITE_SIDEBAR_STATE_KEY,
  EXPAND_SIDEBAR_STATE_KEY,
  setStoredExpandState,
} from '../AgentsPages.effects'
import {agentsTreeSearchState} from '../AgentsPages.slices'

import type {AgentBranchId, AgentBranch} from './AgentsSidebar.types'

export const setAgentsTreeItemExpandStateAction = createAction<{
  agentItemId?: AgentBranchId
  agentItemType: AgentBranch
  expanded: boolean
}>('setAgentsTreeItemExpandState')

export const setAgentsTreeItemExpandState =
  (
    agentItemId: AgentBranchId | undefined,
    agentItemType: AgentBranch,
    expanded: boolean,
  ): AppThunk<any> =>
  (dispatch, getState) => {
    dispatch(
      setAgentsTreeItemExpandStateAction({
        agentItemId,
        agentItemType,
        expanded,
      }),
    )
    setStoredExpandState(EXPAND_SIDEBAR_STATE_KEY, getState().agentsPage.agentsTreeExpandState)
  }

export const setFavoriteAgentsTreeItemExpandStateAction = createAction<{
  agentItemId?: AgentBranchId
  agentItemType: AgentBranch
  expanded: boolean
}>('setFavoriteAgentsTreeItemExpandState')

export const setFavoriteAgentsTreeItemExpandState =
  (
    agentItemId: AgentBranchId | undefined,
    agentItemType: AgentBranch,
    expanded: boolean,
  ): AppThunk<any> =>
  (dispatch, getState) => {
    dispatch(
      setFavoriteAgentsTreeItemExpandStateAction({
        agentItemId,
        agentItemType,
        expanded,
      }),
    )
    setStoredExpandState(
      EXPAND_FAVORITE_SIDEBAR_STATE_KEY,
      getState().agentsPage.favoriteAgentsTreeExpandState,
    )
  }
export const searchInAgentsTree = agentsTreeSearchState.actions.search
