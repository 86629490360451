import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {makeResource} from '../../../../utils/makeResource'
import {agentsPagesQueries} from '../AgentsPages.queries'

import type {AgentsSidebarRelayContainerComponent} from './AgentsSidebar'
import {query} from './AgentsSidebar.queries'

export const AgentsSidebarEntryPoint: EntryPoint<AgentsSidebarRelayContainerComponent> = {
  root: makeResource(
    'AgentsSidebar',
    () => import(/* webpackChunkName: "AgentsSidebar", webpackPrefetch: true */ './AgentsSidebar'),
  ),
  getPreloadProps: () => ({
    queries: {
      ...agentsPagesQueries,
      main: {
        parameters: getRequest(query),
        variables: {},
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
    },
  }),
}
