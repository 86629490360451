import {createSelector} from 'reselect'

import type {State} from '../../../reducers/types'
import {restApi} from '../../../services/rest'
import type {GetTestOccurrenceTreeApiArg, Mute, TestOccurrence} from '../../../services/rest'
import type {
  BuildId,
  BuildTypeId,
  Investigation,
  BuildTypeHierarchy,
  TestId,
  TestOccurrenceId,
  TestsTreeNodeId,
} from '../../../types'
import {emptyArray, getEmptyHash} from '../../../utils/empty'
import {notNull} from '../../../utils/guards'
import type {KeyValue} from '../../../utils/object'

import type {TestOccurrencesSubtree} from './Tests.reducers'
import type {
  TestFlaky,
  TestOccurrencesCounts,
  TestOccurrencesTreeLeaf,
  TestOccurrencesTreeNode,
} from './Tests.types'
import {getLeavesHash, mergeTreeAndSubTrees, sortByDepthSubtreesKeys} from './Tests.utils'

const getTestOccurrencesSubtrees: (
  state: State,
  locator: string,
) => KeyValue<string, TestOccurrencesSubtree> | undefined | null = (state, locator) =>
  state.tests.testOccurrencesSubtree[locator]

const getSortedByDepthSubtreesKeys: (state: State, locator: string) => ReadonlyArray<string> =
  createSelector([getTestOccurrencesSubtrees], subtrees =>
    sortByDepthSubtreesKeys(subtrees, item => item?.depth),
  )

export const getTestOccurrencesTreeNodes: (
  state: State,
  arg: GetTestOccurrenceTreeApiArg,
) => ReadonlyArray<TestOccurrencesTreeNode> | undefined | null = createSelector(
  [
    (state: State, arg: GetTestOccurrenceTreeApiArg) =>
      restApi.endpoints.getTestOccurrenceTree.select(arg)(state).data?.tree.node,
    (state: State, arg: GetTestOccurrenceTreeApiArg) =>
      getTestOccurrencesSubtrees(state, arg.treeLocator),
    (state: State, arg: GetTestOccurrenceTreeApiArg) =>
      getSortedByDepthSubtreesKeys(state, arg.treeLocator),
  ],
  (treeNodes, subtrees, keys) =>
    mergeTreeAndSubTrees(treeNodes, keys, key => subtrees?.[key]?.data.node),
)

export const getTestOccurrencesLeavesHash: (
  state: State,
  arg: GetTestOccurrenceTreeApiArg,
) => KeyValue<TestsTreeNodeId, TestOccurrencesTreeLeaf> = createSelector(
  [
    (state: State, arg: GetTestOccurrenceTreeApiArg) =>
      restApi.endpoints.getTestOccurrenceTree.select(arg)(state).data?.tree.leaf,
    (state: State, arg: GetTestOccurrenceTreeApiArg) =>
      getTestOccurrencesSubtrees(state, arg.treeLocator),
    (state: State, arg: GetTestOccurrenceTreeApiArg) =>
      getSortedByDepthSubtreesKeys(state, arg.treeLocator),
  ],
  (treeLeaves, subtrees, keys) =>
    getLeavesHash(treeLeaves, keys, key => subtrees?.[key]?.data?.leaf),
)

export const getTestOccurrencesNodesHash: (
  state: State,
  arg: GetTestOccurrenceTreeApiArg,
) => KeyValue<TestsTreeNodeId, TestOccurrencesTreeNode> = createSelector(
  [getTestOccurrencesTreeNodes],
  (
    nodes: ReadonlyArray<TestOccurrencesTreeNode> | undefined | null,
  ): KeyValue<TestsTreeNodeId, TestOccurrencesTreeNode> =>
    nodes != null ? nodes.reduce((acc, node) => ({...acc, [node.id]: node}), {}) : getEmptyHash(),
)

const getBaseTestOccurrencesHash: (
  state: State,
) => KeyValue<TestOccurrenceId, TestOccurrence> = state => state.entities.testOccurrences

const getMultirunTestOccurrencesHash: (
  state: State,
) => KeyValue<TestOccurrenceId, TestOccurrence> = state => state.entities.multirunTestOccurrences

const getTestOccurrencesHash: (state: State) => KeyValue<TestOccurrenceId, TestOccurrence> =
  createSelector(
    getBaseTestOccurrencesHash,
    getMultirunTestOccurrencesHash,
    (
      testOccurrences: KeyValue<TestOccurrenceId, TestOccurrence>,
      multirunTestOccurrences: KeyValue<TestOccurrenceId, TestOccurrence>,
    ) => ({...testOccurrences, ...multirunTestOccurrences}),
  )

export const getBaseTestOccurrence: (
  state: State,
  id?: TestOccurrenceId,
) => TestOccurrence | null | undefined = (state, id) => getBaseTestOccurrencesHash(state)[id!]

const getGroupedTestOccurrence: (
  state: State,
  id?: TestOccurrenceId,
) => TestOccurrence | null | undefined = (state, id) => getMultirunTestOccurrencesHash(state)[id!]

export const getTestOccurrence: (
  state: State,
  id?: TestOccurrenceId,
) => TestOccurrence | null | undefined = (state, id) =>
  getGroupedTestOccurrence(state, id) ?? getBaseTestOccurrence(state, id)

export const getTestOccurrenceFirstFailed: (
  state: State,
  id?: TestOccurrenceId,
) => TestOccurrence | null | undefined = (state, id) =>
  state.entities.testOccurrencesFirstFailed[id!]

export const getTestOccurrenceRunOrder: (
  state: State,
  id?: TestOccurrenceId,
) => string | null | undefined = (state, id) => state.entities.testOccurrencesRunOrder[id!]

export const getTestOccurrenceNewFailure: (
  state: State,
  id?: TestOccurrenceId,
) => boolean | null | undefined = (state, id) => state.entities.testOccurrencesNewFailure[id!]

export const getTestOccurrenceMetadataCount: (
  state: State,
  id?: TestOccurrenceId,
) => number | null | undefined = (state, id) => state.entities.testOccurrencesMetadataCount[id!]

export const getTestOccurrenceNextFixed: (
  state: State,
  id?: TestOccurrenceId,
) => TestOccurrence | null | undefined = (state, id) => state.entities.testOccurrencesNextFixed[id!]

export const getTestOccurrenceInvestigations: (
  state: State,
  id?: TestOccurrenceId,
) => ReadonlyArray<Investigation> = (state, id) =>
  state.entities.testOccurrencesInvestigations[id!] ?? emptyArray

export const getTestOccurrenceCurrentlyMutes: (
  state: State,
  id?: TestOccurrenceId,
) => ReadonlyArray<Mute> = (state, id) =>
  state.entities.testOccurrencesCurrentlyMutes[id!] ?? emptyArray

export const getTestOccurrenceMute: (
  state: State,
  id?: TestOccurrenceId,
) => Mute | null | undefined = (state, id) => state.entities.testOccurrencesMute[id!]

export const getTestOccurrenceInvocationsCounters: (
  state: State,
  id?: TestOccurrenceId,
) => TestOccurrencesCounts | null | undefined = (state, id) =>
  state.entities.testOccurrencesInvocationsCounters[id!]

export const getFlakyTestDataByBuildHash: (
  state: State,
  buildId: BuildId | null | undefined,
) => KeyValue<TestId, TestFlaky> = (state, buildId) =>
  (buildId != null ? state.flakyTests[buildId] : null) ?? getEmptyHash()

export const getFlakyTestData: (
  state: State,
  buildId: BuildId | null | undefined,
  testId: TestId | null | undefined,
) => TestFlaky | null | undefined = (state, buildId, testId) => {
  const testData = getFlakyTestDataByBuildHash(state, buildId)
  return testId != null && testData[testId] != null ? testData[testId] : null
}

export const hierarchyToBuildTypesList = (item: BuildTypeHierarchy | undefined): BuildTypeId[] =>
  item?.type === 'BUILD_TYPE'
    ? [item.id].filter(notNull)
    : item?.children.flatMap(hierarchyToBuildTypesList) ?? []

export const makeGetTestOccurrencesList: () => (
  state: State,
  ids: ReadonlyArray<TestOccurrenceId>,
) => ReadonlyArray<TestOccurrence> = () =>
  createSelector(
    [(_: State, ids: ReadonlyArray<TestOccurrenceId>) => ids, getTestOccurrencesHash],
    (
      ids: ReadonlyArray<TestOccurrenceId>,
      testOccurrences: KeyValue<TestOccurrenceId, TestOccurrence>,
    ) =>
      ids.length > 0
        ? ids.map((id: TestOccurrenceId) => testOccurrences[id]).filter(Boolean)
        : emptyArray,
  )

export const makeGetTestIdsByTestOccurranceIds: () => (
  state: State,
  ids: ReadonlyArray<TestOccurrenceId>,
) => ReadonlyArray<TestId> = () =>
  createSelector(
    [getTestOccurrencesHash, (state: State, ids: ReadonlyArray<TestOccurrenceId>) => ids],
    (
      testOccurrences: KeyValue<TestOccurrenceId, TestOccurrence>,
      ids: ReadonlyArray<TestOccurrenceId>,
    ) =>
      ids.length > 0
        ? ids.map((id: TestOccurrenceId) => testOccurrences[id]?.test?.id).filter(Boolean)
        : emptyArray,
  )

export const makeGetTestOccurrenceInvocations: () => (
  state: State,
  ids: ReadonlyArray<TestOccurrenceId>,
) => ReadonlyArray<TestOccurrence> = () =>
  createSelector(
    [(state: State, ids: ReadonlyArray<TestOccurrenceId>) => ids, getBaseTestOccurrencesHash],
    (
      ids: ReadonlyArray<TestOccurrenceId>,
      testOccurrences: KeyValue<TestOccurrenceId, TestOccurrence>,
    ) => (ids.length > 0 ? ids.map(id => testOccurrences[id]).filter(Boolean) : null) ?? emptyArray,
  )
