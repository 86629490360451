import {HTTPCodesEnum} from '../rest/errors/HTTPError'
import type {ActiveEntityProps} from '../types'
import {stringifyId} from '../types'

const FORBIDDEN_MESSAGE = 'You do not have enough permissions to access'
const NOT_FOUND_MESSAGE = 'does not exist'
export const getAvailabilityErrorMessage = ({
  error,
  buildId,
  buildTypeId,
  projectId,
  agentId,
  agentTypeId,
  agentPoolId,
}: ActiveEntityProps &
  Readonly<{
    error?:
      | {
          statusCode?: number
          message?: string
        }
      | string
      | null
  }>): string | null => {
  if (error != null) {
    if (typeof error === 'string') {
      return error
    }
    if (error.statusCode === HTTPCodesEnum.FORBIDDEN) {
      if (buildId != null) {
        return `${FORBIDDEN_MESSAGE} build with id ${buildId}`
      }

      if (buildTypeId != null) {
        return `${FORBIDDEN_MESSAGE} build configuration with id ${stringifyId(buildTypeId)}`
      }

      if (projectId != null) {
        return `${FORBIDDEN_MESSAGE} project with id ${stringifyId(projectId)}`
      }

      if (agentId != null) {
        return `${FORBIDDEN_MESSAGE} agent with id ${stringifyId(agentId)}`
      }

      if (agentTypeId != null) {
        return `${FORBIDDEN_MESSAGE} agent with type id ${stringifyId(agentTypeId)}`
      }

      if (agentPoolId != null) {
        return `${FORBIDDEN_MESSAGE} agent pool with id ${stringifyId(agentPoolId)}`
      }
    }

    if (error.statusCode === HTTPCodesEnum.NOT_FOUND) {
      if (buildId != null) {
        return `Build with id "${stringifyId(buildId)}" ${NOT_FOUND_MESSAGE}`
      }

      if (buildTypeId != null) {
        return `Build configuration with id "${stringifyId(buildTypeId)}" ${NOT_FOUND_MESSAGE}`
      }

      if (projectId != null) {
        return `Project with id "${stringifyId(projectId)}" ${NOT_FOUND_MESSAGE}`
      }

      if (agentId != null) {
        return `Agent with id "${stringifyId(agentId)}" ${NOT_FOUND_MESSAGE}`
      }

      if (agentTypeId != null) {
        return `Agent with type id "${stringifyId(agentTypeId)}" ${NOT_FOUND_MESSAGE}`
      }

      if (agentPoolId != null) {
        return `Agent pool with id "${stringifyId(agentPoolId)}" ${NOT_FOUND_MESSAGE}`
      }
    }

    return error.message ?? null
  }

  return null
}
