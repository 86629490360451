import type {BuildId} from '../../../types'

export enum BuildApprovalViewMode {
  INLINE = 'inline',
  CLASSIC_UI = 'classic_ui',
  POPUP = 'popup',
}

export type OwnProps = {
  buildId: BuildId
  className?: string
  view?: BuildApprovalViewMode
}

export type StateProps = {
  shouldRender: boolean
}

export type Props = OwnProps & StateProps
