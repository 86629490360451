/**
 * @generated SignedSource<<4d6cfcc0f87079c5c996c51a8972bc64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BuildPageRunIdQuery$variables = {
  buildLocator: string;
  skip: boolean;
};
export type BuildPageRunIdQuery$data = {
  readonly build?: {
    readonly rawId: number | null | undefined;
  } | null | undefined;
};
export type BuildPageRunIdQuery = {
  response: BuildPageRunIdQuery$data;
  variables: BuildPageRunIdQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "buildLocator"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "buildLocator",
    "variableName": "buildLocator"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rawId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BuildPageRunIdQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Build",
            "kind": "LinkedField",
            "name": "build",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BuildPageRunIdQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Build",
            "kind": "LinkedField",
            "name": "build",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "1aa427250e9295d18844cb53874ec17e",
    "id": null,
    "metadata": {},
    "name": "BuildPageRunIdQuery",
    "operationKind": "query",
    "text": "query BuildPageRunIdQuery(\n  $buildLocator: String!\n  $skip: Boolean!\n) {\n  build(buildLocator: $buildLocator) @skip(if: $skip) {\n    rawId\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2e51f8218138a5892cb8531ef9a99267";

export default node;
