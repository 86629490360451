import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

import {getEmptyHash} from '../../../utils/empty'

export const defaultAgentsPagesState = {
  agentsTreeExpandState: getEmptyHash(),
  agentsTreeSearchExpandState: getEmptyHash(),
  favoriteAgentsTreeExpandState: getEmptyHash(),
  favoriteAgentsTreeSearchExpandState: getEmptyHash(),
  agentsTreeSearchState: {
    searchQuery: '',
  },
}

export const agentsTreeSearchState = createSlice({
  name: 'agentsPage.agentsTreeSearchState',
  initialState: defaultAgentsPagesState.agentsTreeSearchState,
  reducers: {
    search(state, action: PayloadAction<string>) {
      state.searchQuery = action.payload
    },
  },
})
