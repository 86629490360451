import debounce from 'just-debounce-it'

import create from 'callbag-create'
import pipe from 'callbag-pipe'
import remember from 'callbag-remember'
import share from 'callbag-share'
import subscribe from 'callbag-subscribe'

const END = 2
const elementsVisibility = new Map<string, boolean>()
export const isElementVisible = (id: string): boolean => elementsVisibility.get(id) ?? false
const DELAY = 300 // ms

const handlers = new WeakMap()
const streams = new WeakMap()

const handle = (el: HTMLElement, isVisible: boolean) => {
  const handler = handlers.get(el)

  if (handler) {
    handler(isVisible)
  }
}

const changesQueue = new Map()
const flush = debounce(() => {
  changesQueue.forEach((isVisible, el) => handle(el, isVisible))
  changesQueue.clear()
}, DELAY)

const enqueueUpdate = (el: Element, isVisible: boolean) => {
  if (changesQueue.get(el) === isVisible) {
    changesQueue.delete(el)
  } else {
    changesQueue.set(el, isVisible)
  }

  flush()
}

let observer: IntersectionObserver | null | undefined

const getObserver = (): IntersectionObserver => {
  if (observer == null) {
    observer = new IntersectionObserver(
      entries => entries.forEach(entry => enqueueUpdate(entry.target, entry.isIntersecting)),
      {},
    ) // observer.USE_MUTATION_OBSERVER = false
  }

  return observer
}

export const stopObserving = (el: HTMLElement) => {
  handlers.delete(el)
  getObserver().unobserve(el)
  const stream = streams.get(el)

  if (stream) {
    stream(END)
    streams.delete(el)
  }
}
export const observeVisibility = (
  el: HTMLElement,
  handler: (arg0: boolean) => unknown,
  id?: string | null | undefined,
): ((value?: boolean) => void) => {
  const enhancedHandler = (isVisible: boolean) => {
    if (id != null) {
      elementsVisibility.set(id, isVisible)
    }

    handler(isVisible)
  }

  if (process.env.NODE_ENV === 'test') {
    enhancedHandler(true)
    return () => enhancedHandler(false)
  }

  handlers.set(el, enhancedHandler)
  getObserver().observe(el)
  return (update = true) => {
    if (update) {
      enhancedHandler(false)
    }
    stopObserving(el)
  }
}

const getStream = (el: HTMLElement) => {
  let stream = streams.get(el)

  if (!stream) {
    stream = pipe(
      create(next => observeVisibility(el, isVisible => next(isVisible))),
      remember,
      share,
    )
    streams.set(el, stream)
  }

  return stream
}

export const observeVisibilityMulticast = (
  el: HTMLElement,
  handler: (arg0: boolean) => unknown,
): (() => void) => pipe(getStream(el), subscribe(handler))
