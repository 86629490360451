import {castDraft} from 'immer'

import {createReducer} from '@reduxjs/toolkit'
import * as Redux from 'redux'

import {restApi} from '../../../services/rest'

import type {TestOccurrencesTree} from './Tests.types'

export type TestOccurrencesSubtree = {
  depth?: number
  data: TestOccurrencesTree
}

const testsReducer = Redux.combineReducers({
  testOccurrencesSubtree: createReducer<Record<string, Record<string, TestOccurrencesSubtree>>>(
    {},
    builder => {
      builder.addMatcher(
        restApi.endpoints.getTestOccurrenceTree.matchFulfilled,
        (state, action) => {
          const {treeLocator, subTreeKey, depth} = action.meta.arg.originalArgs
          if (treeLocator != null && subTreeKey != null) {
            state[treeLocator] ??= {}
            state[treeLocator]![subTreeKey] = {depth, data: castDraft(action.payload.tree)}
          }
        },
      )
    },
  ),
})
export default testsReducer
