/**
 * @generated SignedSource<<9a92de67b6a0e13c6694d8e0560b7a94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type currentUserKeyFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AllUserSelectFragment" | "HeaderUserFragment" | "useMyIdFragment" | "useMyUsernameFragment" | "useUserPropertyFragment">;
  readonly " $fragmentType": "currentUserKeyFragment";
};
export type currentUserKeyFragment$key = {
  readonly " $data"?: currentUserKeyFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"currentUserKeyFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "currentUserKeyFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMyIdFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMyUsernameFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useUserPropertyFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderUserFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AllUserSelectFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "9fb704cd8f7f80208bb80d4d817d7110";

export default node;
