import type {SelectItem} from '@jetbrains/ring-ui/components/select/select'
import {paramCase, sentenceCase} from 'change-case'
import type {ComponentType} from 'react'

import {isUnityRunnedEnabled} from '../../../../../../utils/featureToggles'

import gradle from './descriptors/Gradle.xml'
import maven from './descriptors/maven-step-dsl.xml'
import node from './descriptors/NodeJSStep.xml'
import script from './descriptors/ScriptBuildStep.xml'
import unity from './descriptors/UnityStep.xml'

export type Option = SelectItem<{
  key: string
  childParams?: HotProperty[]
  isDefault: boolean
}>

export type HotProperty = {
  placeholder?: string | null
  name: string
  label?: string
  smartDetectors?: string[]
  smartDetectorsSuccess?: string[]
  multiline?: boolean
  monospace?: boolean
  inline?: boolean
  description?: string | null
  boolean?: boolean
  trueValue?: string
  type?: string
  falseValue?: string
  toggleable?: boolean
  autoFocus?: boolean
  advanced?: boolean
  enum?: boolean
  custom?: ComponentType<any>
  options?: Option[]
  withAdvanced?: boolean
}

const parseParams = (params: NodeListOf<Element>, xml: Document): HotProperty[] =>
  [...params]
    .filter(
      param =>
        param.getAttribute('name') !== 'teamcity.build.workingDir' &&
        param.querySelector(':scope > deprecated') == null &&
        param.getAttribute('ui-hidden') !== 'true',
    )
    .map(param => {
      const uiName = param.getAttribute('ui-name')
      const name =
        param.getAttribute('yaml-name') ??
        param.getAttribute('dslName') ??
        param.getAttribute('name')!
      const type = param.getAttribute('type')
      const boolean = type === 'boolean'
      const isEnum = type != null && !boolean
      return {
        name: paramCase(name),
        label: uiName ?? sentenceCase(name),
        boolean,
        description:
          param.getAttribute('ui-description') ??
          param.getAttribute('description') ??
          param.querySelector(':scope > description')?.textContent,
        placeholder: param.getAttribute('ui-placeholder'),
        advanced: param.getAttribute('ui-advanced') === 'true',
        multiline: param.getAttribute('ui-multiline') === 'true',
        monospace: param.getAttribute('ui-monospace') === 'true',
        autoFocus: param.getAttribute('ui-autofocus') === 'true',
        smartDetectors: param.getAttribute('ui-smart-detectors')?.split(','),
        smartDetectorsSuccess: param
          .getAttribute('ui-smart-detectors-success')
          ?.split(',')
          .filter(Boolean),
        enum: isEnum,
        options:
          type === 'compound'
            ? [...param.querySelectorAll(':scope > option')]
                .filter(
                  option =>
                    option.querySelector(':scope > deprecated') == null &&
                    option.getAttribute('ui-hidden') !== 'true',
                )
                .map(option => {
                  const description = option
                    .querySelector(':scope > description')
                    ?.textContent?.trim()
                    .split(/\n(.*)/s)

                  return {
                    key: option.getAttribute('name')!,
                    label: description?.[0],
                    details: description?.[1],
                    childParams: parseParams(option.querySelectorAll(':scope > param'), xml),
                    isDefault: option.getAttribute('ui-default') === 'true',
                  }
                })
            : isEnum
              ? [...xml.querySelectorAll(`types > enum[name=${type}] > option`)].map(option => {
                  const value = option.getAttribute('value')!
                  return {
                    key: value,
                    label: sentenceCase(value),
                    details: option.querySelector(':scope > description')?.textContent,
                    isDefault: option.getAttribute('ui-default') === 'true',
                  }
                })
              : undefined,
      }
    })

function parseDescriptor(xmlString: string): Record<string, HotProperty[]> {
  const xml = new window.DOMParser().parseFromString(xmlString, 'text/xml')
  const type = xml.querySelector('function')!.getAttribute('name')!
  return {
    [paramCase(type)]: parseParams(xml.querySelectorAll('params > param'), xml),
  }
}

export const generatedFields = {
  ...parseDescriptor(script),
  ...parseDescriptor(maven),
  ...parseDescriptor(gradle),
  ...parseDescriptor(node),
  ...(isUnityRunnedEnabled ? parseDescriptor(unity) : {}),
}
