import type {AsyncThunk, AsyncThunkPayloadCreator} from '@reduxjs/toolkit'
import {SHOULD_AUTOBATCH, createAsyncThunk} from '@reduxjs/toolkit'

import type {AppDispatch} from '../actions/types'

import type {State} from './types'

type PendingMeta = {
  [SHOULD_AUTOBATCH]?: boolean
}

type AsyncThunkConfig = {
  pendingMeta: PendingMeta
}

interface FulfilledMeta {
  fulfilledMeta: {
    [SHOULD_AUTOBATCH]: true
  }
}

type AppAsyncThunkConfig = {
  state: State
  dispatch: AppDispatch
}
// for usage with dispatch and getState
export const createAppAsyncThunk = <D, P = void>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<D, P, AppAsyncThunkConfig>,
): AsyncThunk<D, P, AsyncThunkConfig> =>
  createAsyncThunk<unknown, P, AppAsyncThunkConfig & FulfilledMeta>(
    typePrefix,
    function innerPayloadCreator(args, innerOptions) {
      return Promise.resolve(payloadCreator(args, innerOptions)).then(result =>
        innerOptions.fulfillWithValue(result, {
          [SHOULD_AUTOBATCH]: true,
        }),
      )
    },
    {
      getPendingMeta: () => ({
        [SHOULD_AUTOBATCH]: true,
      }),
    },
  ) as AsyncThunk<D, P, AsyncThunkConfig>
