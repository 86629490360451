import {graphql} from 'react-relay'

import {getOwnTestsLocator} from './TestsPreviewPanel.utils'

export const testsPreviewPanelCountsQuery = graphql`
  query TestsPreviewPanelCountsQuery($locator: String!) {
    testOccurrences(locator: $locator) {
      testCounters {
        ...TestsPreviewPanelCountersFragment
      }
    }
  }
`

export const getCountsVariables = (buildLocator: string) => ({
  locator: `${getOwnTestsLocator(buildLocator)},count:-1`,
})
