import type * as React from 'react'
import {connect} from 'react-redux'

import type {State} from '../../../../reducers/types'
import {getBuild} from '../../../../selectors'
import type {BuildId} from '../../../../types'
import {getBuildOvertimeInfo} from '../../../../utils/buildOvertime'

import BuildDurationAnchor from './BuildDurationAnchor'

type Props = {
  buildId: BuildId
  children?: React.ReactNode
}

const mapStateToProps = (state: State, props: Props) => {
  const build = getBuild(state, props.buildId)
  const {percentageComplete, elapsedSeconds = 0, leftSeconds} = build?.['running-info'] || {}
  const {seconds: overtime, isOvertime} = getBuildOvertimeInfo(build)

  return {
    progress: percentageComplete,
    status: build?.status,
    elapsed: elapsedSeconds,
    left: leftSeconds,
    overtime,
    isOvertime,
    started: build?.startDate,
    finished: build?.finishDate,
  }
}

export default connect(mapStateToProps)(BuildDurationAnchor)
