import type {User} from '../services/rest'

import useMyId from './useMyId'

export function useUserDisplayName(user?: User, capitalizeYou?: boolean) {
  const myId = useMyId()

  if (!user) {
    return null
  }

  if (user.id != null && user.id === myId) {
    return capitalizeYou === true ? 'You' : 'you'
  }

  return user.name ?? user.username
}
