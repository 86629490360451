import type {CaseReducer, PayloadAction} from '@reduxjs/toolkit'

import type {VcsRootDto} from '../../services/pipelinesApi.types'
import type {JobRepositorySettings} from '../../types'

import type {PipelineDraftState} from './EditPipelinePage.slices.types'
import {deleteIfEmpty, getJob} from './EditPipelinePage.slices.utils'

const mainRepositryName = 'main'

export const setUrl: CaseReducer<PipelineDraftState, PayloadAction<{id: string; url: string}>> = (
  state,
  action,
) => {
  const {id, url} = action.payload
  const {draft} = state[id] ?? {}
  if (draft != null) {
    draft.vcsRoot.url = url
  }
}

export const updateMainVcsRoot: CaseReducer<
  PipelineDraftState,
  PayloadAction<Omit<VcsRootDto, 'vcsName'>>
> = (state, action) => {
  const {id, ...updateVcsRoot} = action.payload
  const {draft} = state[id] ?? {}

  if (draft != null) {
    draft.vcsRoot = {
      ...draft.vcsRoot,
      ...updateVcsRoot,
    }
  }
}

export const setAdditionalVcsRoot: CaseReducer<PipelineDraftState, PayloadAction<VcsRootDto>> = (
  state,
  action,
) => {
  const {id, ...vcsRoot} = action.payload
  const {draft} = state[id] ?? {}
  if (draft != null) {
    draft.additionalVcsRoots ??= []
    draft.additionalVcsRoots?.push(vcsRoot)
  }
}

export const deleteAdditionalVcsRoot: CaseReducer<
  PipelineDraftState,
  PayloadAction<{id: string; url: string}>
> = (state, action) => {
  const mainRepositoryName = 'main'
  const {id, url} = action.payload
  const pipelineState = state[id]
  const pipelineJobs = pipelineState?.draft?.settings.jobs ?? {}

  state[id] ??= {}
  state[id]!.deleted ??= {}
  state[id]!.deleted!.additionalVcsRoots ??= []
  state[id]!.deleted!.additionalVcsRoots!.push(url)

  // Set enabled the main repository if not one remaining
  Object.entries(pipelineJobs).forEach(([_, value]) => {
    const jobRepositories = value.repositories ?? []
    const repositoriesKeysSet = new Set(
      jobRepositories.flatMap(repository => Object.keys(repository)),
    )
    const hasUrlRepositories = repositoriesKeysSet.has(url)
    const isMainRepositoryDisabled = repositoriesKeysSet.has(mainRepositoryName)
    const isMainEnabled =
      hasUrlRepositories && isMainRepositoryDisabled && repositoriesKeysSet.size === 2

    if (isMainEnabled) {
      value.repositories = value.repositories?.filter(
        repository => !(mainRepositoryName in repository),
      )
    }
  })
}

export const restoreAdditionalVcsRoot: CaseReducer<
  PipelineDraftState,
  PayloadAction<{id: string; url: string}>
> = (state, action) => {
  const {id, url} = action.payload
  const {deleted} = state[id] ?? {}

  if (deleted?.additionalVcsRoots?.includes(url)) {
    deleted.additionalVcsRoots = deleted.additionalVcsRoots.filter(deletedUrl => deletedUrl !== url)
    deleteIfEmpty(deleted, 'additionalVcsRoots') && deleteIfEmpty(state[id], 'deleted')
  }
}

export const updateAdditionalVcsRoot: CaseReducer<
  PipelineDraftState,
  PayloadAction<Omit<VcsRootDto, 'vcsName'> & {index: number}>
> = (state, action) => {
  const {id, index, ...updateVcsRoot} = action.payload
  const {draft} = state[id] ?? {}

  if (draft != null) {
    const additionalVcsRoot = draft?.additionalVcsRoots[index]
    const jobs = state[id]!.draft?.settings.jobs
    const prevUrl = additionalVcsRoot?.url
    const updateUrl = updateVcsRoot.url

    if (prevUrl !== updateUrl) {
      Object.keys(jobs ?? []).forEach(key => {
        const job = jobs![key]
        const repositories = job.repositories
        const jobRepository = repositories?.find(jobRepository => prevUrl in jobRepository)

        if (jobRepository && repositories) {
          job.repositories?.push({
            [updateUrl]: jobRepository[prevUrl],
          })

          job.repositories = repositories?.filter(repository => !(prevUrl in repository))
        }
      })
    }
    draft.additionalVcsRoots[index] = {
      ...additionalVcsRoot,
      ...updateVcsRoot,
    }
  }
}

export const updateJobRepository: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    pipelineId: string
    jobId: string
    url: string
    settings: JobRepositorySettings
  }>
> = (state, action) => {
  const {pipelineId, settings, jobId, url} = action.payload
  const job = getJob(state, pipelineId, jobId)

  if (job != null) {
    const isMainRepositry = url === mainRepositryName
    const indexRepositry = job.repositories?.findIndex(repository => url in repository)
    const hasRepositry = indexRepositry !== undefined && indexRepositry !== -1

    job.repositories ??= []

    if (isMainRepositry && hasRepositry) {
      if (settings.enabled && !settings.path) {
        job.repositories = job.repositories?.filter(jobRepository => !(url in jobRepository))
      } else {
        job.repositories![indexRepositry] = {
          [url]: settings,
        }
      }
    } else if (isMainRepositry && !hasRepositry) {
      job.repositories?.push({
        [url]: settings,
      })
    } else if (!isMainRepositry && hasRepositry) {
      job.repositories = job.repositories?.reduce<Record<string, JobRepositorySettings>[]>(
        (accumulator, repository) => {
          if (url in repository) {
            repository[url] = settings

            const {enabled} = repository[url]

            if (enabled) {
              accumulator.push(repository)
            }
          } else {
            accumulator.push(repository)
          }

          return accumulator
        },
        [],
      )
    } else {
      job.repositories.push({[url]: settings})
    }

    if (!job.repositories?.length) {
      deleteIfEmpty(job, 'repositories')
    }
  }
}
export const setDefaultBranch: CaseReducer<
  PipelineDraftState,
  PayloadAction<{id: string; branch: string}>
> = (state, action) => {
  const {id, branch} = action.payload
  const {draft} = state[id] ?? {}
  if (draft != null) {
    draft.vcsRoot.branch = branch
  }
}

export const setBranchSpec: CaseReducer<
  PipelineDraftState,
  PayloadAction<{id: string; branchSpecification: string}>
> = (state, action) => {
  const {id, branchSpecification} = action.payload
  const {draft} = state[id] ?? {}
  if (draft != null) {
    draft.vcsRoot.branchSpecification = branchSpecification
  }
}
