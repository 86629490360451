import {queryToObject} from './queryParams'
import {parseURL} from './url'

enum LogKeys {
  PERFORMANCE = 'performanceLog',
}

enum PerformanceLogKeys {
  ACTIONS = 'actions',
  REDUCERS = 'reducers',
  ALL = 'all',
}

function getLogLevel(key: LogKeys): string | null | undefined {
  const search = parseURL(window.location.href).search
  const urlParams = queryToObject(search)

  return urlParams[key]
}

export const hasPerformanceActionLog = (() => {
  const logLevel = getLogLevel(LogKeys.PERFORMANCE)
  if (logLevel == null) {
    return false
  }
  return logLevel === PerformanceLogKeys.ACTIONS || logLevel === PerformanceLogKeys.ALL
})()

export const hasPerformanceReducerLog = (() => {
  const logLevel = getLogLevel(LogKeys.PERFORMANCE)
  if (logLevel == null) {
    return false
  }
  return logLevel === PerformanceLogKeys.REDUCERS || logLevel === PerformanceLogKeys.ALL
})()
