export const extractErrorFromRelay = (originalError: Error): Error =>
  originalError != null &&
  typeof originalError === 'object' &&
  'source' in originalError &&
  originalError.source != null &&
  typeof originalError.source === 'object' &&
  'errors' in originalError.source &&
  Array.isArray(originalError.source.errors)
    ? originalError.source.errors[0]
    : originalError
