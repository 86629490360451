/**
 * @generated SignedSource<<13f4d84765fecec962003d651196ab1b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DependenciesTabQuery$variables = {
  buildTypeLocator: string;
  chainBuildsLocator: string;
  deliveredArtifactBuildsLocator: string;
  downloadedArtifactBuildsLocator: string;
};
export type DependenciesTabQuery$data = {
  readonly buildTypes: {
    readonly count: number | null | undefined;
  } | null | undefined;
  readonly chainBuilds: {
    readonly count: number | null | undefined;
  } | null | undefined;
  readonly deliveredArtifactBuilds: {
    readonly count: number | null | undefined;
  } | null | undefined;
  readonly downloadedArtifactBuilds: {
    readonly count: number | null | undefined;
  } | null | undefined;
};
export type DependenciesTabQuery = {
  response: DependenciesTabQuery$data;
  variables: DependenciesTabQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "buildTypeLocator"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "chainBuildsLocator"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deliveredArtifactBuildsLocator"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "downloadedArtifactBuildsLocator"
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
],
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "locator",
        "variableName": "buildTypeLocator"
      }
    ],
    "concreteType": "BuildTypes",
    "kind": "LinkedField",
    "name": "buildTypes",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": "chainBuilds",
    "args": [
      {
        "kind": "Variable",
        "name": "locator",
        "variableName": "chainBuildsLocator"
      }
    ],
    "concreteType": "Builds",
    "kind": "LinkedField",
    "name": "builds",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": "downloadedArtifactBuilds",
    "args": [
      {
        "kind": "Variable",
        "name": "locator",
        "variableName": "downloadedArtifactBuildsLocator"
      }
    ],
    "concreteType": "Builds",
    "kind": "LinkedField",
    "name": "builds",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": "deliveredArtifactBuilds",
    "args": [
      {
        "kind": "Variable",
        "name": "locator",
        "variableName": "deliveredArtifactBuildsLocator"
      }
    ],
    "concreteType": "Builds",
    "kind": "LinkedField",
    "name": "builds",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DependenciesTabQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "DependenciesTabQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "855108d6a0d82fcb21947381cb01c2fe",
    "id": null,
    "metadata": {},
    "name": "DependenciesTabQuery",
    "operationKind": "query",
    "text": "query DependenciesTabQuery(\n  $buildTypeLocator: String!\n  $chainBuildsLocator: String!\n  $downloadedArtifactBuildsLocator: String!\n  $deliveredArtifactBuildsLocator: String!\n) {\n  buildTypes(locator: $buildTypeLocator) {\n    count\n  }\n  chainBuilds: builds(locator: $chainBuildsLocator) {\n    count\n  }\n  downloadedArtifactBuilds: builds(locator: $downloadedArtifactBuildsLocator) {\n    count\n  }\n  deliveredArtifactBuilds: builds(locator: $deliveredArtifactBuildsLocator) {\n    count\n  }\n}\n"
  }
};
})();

(node as any).hash = "751680f659ca81d1f44e9c45f947f551";

export default node;
