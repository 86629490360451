import {createReducer, isAnyOf} from '@reduxjs/toolkit'
import * as Redux from 'redux'

import {restApi} from '../../../services/rest'

import type {ErrorAnswer} from './Listings/Rules/Rules.types'

const error = createReducer<ErrorAnswer | null>(null, builder => {
  builder.addMatcher(
    isAnyOf(
      restApi.endpoints.getCleanupPolicies.matchPending,
      restApi.endpoints.getCleanupPolicies.matchFulfilled,
      restApi.endpoints.updateCleanupPolicyDisableState.matchPending,
      restApi.endpoints.updateCleanupPolicyDisableState.matchFulfilled,
      restApi.endpoints.resetCleanupPolicy.matchPending,
      restApi.endpoints.resetCleanupPolicy.matchFulfilled,
      restApi.endpoints.updateCleanupPolicy.matchPending,
      restApi.endpoints.updateCleanupPolicy.matchFulfilled,
    ),
    () => null,
  )

  builder.addMatcher(
    isAnyOf(
      restApi.endpoints.getCleanupPolicies.matchRejected,
      restApi.endpoints.updateCleanupPolicyDisableState.matchRejected,
      restApi.endpoints.resetCleanupPolicy.matchRejected,
      restApi.endpoints.updateCleanupPolicy.matchRejected,
    ),
    (state, action) => {
      if (action.meta.condition) {
        // request was aborted due to condition (another query already running)
        return null
      }
      return 'data' in action.error ? (action.error.data as ErrorAnswer) : null
    },
  )
})

const isAnyActionPerforming = createReducer(false, builder => {
  builder.addMatcher(
    isAnyOf(
      restApi.endpoints.getCleanupPolicies.matchPending,
      restApi.endpoints.updateCleanupPolicyDisableState.matchPending,
      restApi.endpoints.resetCleanupPolicy.matchPending,
      restApi.endpoints.updateCleanupPolicy.matchPending,
    ),
    () => true,
  )
  builder.addMatcher(
    isAnyOf(
      restApi.endpoints.getCleanupPolicies.matchFulfilled,
      restApi.endpoints.getCleanupPolicies.matchRejected,
      restApi.endpoints.updateCleanupPolicyDisableState.matchFulfilled,
      restApi.endpoints.updateCleanupPolicyDisableState.matchRejected,
      restApi.endpoints.resetCleanupPolicy.matchFulfilled,
      restApi.endpoints.resetCleanupPolicy.matchRejected,
      restApi.endpoints.updateCleanupPolicy.matchFulfilled,
      restApi.endpoints.updateCleanupPolicy.matchRejected,
    ),
    () => false,
  )
})

const cleanupPoliciesReducer = Redux.combineReducers({
  isAnyActionPerforming,
  error,
})
/** Add exported reducer to the end of mainReducer in ~/reducers/index.js */

export default cleanupPoliciesReducer
