import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'

import {makeResource} from '../../../utils/makeResource'

import type {NotificationsPageComponent} from './NotificationsPage'

export const NotificationsPageEntryPoint: EntryPoint<
  NotificationsPageComponent,
  LoaderFunctionArgs
> = {
  root: makeResource(
    'NotificationsPage',
    () =>
      import(
        /* webpackChunkName: "NotificationsPage", webpackPrefetch: true */ './NotificationsPage'
      ),
  ),
  getPreloadProps: () => ({}),
}
