import RemoveIcon from '@jetbrains/icons/cancel.svg'
import StopIcon from '@jetbrains/icons/stop.svg'
import Button from '@jetbrains/ring-ui/components/button/button'
import classNames from 'classnames'
import * as React from 'react'
import {useRef} from 'react'

import {useUserDisplayName} from '../../../hooks/useUserDisplayName'
import type {BuildId} from '../../../types'
import {KillOperationKind} from '../../../types'
import {BS} from '../../../types/BS_types'
import IconButton from '../IconButton/IconButton'

import {useIsCancellable} from './StopBuild.hooks'
import {StopButtonType} from './StopBuild.types'
import type {Props} from './StopBuild.types'

import styles from './StopBuild.css'

const noop = () => Promise.resolve(false)

/**
 * BS.StopBuildDialog.showStopBuildDialog([21740961], '', 2, null)
 * Shows the dialog to one of the following 3 actions: remove a build from the queue, stop a running build, remove a finished build.
 *
 * @param promoIds        array of promotion identifiers. Mostly one identifier only.
 * @param defaultMessage
 * @param operationKind      See StopBuildAction.KillOperationKind:
 *                           1 - remove a queued build,
 *                           2 - stop a running build,
 *                           3 - remove a finished build.
 * @param reStopMessage
 */
type ShowDialogFn = (
  arg0: [BuildId],
  arg1: string,
  arg2: KillOperationKind,
  arg3: HTMLSpanElement | null | undefined,
) => Promise<boolean>
export default function StopBuild({
  state = 'running',
  actualStopMessage = '',
  buttonType = StopButtonType.ICON_BUTTON,
  onStop = noop,
  updateResults = noop,
  className,
  isStopping,
  isInterrupted,
  stoppedWithComment,
  stoppedBy,
  buttonText,
  primary,
  agentName,
  buildId,
  withBorder,
}: Props) {
  const isCancellable = useIsCancellable(buildId)
  const stoppedByDisplayName = useUserDisplayName(stoppedBy)
  const commentRef = useRef<HTMLSpanElement>(null)

  const getTitle: () => string = (): string => {
    const agentMention = agentName != null ? ` on ${agentName}` : ''

    if (state === 'finished') {
      return ''
    }

    if (!isCancellable) {
      return isInterrupted ? 'Build is stopping' : ''
    }

    if (state === 'queued') {
      return 'Cancel build...'
    }

    if (isStopping) {
      return 'Build is stopping'
    }

    if (isInterrupted) {
      return `Build${agentMention} is stopping. Click to force stop`
    }

    return `Stop build${agentMention}...`
  }

  const getButtonText: () => string = () => (state === 'running' ? 'Stop...' : 'Cancel...')
  const getIcon = () => (state === 'running' ? StopIcon : RemoveIcon)

  const getOperationKind: () => KillOperationKind = () =>
    state === 'running' ? KillOperationKind.STOP : KillOperationKind.CANCEL

  const getShowHandler: () => ShowDialogFn = () => {
    if (BS?.StopBuildDialog) {
      return BS.StopBuildDialog.showStopBuildDialog.bind(BS.StopBuildDialog)
    } else {
      return noop
    }
  }

  const handleShowStopDialog: () => Promise<void> = async () => {
    const showDialog: ShowDialogFn = getShowHandler()
    const actionTriggered = await showDialog(
      [buildId],
      actualStopMessage,
      getOperationKind(),
      commentRef.current,
    )

    if (actionTriggered) {
      onStop(buildId)
      updateResults()
    }
  }

  const classes = classNames(styles.stopBuild, className, {
    [styles.icon]: buttonType === StopButtonType.ICON_BUTTON,
    [styles.invisible]: state === 'finished' || (!isCancellable && !isInterrupted),
  })
  const iconClasses = classNames({
    [styles.stopIcon]: state === 'running',
  })
  return (
    <>
      {stoppedWithComment != null && (
        <span hidden ref={commentRef}>
          {'This build has already been stopped'}
          {stoppedByDisplayName != null && (
            <>
              {' by '}
              <em>{stoppedByDisplayName}</em>
            </>
          )}
          {' with message '}
          <em>{stoppedWithComment}</em>
        </span>
      )}
      {buttonType === StopButtonType.ICON_BUTTON ? (
        <IconButton
          disabled={!isCancellable}
          className={classes}
          iconWrapperClassName={iconClasses}
          title={getTitle()}
          onClick={handleShowStopDialog}
          loader={isStopping || (!isCancellable && isInterrupted)}
          icon={getIcon()}
          danger={isInterrupted && isCancellable}
          primary={primary}
          withBorder={withBorder}
        >
          {buttonText}
        </IconButton>
      ) : (
        <Button
          disabled={!isCancellable}
          className={classes}
          title={getTitle()}
          onClick={handleShowStopDialog}
          loader={isStopping || (!isCancellable && isInterrupted)}
          danger={isInterrupted && isCancellable}
        >
          {getButtonText()}
        </Button>
      )}
    </>
  )
}
