import {createReducer} from '@reduxjs/toolkit'
import {combineReducers} from 'redux'

import type {BuildLogSearchState} from '../../../BuildLog.types'
import {SearchDirection, FullLogTarget} from '../../../BuildLog.types'
import {updateFullLogState} from '../../FullBuildLog.actions'

import {
  changeBuildlogSearchMatchPosition,
  clearBuildLogSearch,
  searchMessageInBuildLogAction,
} from './BuildLogSearch.actions'

const defaultSearchState: BuildLogSearchState = Object.freeze({
  query: null,
  nextStartId: null,
  foundId: null,
  matchPosition: null,
  matchesCount: 0,
  startId: null,
  limitReached: false,
  notFound: false,
  loading: false,
  searchDirection: SearchDirection.NEXT,
})

const buildLogSearchReducer = (fullLogTarget: FullLogTarget) =>
  createReducer(defaultSearchState, builder => {
    builder.addCase(updateFullLogState, (state, action) => {
      const {target, buildId, focusLine} = action.payload
      if (target !== fullLogTarget) {
        return undefined
      }
      // eslint-disable-next-line eqeqeq
      if (buildId === null) {
        return defaultSearchState
      }

      if (focusLine != null && focusLine !== state.foundId) {
        state.matchPosition = null
        state.nextStartId = focusLine + (state.searchDirection === SearchDirection.NEXT ? 1 : -1)
      }

      return undefined
    })
    builder.addCase(searchMessageInBuildLogAction.pending, (state, action) => {
      const {query, searchDirection, target} = action.meta.arg
      if (target !== fullLogTarget) {
        return state
      }
      if (query !== state.query || searchDirection !== state.searchDirection) {
        return {
          ...defaultSearchState,
          query,
          searchDirection,
          loading: true,
        }
      }

      state.loading = true
      return undefined
    })
    builder.addCase(searchMessageInBuildLogAction.fulfilled, (state, action) => {
      const {query, searchDirection, target, startId} = action.meta.arg
      if (target !== fullLogTarget) {
        return state
      }
      const {nextStartId, foundId, matchPosition, matchesCount, limitReached, notFound} =
        action.payload
      if (query === state.query && searchDirection === state.searchDirection) {
        return {
          query: state.query,
          nextStartId,
          foundId,
          matchPosition,
          matchesCount,
          startId,
          notFound,
          limitReached,
          loading: false,
          searchDirection,
        }
      }

      return state
    })
    builder.addCase(searchMessageInBuildLogAction.rejected, (state, action) => {
      const {query, searchDirection, target} = action.meta.arg
      if (
        target === fullLogTarget &&
        query === state.query &&
        searchDirection === state.searchDirection
      ) {
        state.loading = false
      }
    })
    builder.addCase(changeBuildlogSearchMatchPosition, (state, action) => {
      const {target, query, matchPosition} = action.payload
      if (target === fullLogTarget && query === state.query) {
        state.matchPosition = matchPosition
      }
    })
    builder.addCase(clearBuildLogSearch, (state, action) =>
      action.payload === fullLogTarget ? defaultSearchState : state,
    )
  })

export const searchStates = combineReducers({
  page: buildLogSearchReducer(FullLogTarget.PAGE),
  popup: buildLogSearchReducer(FullLogTarget.POPUP),
})
