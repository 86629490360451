/**
 * @generated SignedSource<<31e01c9e284e45a0cddd7dc5fc227f79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MatrixParamBuildQuery$variables = {
  btLocator: string;
  featureId: string;
  skip: boolean;
};
export type MatrixParamBuildQuery$data = {
  readonly buildTypeMatrix?: {
    readonly " $fragmentSpreads": FragmentRefs<"MatrixParamBuildContext_matrix">;
  } | null | undefined;
};
export type MatrixParamBuildQuery = {
  response: MatrixParamBuildQuery$data;
  variables: MatrixParamBuildQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "btLocator"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "featureId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "btLocator",
    "variableName": "btLocator"
  },
  {
    "kind": "Variable",
    "name": "featureId",
    "variableName": "featureId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MatrixParamBuildQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "MatrixParameters",
            "kind": "LinkedField",
            "name": "buildTypeMatrix",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MatrixParamBuildContext_matrix"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MatrixParamBuildQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "MatrixParameters",
            "kind": "LinkedField",
            "name": "buildTypeMatrix",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MatrixParameter",
                "kind": "LinkedField",
                "name": "parameter",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MatrixParameterValue",
                    "kind": "LinkedField",
                    "name": "value",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "label",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "29053bbd3badeb6cd624fcf00b46405f",
    "id": null,
    "metadata": {},
    "name": "MatrixParamBuildQuery",
    "operationKind": "query",
    "text": "query MatrixParamBuildQuery(\n  $btLocator: String!\n  $featureId: String!\n  $skip: Boolean!\n) {\n  buildTypeMatrix(btLocator: $btLocator, featureId: $featureId) @skip(if: $skip) {\n    ...MatrixParamBuildContext_matrix\n  }\n}\n\nfragment MatrixParamBuildContext_matrix on MatrixParameters {\n  parameter {\n    name\n    value {\n      value\n      label\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3888de8f0e8a995deb2113a469d5c9b6";

export default node;
