import {getBuildLocator} from '../../../rest/locators'
import type {Branch} from '../../../services/rest'
import type {BuildTypeId} from '../../../types'
import {toBuildId} from '../../../types'
import {getPermalinkLocator} from '../../../utils/permalinks'

export function getBuildOrPermalinkLocator(
  buildIdParam = '',
  buildTypeId?: BuildTypeId,
  branch?: Branch | null,
) {
  const buildId = buildIdParam && toBuildId(buildIdParam)
  return (
    (buildId && getBuildLocator(buildId)) ||
    (buildTypeId != null && getPermalinkLocator(buildIdParam, buildTypeId, branch)) ||
    ''
  )
}
