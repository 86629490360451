/**
 * @generated SignedSource<<eb38c75dd21f655e36896231514dadae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectInvestigationsTabCountQuery$variables = {
  locator: string;
};
export type ProjectInvestigationsTabCountQuery$data = {
  readonly testOccurrences: {
    readonly " $fragmentSpreads": FragmentRefs<"ProjectInvestigationsTabTestsTreeCountFragment">;
  } | null | undefined;
};
export type ProjectInvestigationsTabCountQuery = {
  response: ProjectInvestigationsTabCountQuery$data;
  variables: ProjectInvestigationsTabCountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locator"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "locator",
    "variableName": "locator"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectInvestigationsTabCountQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TestOccurrences",
        "kind": "LinkedField",
        "name": "testOccurrences",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectInvestigationsTabTestsTreeCountFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectInvestigationsTabCountQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TestOccurrences",
        "kind": "LinkedField",
        "name": "testOccurrences",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f18a8f36eb7e4d8730d01a2750ab3f1a",
    "id": null,
    "metadata": {},
    "name": "ProjectInvestigationsTabCountQuery",
    "operationKind": "query",
    "text": "query ProjectInvestigationsTabCountQuery(\n  $locator: String!\n) {\n  testOccurrences(locator: $locator) {\n    ...ProjectInvestigationsTabTestsTreeCountFragment\n  }\n}\n\nfragment ProjectInvestigationsTabTestsTreeCountFragment on TestOccurrences {\n  count\n}\n"
  }
};
})();

(node as any).hash = "ab1b23b1ea800c00d25be564bbab3d8a";

export default node;
