import Button from '@jetbrains/ring-ui/components/button/button'
import * as React from 'react'

import {internalProps} from '../../../types/BS_types'
import {getSWRegistration, requestSkipWaiting} from '../../../workers/sw.lifecycle'
import ServiceMessage from '../ServiceMessage/ServiceMessage'

import styles from './ServiceWorkerChecker.css'

const forceSWUpdate = internalProps['teamcity.ui.forceServiceWorkerSkipWaiting']

function Content() {
  const [refreshing, setRefreshing] = React.useState(false)
  const refresh = React.useCallback(async () => {
    const registration = await getSWRegistration()

    if (registration?.active && !registration?.waiting && !registration?.installing) {
      window.location.reload()
    } else {
      requestSkipWaiting()
    }

    setRefreshing(true)
  }, [])

  return (
    <div className={styles.content}>
      <p className={styles.text}>{'A new UI version is available'}</p>
      <Button onClick={refresh} primary loader={refreshing}>
        {'Refresh'}
      </Button>
    </div>
  )
}

function ServiceWorkerChecker(): React.ReactElement | null {
  const [outdated, setOutdated] = React.useState(false)

  React.useEffect(() => {
    const update = () => {
      if (forceSWUpdate) {
        requestSkipWaiting()
      } else {
        setOutdated(true)
      }
    }

    const checkRegistration = async () => {
      const registration = await getSWRegistration()

      if (registration == null) {
        return
      }

      if (registration.waiting) {
        update()
      }

      registration.addEventListener('updatefound', () => {
        if (registration.installing) {
          registration.installing.addEventListener('statechange', () => {
            if (registration.waiting && navigator.serviceWorker?.controller) {
              update()
            }
          })
        }
      })
    }

    checkRegistration()
  }, [])

  return outdated ? (
    <ServiceMessage
      captionClassName={styles.wrapper}
      descriptionClassName={styles.description}
      closeable={false}
    >
      <Content />
    </ServiceMessage>
  ) : null
}

export default ServiceWorkerChecker
