import type {PlaceId} from '@jetbrains/teamcity-api'
import {skipToken} from '@reduxjs/toolkit/query'
import * as React from 'react'

import {emptyArray} from '../../../utils/empty'
import {useJobId} from '../../pages/PipelinesPages/EditPipelinePage/EditPipelinePage.hooks'

import {usePluginsLoader} from './Plugin.loader'
import {pluginsApi} from './Plugin.requests'
import {getCachedPlugins} from './Plugin.selectors'
import type {DummyPlugin, PluginLocationContext, Props} from './Plugin.types'
import {getActiveEntitiesFromUrl} from './Plugin.utils'

import {useAppSelector} from 'src/hooks/react-redux'

export const useCachedPlugins = (placeId: PlaceId): ReadonlyArray<string> =>
  useAppSelector(state => getCachedPlugins(state, placeId))

export const useActiveEntities = ({
  projectId,
  buildId,
  buildTypeId,
  agentId,
  agentPoolId,
  agentTypeId,
  testRunId,
}: PluginLocationContext): PluginLocationContext =>
  React.useMemo(
    () =>
      window.ReactUI.isSakuraUI
        ? {
            projectId,
            buildId,
            buildTypeId,
            agentId,
            agentPoolId,
            agentTypeId,
            testRunId,
          }
        : getActiveEntitiesFromUrl(),
    [agentId, agentPoolId, agentTypeId, buildId, buildTypeId, projectId, testRunId],
  )

const useAvailablePlugins = (
  placeId: PlaceId,
  activeEntities: PluginLocationContext,
  disableFetch?: boolean,
): [boolean, ReadonlyArray<DummyPlugin>] => {
  const {isLoading, availablePlugins} = pluginsApi.endpoints.getPlaceIdAvailablePlugins.useQuery(
    disableFetch ? skipToken : {placeId, activeEntities},
    {
      selectFromResult: ({isLoading, data}) => ({
        isLoading,
        availablePlugins: data ?? emptyArray,
      }),
    },
  )
  return [isLoading, availablePlugins]
}

export const usePlugins = ({
  placeId,
  pluginDevelopmentMode,
  disableFetch,
  ...restEntities
}: Props): [PluginLocationContext, ReadonlyArray<string>] => {
  const entities = {...restEntities}
  const jobId = useJobId()
  if (!entities.buildId && jobId != null) {
    /*
    Still no clear understanding of how to throw pipelineId, runId, jobId into the plugin via context.
    Temporary solution is to throw jobId through buildId.
    */
    entities.buildId = jobId
  }
  const activeEntities = useActiveEntities(entities)
  const [availablePluginsLoading, availablePlugins] = useAvailablePlugins(
    placeId,
    activeEntities,
    disableFetch,
  )
  usePluginsLoader({
    availablePlugins,
    availablePluginsLoading,
    activeEntities,
    placeId,
    pluginDevelopmentMode,
  })
  const pluginsToRender = useCachedPlugins(placeId)
  return [activeEntities, pluginsToRender]
}
