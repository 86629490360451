import {produce, original} from 'immer'

import type {UnknownAction, Draft, PayloadAction} from '@reduxjs/toolkit'
import {createReducer} from '@reduxjs/toolkit'
import deepEqual from 'fast-deep-equal'
import type {Reducer} from 'redux'

import {objectKeys} from '../utils/object'
import type {KeyValue, WritableKeyValue} from '../utils/object'

// inside createReducer/createSlice, use Object.assign instead
export const mergeIfDifferent = <T extends {}>(
  dest: T,
  src: Readonly<Partial<T>> | null | undefined,
): T =>
  produce(dest, draft => {
    Object.assign(draft, src)
  })

export function assignIfDeepDifferent<T extends {}>(
  draft: Draft<T>,
  src: Readonly<Partial<T>> | null | undefined,
) {
  if (src != null) {
    const dest = draft as T
    const originalDest = original(draft) as T
    objectKeys(src).forEach(key => {
      if (!deepEqual(originalDest[key], src[key])) {
        dest[key] = src[key] as T[keyof T]
      }
    })
  }
}

const baseKeyValueReducer = <K extends PropertyKey, S, FA extends UnknownAction>(
  getKey: (arg0: FA) => K | null | undefined,
  valueReducer: Reducer<S, FA>,
  filter: (action: UnknownAction) => action is FA,
) =>
  createReducer(
    (): KeyValue<K, S> => Object.freeze({}),
    builder => {
      builder.addMatcher(filter, (draft, action) => {
        const key = getKey(action)

        if (key != null) {
          const state = draft as WritableKeyValue<K, S>
          state[key] = valueReducer(original(state)?.[key], action)
        }
      })
    },
  )

type BaseActionCreator = {
  match: (action: UnknownAction) => boolean
}

export const keyValueReducer = <K extends PropertyKey, S, FA extends PayloadAction<unknown>>(
  getKey: (arg0: FA) => K | null | undefined,
  valueReducer: Reducer<S, FA>,
  typeFilter: readonly BaseActionCreator[],
) =>
  baseKeyValueReducer(getKey, valueReducer, (action): action is FA =>
    typeFilter.some(type => type.match(action)),
  )
