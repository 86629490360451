import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'
import {getRequest} from 'relay-runtime'

import {BuildPageTabNamesEnum} from '../../../../types'
import type {ThinNestedEntryPointParamsObject} from '../../../../types/entryPoints'
import {makeResource} from '../../../../utils/makeResource'
import {queryToObject} from '../../../../utils/queryParams'
import {BuildTabs} from '../../../common/ToggleSakuraUI/ToggleSakuraUI.utils'
import {BuildArtifactsTabEntryPoint} from '../../BuildPage/BuildArtifactsTab/BuildArtifactsTab.entryPoint'
import {BuildTestsTabEntryPoint} from '../../BuildPage/BuildTestsTab/BuildTestsTab.entryPoint'

import type {NestedEntryPoints, PipelineRunPageComponent} from './PipelineRunPage'
import {pipelineQuery} from './PipelineRunPage.queries'

export const PipelineRunPageEntryPoint: EntryPoint<PipelineRunPageComponent, LoaderFunctionArgs> = {
  root: makeResource(
    'PipelineRunPage',
    () =>
      import(/* webpackChunkName: "PipelineRunPage", webpackPrefetch: true */ './PipelineRunPage'),
  ),
  getPreloadProps: ({params, request}) => {
    const url = new URL(request.url)
    const {job, tab} = queryToObject(url.search)
    const entryPoints: ThinNestedEntryPointParamsObject<NestedEntryPoints> = {}
    if (job != null) {
      const buildLocator = `id:${job}`
      switch (BuildTabs[tab ?? '']) {
        case BuildPageTabNamesEnum.TESTS:
          entryPoints.testsTab = {
            entryPoint: BuildTestsTabEntryPoint,
            entryPointParams: buildLocator,
          }
          break
        case BuildPageTabNamesEnum.ARTIFACTS:
          entryPoints.artifactsTab = {
            entryPoint: BuildArtifactsTabEntryPoint,
            entryPointParams: buildLocator,
          }
          break
        default:
      }
    }
    return {
      queries: {
        pipeline: {
          parameters: getRequest(pipelineQuery),
          variables: {locator: `internalId:${params.id}`},
          options: {networkCacheConfig: {metadata: {essential: true}}},
        },
      },
      entryPoints,
    }
  },
}
