import {graphql} from 'react-relay'

import type {ProjectId} from '../../../../types'

import {getCountTestProjectInvestigationsLocator} from './ProjectInvestigations.rest'

export const countQuery = graphql`
  query ProjectInvestigationsTabCountQuery($locator: String!) {
    testOccurrences(locator: $locator) {
      ...ProjectInvestigationsTabTestsTreeCountFragment
    }
  }
`

export const getCountVariables = (projectId: ProjectId) => ({
  locator: getCountTestProjectInvestigationsLocator(projectId),
})
