/**
 * @generated SignedSource<<f9cd1414f4a3772d1a91629e40f06116>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type branchExistsInBuildTypeQuery$variables = {
  branchLocator: string;
  btLocator: string;
};
export type branchExistsInBuildTypeQuery$data = {
  readonly branchExistsInBuildType: boolean | null | undefined;
};
export type branchExistsInBuildTypeQuery = {
  response: branchExistsInBuildTypeQuery$data;
  variables: branchExistsInBuildTypeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "branchLocator"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "btLocator"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "branchLocator",
        "variableName": "branchLocator"
      },
      {
        "kind": "Variable",
        "name": "btLocator",
        "variableName": "btLocator"
      }
    ],
    "kind": "ScalarField",
    "name": "branchExistsInBuildType",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "branchExistsInBuildTypeQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "branchExistsInBuildTypeQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e89e39f8bf2eea926a72666200a87fd1",
    "id": null,
    "metadata": {},
    "name": "branchExistsInBuildTypeQuery",
    "operationKind": "query",
    "text": "query branchExistsInBuildTypeQuery(\n  $branchLocator: String!\n  $btLocator: String!\n) {\n  branchExistsInBuildType(branchLocator: $branchLocator, btLocator: $btLocator)\n}\n"
  }
};
})();

(node as any).hash = "b055ef6e3cf28ea8a5e96cfc3f858ade";

export default node;
