import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {makeResource} from '../../../../utils/makeResource'
import {featureIdQuery} from '../../../common/BuildArtifactsTree/BuildArtifactStorageInfo/BuildArtifactStorageInfo.queries'

import type {BuildArtifactsTabComponent} from './BuildArtifactsTab'

export const BuildArtifactsTabEntryPoint: EntryPoint<BuildArtifactsTabComponent, string> = {
  root: makeResource(
    'BuildArtifactsTab',
    () =>
      import(
        /* webpackChunkName: "BuildArtifactsTab", webpackPrefetch: true */ './BuildArtifactsTab'
      ),
  ),
  getPreloadProps: buildLocator => ({
    queries: {
      storageFeatureIdQuery: {
        parameters: getRequest(featureIdQuery),
        variables: {buildLocator},
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
    },
  }),
}
