import type {PayloadAction} from '@reduxjs/toolkit'
import type {MiddlewareAPI} from 'redux'

export const meassureActionPerformaceMiddleware = () => {
  let id = 0

  return (store: MiddlewareAPI) =>
    (next: (action: PayloadAction<unknown>) => PayloadAction<unknown>) =>
    (action: PayloadAction<unknown>) => {
      const currentId = id
      id++

      const baseMark = `${action.type}-${currentId}`
      const startMark = `${baseMark}-start`
      const endMark = `${baseMark}-end`

      const prevState = store.getState()

      performance.mark(startMark)
      const result = next(action)
      performance.mark(endMark)

      const state = store.getState()
      const isStateChanged = prevState !== state

      type StateKey = keyof typeof prevState

      const changedSegments: string[] = []
      if (isStateChanged) {
        for (const [key, prevSegment] of Object.entries(prevState)) {
          if (prevSegment !== state[key as StateKey]) {
            changedSegments.push(key)
          }
        }
      }

      performance.measure(`${baseMark}-${changedSegments}`, {
        detail: {
          changedSegments,
        },
        start: startMark,
        end: endMark,
      })

      return result
    }
}
