import {useAppSelector} from '../../../hooks/react-redux'
import useMyId from '../../../hooks/useMyId'
import {usePermission} from '../../../hooks/usePermission'
import {getBuild, getProjectIdFromBuildId} from '../../../selectors'
import {Permission} from '../../../types'
import type {BuildId} from '../../../types'

export function useIsCancellable(buildId?: BuildId | null) {
  const projectId = useAppSelector(state => getProjectIdFromBuildId(state, buildId))
  const myId = useMyId()
  const isOthersPersonalBuild = useAppSelector(state => {
    const {personal, user} = getBuild(state, buildId) || {}
    return personal && (user?.id == null || user.id !== myId)
  })
  const canCancel = usePermission(Permission.CANCEL_BUILD, projectId)
  const canCancelAnyPersonalBuild = usePermission(Permission.CANCEL_ANY_PERSONAL_BUILD, projectId)
  return canCancel && (!isOthersPersonalBuild || canCancelAnyPersonalBuild)
}
