import {createSlice, type PayloadAction} from '@reduxjs/toolkit'

import type {TestId} from '../../../../types'

const selectedTestProblemsInitialState: {selectedIds: TestId[]} = {
  selectedIds: [],
}

export const selectedTestProblems = createSlice({
  name: 'selectedTestProblems',
  initialState: selectedTestProblemsInitialState,
  reducers: {
    setSelectedIds(state, action: PayloadAction<TestId[]>) {
      state.selectedIds = action.payload
    },
  },
})
