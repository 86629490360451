/**
 * @generated SignedSource<<c7f8432ece352c4f5a7002c192d21b96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectsSidebarQuery$variables = Record<PropertyKey, never>;
export type ProjectsSidebarQuery$data = {
  readonly federationServers: {
    readonly " $fragmentSpreads": FragmentRefs<"projectTreesFederationServersFragment">;
  } | null | undefined;
};
export type ProjectsSidebarQuery = {
  response: ProjectsSidebarQuery$data;
  variables: ProjectsSidebarQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectsSidebarQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FederationServers",
        "kind": "LinkedField",
        "name": "federationServers",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "projectTreesFederationServersFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProjectsSidebarQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FederationServers",
        "kind": "LinkedField",
        "name": "federationServers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FederationServer",
            "kind": "LinkedField",
            "name": "server",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "375a3a41e449436ef845842c07a2cede",
    "id": null,
    "metadata": {},
    "name": "ProjectsSidebarQuery",
    "operationKind": "query",
    "text": "query ProjectsSidebarQuery {\n  federationServers {\n    ...projectTreesFederationServersFragment\n  }\n}\n\nfragment projectTreesFederationServersFragment on FederationServers {\n  server {\n    url\n  }\n}\n"
  }
};

(node as any).hash = "e1c9fee2ff788683499edad29698a611";

export default node;
