import type {EntryPoint, EntryPointComponent} from 'react-relay'

import {makeResource} from '../../../utils/makeResource'

import type {AgentsPagesQueries} from './AgentsPages.queries'
import {agentsPagesQueries} from './AgentsPages.queries'

export const AgentsPagesEntryPoint: EntryPoint<EntryPointComponent<AgentsPagesQueries, {}>> = {
  root: makeResource(
    'AgentsPages',
    () => import(/* webpackChunkName: "AgentsPages", webpackPrefetch: true */ './AgentsPages'),
  ),
  getPreloadProps: () => ({queries: agentsPagesQueries}),
}
