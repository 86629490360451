import type {BuildType} from '../../../../services/rest'
import type {BuildTypeId, NormalizedBuild} from '../../../../types'
import {isValue} from '../../../../utils/guards'
import type {KeyValue} from '../../../../utils/object'

import {ChangePageTabName, ClassicChangePageTabName} from './ChangeDetailsTabs.types'

const MatchingClassicToSakuraTabNamesEnum = {
  [ClassicChangePageTabName.PROBLEMS_TESTS]: ChangePageTabName.PROBLEMS_TESTS,
  [ClassicChangePageTabName.BUILDS]: ChangePageTabName.BUILDS,
  [ClassicChangePageTabName.DEPLOYMENTS]: ChangePageTabName.DEPLOYMENTS,
  [ClassicChangePageTabName.FILES]: ChangePageTabName.FILES,
}

const MatchingSakuraToClassicTabNamesEnum = {
  [ChangePageTabName.PROBLEMS_TESTS]: ClassicChangePageTabName.PROBLEMS_TESTS,
  [ChangePageTabName.BUILDS]: ClassicChangePageTabName.BUILDS,
  [ChangePageTabName.DEPLOYMENTS]: ClassicChangePageTabName.DEPLOYMENTS,
  [ChangePageTabName.FILES]: ClassicChangePageTabName.FILES,
}

export const matchSakuraToClassicTabName = (tab: string | undefined | null) =>
  isValue(ChangePageTabName, tab)
    ? MatchingSakuraToClassicTabNamesEnum[tab]
    : ClassicChangePageTabName.FILES

export const matchClassicToSakuraTabName = (tab: string | undefined | null) =>
  isValue(ClassicChangePageTabName, tab)
    ? MatchingClassicToSakuraTabNamesEnum[tab]
    : ChangePageTabName.FILES

export const getBuildsHash = (
  changeBuilds: ReadonlyArray<NormalizedBuild>,
): KeyValue<BuildTypeId, NormalizedBuild[]> =>
  changeBuilds.reduce(
    (acc: KeyValue<BuildTypeId, NormalizedBuild[]>, build) => ({
      ...acc,
      [build.buildType]: (acc[build.buildType] ?? []).concat(build),
    }),
    {},
  )

export const sortBuildTypes = (changeBuildTypes: ReadonlyArray<BuildType>) =>
  [...changeBuildTypes].sort((bt1, bt2) => {
    const id1 = bt1.id ?? ''
    const id2 = bt2.id ?? ''
    return id1 > id2 ? 1 : id1 < id2 ? -1 : 0
  })
