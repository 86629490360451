import buildType12pxArrowIcon from '@jetbrains/icons/buildType-12px-arrow.svg'
import buildType12pxFillArrowIcon from '@jetbrains/icons/buildType-12px-fill-arrow.svg'
import buildType12pxFillIcon from '@jetbrains/icons/buildType-12px-fill.svg'
import buildType12pxIcon from '@jetbrains/icons/buildType-12px.svg'
import buildType20pxArrowIcon from '@jetbrains/icons/buildType-20px-arrow.svg'
import buildType20pxFillArrowIcon from '@jetbrains/icons/buildType-20px-fill-arrow.svg'
import buildType20pxFillIcon from '@jetbrains/icons/buildType-20px-fill.svg'
import buildType20pxIcon from '@jetbrains/icons/buildType-20px.svg'
import buildTypeComposite12pxArrowIcon from '@jetbrains/icons/buildType-composite-12px-arrow.svg'
import buildTypeComposite12pxFillArrowIcon from '@jetbrains/icons/buildType-composite-12px-fill-arrow.svg'
import buildTypeComposite12pxFillIcon from '@jetbrains/icons/buildType-composite-12px-fill.svg'
import buildTypeComposite12pxIcon from '@jetbrains/icons/buildType-composite-12px.svg'
import buildTypeComposite20pxArrowIcon from '@jetbrains/icons/buildType-composite-20px-arrow.svg'
import buildTypeComposite20pxFillArrowIcon from '@jetbrains/icons/buildType-composite-20px-fill-arrow.svg'
import buildTypeComposite20pxFillIcon from '@jetbrains/icons/buildType-composite-20px-fill.svg'
import buildTypeComposite20pxIcon from '@jetbrains/icons/buildType-composite-20px.svg'
import pausedOutline12pxIcon from '@jetbrains/icons/paused-outline-12px.svg'
import project12pxArrowIcon from '@jetbrains/icons/project-12px-arrow.svg'
import project12pxFillArrowIcon from '@jetbrains/icons/project-12px-fill-arrow.svg'
import project12pxFillIcon from '@jetbrains/icons/project-12px-fill.svg'
import project12pxIcon from '@jetbrains/icons/project-12px.svg'
import project20pxArrowIcon from '@jetbrains/icons/project-20px-arrow.svg'
import project20pxFillArrowIcon from '@jetbrains/icons/project-20px-fill-arrow.svg'
import project20pxFillIcon from '@jetbrains/icons/project-20px-fill.svg'
import project20pxIcon from '@jetbrains/icons/project-20px.svg'
import template12pxArrowIcon from '@jetbrains/icons/template-12px-arrow.svg'
import template12pxIcon from '@jetbrains/icons/template-12px.svg'
import template20pxArrowIcon from '@jetbrains/icons/template-20px-arrow.svg'
import template20pxIcon from '@jetbrains/icons/template-20px.svg'
import type {IconType as Icon} from '@jetbrains/ring-ui/components/icon/icon'
import classNames from 'classnames'
import * as React from 'react'

import type {KeyValue} from '../../../utils/object'
import SvgIcon from '../SvgIcon/SvgIcon'

import {Sizes, EntityIconType} from './ProjectOrBuildTypeIcon.types'
import type {DefaultProps, Props} from './ProjectOrBuildTypeIcon.types'

import styles from './ProjectOrBuildTypeIcon.css'

const Icons: KeyValue<string, Icon> = {
  'buildType-12px-arrow': buildType12pxArrowIcon,
  'buildType-12px-fill-arrow': buildType12pxFillArrowIcon,
  'buildType-12px-fill': buildType12pxFillIcon,
  'buildType-12px': buildType12pxIcon,
  'buildType-20px-arrow': buildType20pxArrowIcon,
  'buildType-20px-fill-arrow': buildType20pxFillArrowIcon,
  'buildType-20px-fill': buildType20pxFillIcon,
  'buildType-20px': buildType20pxIcon,
  'buildType-composite-12px-arrow': buildTypeComposite12pxArrowIcon,
  'buildType-composite-12px-fill-arrow': buildTypeComposite12pxFillArrowIcon,
  'buildType-composite-12px-fill': buildTypeComposite12pxFillIcon,
  'buildType-composite-12px': buildTypeComposite12pxIcon,
  'buildType-composite-20px-arrow': buildTypeComposite20pxArrowIcon,
  'buildType-composite-20px-fill-arrow': buildTypeComposite20pxFillArrowIcon,
  'buildType-composite-20px-fill': buildTypeComposite20pxFillIcon,
  'buildType-composite-20px': buildTypeComposite20pxIcon,
  'project-12px-arrow': project12pxArrowIcon,
  'project-12px-fill-arrow': project12pxFillArrowIcon,
  'project-12px-fill': project12pxFillIcon,
  'project-12px': project12pxIcon,
  'project-20px-arrow': project20pxArrowIcon,
  'project-20px-fill-arrow': project20pxFillArrowIcon,
  'project-20px-fill': project20pxFillIcon,
  'project-20px': project20pxIcon,
  'template-12px-arrow': template12pxArrowIcon,
  'template-12px': template12pxIcon,
  'template-20px-arrow': template20pxArrowIcon,
  'template-20px': template20pxIcon,
  'paused-outline-12px': pausedOutline12pxIcon,
}

const getIcon = (name: string) => Icons[name] ?? name

const primaryTypes = new Set<EntityIconType>([EntityIconType.PROJECT, EntityIconType.BUILD_TYPE])

class ProjectOrBuildTypeIcon extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    type: EntityIconType.PROJECT,
    size: 'S',
  }

  render(): React.ReactNode {
    const {type, composite, status, size, className, arrowClassName, title, withArrow} = this.props

    if (type == null) {
      return null
    }

    const typeIsInvestigation = type === EntityIconType.INVESTIGATION
    const typeIsPaused = type === EntityIconType.PAUSED || type === EntityIconType.PAUSED_OUTLINE
    const typeIsPrimary = primaryTypes.has(type)
    const classes = classNames(
      styles.icon,
      className,
      (typeIsPrimary || typeIsInvestigation || typeIsPaused) && styles[status ?? 'unknown'],
      {[styles.iconM]: size === 'M'},
    )
    const arrowClasses = classNames(classes, styles.arrow, arrowClassName)
    const name = [
      type,
      type === 'buildType' && composite === true && 'composite',
      `${Sizes[size]}px`,
      typeIsPrimary && status === 'failed' && 'fill',
    ]
      .filter(Boolean)
      .join('-')
    return (
      <>
        <SvgIcon className={classes} icon={getIcon(name)} title={title} />
        {(withArrow || arrowClassName != null) && (
          <SvgIcon className={arrowClasses} icon={getIcon(`${name}-arrow`)} />
        )}
      </>
    )
  }
}

export default ProjectOrBuildTypeIcon
