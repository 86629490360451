import type {AppThunk} from '../../../actions/types'
import {getChangeTagsArg} from '../../../rest/builds'
import {getAllTags, getTags} from '../../../selectors'
import {restApi} from '../../../services/rest'
import {STAR_TAG} from '../../../types'
import type {BuildId} from '../../../types'

export const starBuild =
  (buildId: BuildId, starred: boolean): AppThunk<Promise<unknown>> =>
  (dispatch, getState) => {
    const state = getState()
    const tags = getTags(state, buildId)
    return dispatch(
      restApi.endpoints.setBuildTags.initiate(
        getChangeTagsArg(
          buildId,
          starred
            ? tags.concat({
                name: STAR_TAG,
                private: true,
              })
            : tags,
          getAllTags(state, buildId),
        ),
      ),
    )
  }
