/* eslint-disable prefer-template */
import type {
  GetAgentNormalizedApiArg,
  GetAllAgentPreviewsNormalizedApiArg,
  GetAllAgentsApiArg,
} from '../services/rest'
import type {AgentRequestOptions, AgentId} from '../types'

import {buildTypeFields} from './buildTypes'

const getAgentFields = ({
  authorizedInfo,
  disconnectionComment,
  lastActivityTime,
  enabledInfo,
  cloudInfo,
  details,
}: AgentRequestOptions) =>
  'id,typeId,ip,name,enabled,connected,authorized,uptodate,version,outdated,currentAgentVersion,pluginsOutdated,javaOutdated,' +
  (details === true ? 'host,port,protocol,cpuRank,connectedSince,' : '') +
  (lastActivityTime === true ? 'lastActivityTime,' : '') +
  'links(link(type,relativeUrl)),' +
  'pool(id,name),' +
  (enabledInfo === true
    ? 'enabledInfo(status,comment(text,timestamp,user(id,name,username)),statusSwitchTime),'
    : '') +
  'environment(osType,osName),' +
  (disconnectionComment === true ? 'disconnectionComment,' : '') +
  (authorizedInfo === true
    ? 'authorizedInfo(status,comment(text,timestamp,user(id,name,username))),'
    : '') +
  (cloudInfo === true
    ? 'cloudInstance(id,image(name,profile(id,name,project(id))),agent(typeId,pool(id))),'
    : '') +
  `build(` +
  `id,number,branchName,defaultBranch,startDate,` +
  `running-info(percentageComplete,elapsedSeconds,estimatedTotalSeconds,leftSeconds,overtimeSeconds),` +
  `links(link(type,relativeUrl)),` +
  `statusText,status,state,personal,` +
  `user(id,name,username),` +
  `canceledInfo(text,user(id,name,username)),` +
  `tags(tag(name,private),$locator(private:true,owner:current)),` +
  `buildType(${buildTypeFields({
    withLinks: true,
  }).join(',')})` +
  `)`

export const getAgentsArg = (
  locator = 'state:any',
  {
    authorizedInfo = false,
    disconnectionComment = false,
    lastActivityTime = true,
    enabledInfo = true,
    cloudInfo = false,
  }: AgentRequestOptions = {},
): GetAllAgentsApiArg => ({
  locator,
  fields: `agent(${getAgentFields({
    authorizedInfo,
    disconnectionComment,
    lastActivityTime,
    enabledInfo,
    cloudInfo,
  })})`,
})
export const getSingleAgentArg = (
  id: AgentId,
  {
    authorizedInfo = true,
    disconnectionComment = true,
    lastActivityTime = true,
    enabledInfo = true,
    cloudInfo = false,
    details = true,
  }: AgentRequestOptions = {},
): GetAgentNormalizedApiArg => ({
  agentLocator: `id:${id}`,
  fields: getAgentFields({
    authorizedInfo,
    disconnectionComment,
    lastActivityTime,
    enabledInfo,
    cloudInfo,
    details,
  }),
  essential: true,
})

export const getAgentPreviewsArg = (): GetAllAgentPreviewsNormalizedApiArg => ({
  locator: 'defaultFilter:false',
  fields: // prettier-ignore
    'agent(' +
      'id,name,typeId,enabled,connected,authorized,ip,' +
      'pool(id,name),' +
      'build(id),' +
      'environment,' +
    ')',
  essential: true,
})
