import type {BuildTypeId, ChangeId} from '../../../../types'

export type ChangeTab = {
  id: string
  title: string
}

export enum ChangePageTabName {
  PROBLEMS_TESTS = 'problemsTests',
  BUILDS = 'builds',
  DEPLOYMENTS = 'deployments',
  FILES = 'files',
}

export const ChangePageTabTitle = {
  [ChangePageTabName.PROBLEMS_TESTS]: 'Problems & Tests',
  [ChangePageTabName.BUILDS]: 'Builds',
  [ChangePageTabName.DEPLOYMENTS]: 'Deployments',
  [ChangePageTabName.FILES]: 'Files',
}

export enum ClassicChangePageTabName {
  PROBLEMS_TESTS = 'vcsModificationTests',
  BUILDS = 'vcsModificationBuilds',
  DEPLOYMENTS = 'vcsModificationDeployments',
  FILES = 'vcsModificationFiles',
}

export type ChangeDetailsTabsProps = {
  className?: string
  changeId: ChangeId
  buildTypeId?: BuildTypeId | undefined | null
  currentTab: ChangePageTabName
  onSelectTab: (tab: ChangePageTabName) => void
  withHref?: boolean
}
