import Dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown'
import * as React from 'react'

import IconButton from '../IconButton/IconButton'

import {HelpDropdownPopup} from './HelpDropdownPopup/HelpDropdownPopup'

import styles from './HelpDropdown.css'

function HelpDropdown() {
  return (
    <>
      <Dropdown
        className={styles.dropdown}
        anchor={({active}) => <IconButton title="Help" icon="help-20px" primary={active} />}
        hoverMode
      >
        <HelpDropdownPopup />
      </Dropdown>
    </>
  )
}

export default React.memo(HelpDropdown)
