import interval from 'callbag-interval'
import map from 'callbag-map'
import pipe from 'callbag-pipe'
import share from 'callbag-share'
import subscribe from 'callbag-subscribe'
import {formatDistance, parseISO} from 'date-fns'
import {useEffect} from 'react'

import useEventSelector from './useEventSelector'

const SECOND = 1000
const now$ = pipe(
  interval(SECOND),
  map(() => new Date(Date.now())),
  share,
)

export default function useTimeSelector<T>(
  selector: (now: Date) => T,
  equalityFn: (a: T, b: T) => boolean = (a, b) => a === b,
) {
  const [value, callback] = useEventSelector(
    (now: Date = new Date(Date.now())) => selector(now),
    equalityFn,
  )
  useEffect(() => pipe(now$, subscribe(callback)), [callback])
  return value
}

export function useDistanceToNow(ISOString: string | null | undefined, addSuffix = true) {
  const date = ISOString != null ? parseISO(ISOString) : null
  return useTimeSelector(now => (date != null ? formatDistance(date, now, {addSuffix}) : null))
}
