/**
 * @generated SignedSource<<ea448d8011e14283e00a103141e3c629>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PermalinksQuery$variables = {
  finishedLocator?: string | null | undefined;
  pinnedLocator?: string | null | undefined;
  successfulLocator?: string | null | undefined;
};
export type PermalinksQuery$data = {
  readonly finished: {
    readonly count: number | null | undefined;
  } | null | undefined;
  readonly pinned: {
    readonly count: number | null | undefined;
  } | null | undefined;
  readonly successful: {
    readonly count: number | null | undefined;
  } | null | undefined;
};
export type PermalinksQuery = {
  response: PermalinksQuery$data;
  variables: PermalinksQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "finishedLocator"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pinnedLocator"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "successfulLocator"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": "pinned",
    "args": [
      {
        "kind": "Variable",
        "name": "locator",
        "variableName": "pinnedLocator"
      }
    ],
    "concreteType": "Builds",
    "kind": "LinkedField",
    "name": "builds",
    "plural": false,
    "selections": (v3/*: any*/),
    "storageKey": null
  },
  {
    "alias": "successful",
    "args": [
      {
        "kind": "Variable",
        "name": "locator",
        "variableName": "successfulLocator"
      }
    ],
    "concreteType": "Builds",
    "kind": "LinkedField",
    "name": "builds",
    "plural": false,
    "selections": (v3/*: any*/),
    "storageKey": null
  },
  {
    "alias": "finished",
    "args": [
      {
        "kind": "Variable",
        "name": "locator",
        "variableName": "finishedLocator"
      }
    ],
    "concreteType": "Builds",
    "kind": "LinkedField",
    "name": "builds",
    "plural": false,
    "selections": (v3/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PermalinksQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PermalinksQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "66a63db49fa30a43c0e5b3448c766f98",
    "id": null,
    "metadata": {},
    "name": "PermalinksQuery",
    "operationKind": "query",
    "text": "query PermalinksQuery(\n  $pinnedLocator: String\n  $successfulLocator: String\n  $finishedLocator: String\n) {\n  pinned: builds(locator: $pinnedLocator) {\n    count\n  }\n  successful: builds(locator: $successfulLocator) {\n    count\n  }\n  finished: builds(locator: $finishedLocator) {\n    count\n  }\n}\n"
  }
};
})();

(node as any).hash = "b731ebfe2601d100bf0bcc9da5cb553b";

export default node;
