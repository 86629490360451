/**
 * @generated SignedSource<<435458a58de197f8af1fec1620e117df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuildApprovalReviewers_approvalGroups$data = {
  readonly groupApproval: ReadonlyArray<{
    readonly group: {
      readonly key: string | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"BuildApprovalReviewers_groupApproval">;
  }> | null | undefined;
  readonly " $fragmentType": "BuildApprovalReviewers_approvalGroups";
};
export type BuildApprovalReviewers_approvalGroups$key = {
  readonly " $data"?: BuildApprovalReviewers_approvalGroups$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuildApprovalReviewers_approvalGroups">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuildApprovalReviewers_approvalGroups",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GroupApprovalRule",
      "kind": "LinkedField",
      "name": "groupApproval",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Group",
          "kind": "LinkedField",
          "name": "group",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "key",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BuildApprovalReviewers_groupApproval"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GroupApprovals",
  "abstractKey": null
};

(node as any).hash = "86fa3ededb10e9b22ef2e5aab2eff23c";

export default node;
