import {skipToken} from '@reduxjs/toolkit/query'

import {restApi} from '../../../../services/rest'
import {getDslOptions, getDslRequestEndpoint, getFragmentDsl} from '../Dsl.selectors'

import {useAppSelector} from 'src/hooks/react-redux'
import type {BuildTypeId} from 'src/types'

export const useIsDslLoaded = (
  controlId: string,
  buildTypeOrTemplateId?: BuildTypeId,
  isFragment?: boolean,
) => {
  const dslEndpoint = useAppSelector(state =>
    !isFragment && buildTypeOrTemplateId != null
      ? getDslRequestEndpoint(state, buildTypeOrTemplateId, controlId)
      : null,
  )
  const isDslMode = useAppSelector(state => getDslOptions(state, controlId)?.show ?? false)
  const shouldLoadDslHtml = isDslMode && !isFragment && dslEndpoint != null
  const {isLoaded} = restApi.endpoints.getHtml.useQuery(
    shouldLoadDslHtml ? {path: dslEndpoint, method: 'POST'} : skipToken,
    {selectFromResult: ({data}) => ({isLoaded: data != null})},
  )
  const isFragmentLoaded = useAppSelector(state => getFragmentDsl(state) != null)

  return shouldLoadDslHtml ? isLoaded : isFragmentLoaded
}
