import throttle from 'lodash/throttle'
import * as React from 'react'

import withHook from '../hocs/withHook'
import {noop} from '../utils/empty'

interface UpdateContextValue {
  update: () => unknown
  updateImmediate?: () => Promise<unknown>
}

const UpdateContext: React.Context<UpdateContextValue> = React.createContext({
  update: noop,
})

type UpdateProviderProps = {
  updateImmediate?: () => Promise<unknown>
  update: () => unknown
  timeout?: number
  children: React.ReactNode
}
const DEFAULT_TIMEOUT = 500 // ms

export function UpdateProvider({
  update,
  updateImmediate,
  timeout = DEFAULT_TIMEOUT,
  children,
}: UpdateProviderProps) {
  const throttledUpdate = React.useMemo(
    () =>
      throttle(update, timeout, {
        leading: true,
        trailing: true,
      }),
    [timeout, update],
  )
  const contextValue = React.useMemo(
    () => ({
      update: throttledUpdate,
      updateImmediate,
    }),
    [throttledUpdate, updateImmediate],
  )

  return <UpdateContext.Provider value={contextValue}>{children}</UpdateContext.Provider>
}
export const useUpdateResults = () => React.useContext(UpdateContext).update
export const useUpdateImmediateResults = () => React.useContext(UpdateContext).updateImmediate

export type UpdateResultProps = {
  updateResults(): unknown
}

export function withUpdateResults<OwnProps extends object>() {
  return withHook<{updateResults: UpdateContextValue['update']}, OwnProps>(() => ({
    updateResults: useUpdateResults(),
  }))
}
