import type {BuildId} from '../types'

import {restBaseApi} from './restBase'

type GetArtifactsSizeResponse = {size: string}
type GetArtifactsSizeArg = {buildId: BuildId; showAll: boolean}
export const buildArtifactsApi = restBaseApi.injectEndpoints({
  endpoints: build => ({
    getArtifactsSize: build.query<GetArtifactsSizeResponse, GetArtifactsSizeArg>({
      query: params => ({
        url: 'buildArtifactsDetails.html',
        params,
      }),
    }),
  }),
})
