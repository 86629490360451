import type {NormalizedTestOccurrences} from '../../../rest/schemata'
import {
  normalizeTestOccurrences,
  normalizeTestOccurrencesBuild,
  normalizeTestOccurrencesCurrentlyMutes,
  normalizeTestOccurrencesFirstFailed,
  normalizeTestOccurrencesInvestigations,
  normalizeTestOccurrencesInvocationsCounters,
  normalizeTestOccurrencesMetadataCount,
  normalizeTestOccurrencesMute,
  normalizeTestOccurrencesNewFailure,
  normalizeTestOccurrencesNextFixed,
  normalizeTestOccurrencesRunOrder,
} from '../../../rest/schemata'
import type {TestOccurrence} from '../../../services/rest'
import type {TestOccurrenceId} from '../../../types'
import {stringifyId} from '../../../types'
import {emptyArray, getEmptyHash} from '../../../utils/empty'
import type {KeyValue} from '../../../utils/object'
import {keyValue, objectEntries} from '../../../utils/object'
import type {
  ProblemOccurrencesTreeLeaf,
  ProblemOccurrencesTreeNode,
} from '../BuildProblems/BuildProblems.types'

import type {
  RequestTestOccurrenceOptionsParams,
  TestOccurrencesTreeLeaf,
  TestScope,
  TestScopeType,
  TestOccurrencesTreeNode,
} from './Tests.types'
import {TestScopesKey} from './Tests.types'

export const getTestOccurrencesEntities = (
  data: ReadonlyArray<TestOccurrence>,
  options?: RequestTestOccurrenceOptionsParams,
): NormalizedTestOccurrences => {
  const testOccurrences = normalizeTestOccurrences(data)
  let entities = {...testOccurrences.entities}

  if (options?.withNewFailure === true) {
    entities = {...entities, ...normalizeTestOccurrencesNewFailure(data).entities}
  }

  if (options?.withMetadataCount === true) {
    entities = {...entities, ...normalizeTestOccurrencesMetadataCount(data).entities}
  }

  if (options?.withFirstFailed === true) {
    entities = {...entities, ...normalizeTestOccurrencesFirstFailed(data).entities}
  }

  if (options?.withNextFixed === true) {
    entities = {...entities, ...normalizeTestOccurrencesNextFixed(data).entities}
  }

  if (options?.withRunOrder === true) {
    entities = {...entities, ...normalizeTestOccurrencesRunOrder(data).entities}
  }

  if (options?.withInvestigationInfo === true) {
    entities = {...entities, ...normalizeTestOccurrencesInvestigations(data).entities}
  }

  if (options?.withMuteInfo === true) {
    entities = {
      ...entities,
      ...normalizeTestOccurrencesCurrentlyMutes(data).entities,
      ...normalizeTestOccurrencesMute(data).entities,
    }
  }

  if (options?.withInvocationsCounters === true) {
    entities = {...entities, ...normalizeTestOccurrencesInvocationsCounters(data).entities}
  }

  if (options?.withBuildInfo === true) {
    entities = {...normalizeTestOccurrencesBuild(data).entities, ...entities}
  }

  return {
    entities,
    result: testOccurrences.result,
  }
}

export const trimAndRemoveColon = (value: string): string => {
  const text = value.trim()
  return text[text.length - 1] === ':' ? text.replace(/\:(?=[^:]*$)/g, '') : text
}

export const getTestOccurrenceElementId = (testOccurrenceId?: TestOccurrenceId): string =>
  `test-occurrence-${stringifyId(testOccurrenceId)}`

export const getTestScope = (
  scopeKey: string | null | undefined,
  scope: TestScope | TestScopeType,
): TestScope => {
  const defaultScope = {
    suite: null,
    package: null,
    class: null,
  }
  let result = {}

  if (scopeKey === TestScopesKey.SUITE) {
    result = {...defaultScope, suite: scope.suite}
  } else if (scopeKey === TestScopesKey.PACKAGE) {
    result = {...defaultScope, suite: scope.suite, package: scope.package}
  } else if (scopeKey === TestScopesKey.CLASS) {
    result = {...defaultScope, suite: scope.suite, package: scope.package, class: scope.class}
  } else {
    return defaultScope
  }

  return objectEntries(result).reduce(
    (acc, [key, data]) => ({...acc, [key]: data ?? null}),
    defaultScope,
  )
}

export function sortByDepthSubtreesKeys<T>(
  nullableSubtrees: KeyValue<string, T> | undefined | null,
  getDepth: (item: T | undefined) => number | undefined,
): ReadonlyArray<string> {
  const subtrees = nullableSubtrees ?? {}

  return Object.keys(subtrees).sort((key1, key2) => {
    const depth1 = getDepth(subtrees[key1]) ?? 0
    const depth2 = getDepth(subtrees[key2]) ?? 0
    return depth1 - depth2
  })
}

export const mergeTreeAndSubTrees = <
  T extends TestOccurrencesTreeNode | ProblemOccurrencesTreeNode,
>(
  treeNodes: readonly T[] | undefined,
  sortedByDepthSubtreesKeys: ReadonlyArray<string>,
  getSubtreeNodes: (key: string) => readonly T[] | undefined,
) => {
  let nodes = [...(treeNodes ?? emptyArray)]

  sortedByDepthSubtreesKeys.forEach(subtreeKey => {
    let subtreeRootIndex
    const subtreeNodes = getSubtreeNodes(subtreeKey) ?? []

    const subtreeNodesIds: Record<string, T> = subtreeNodes.reduce(
      (acc, node) => ({...acc, [node.id]: node}),
      {},
    )

    nodes.forEach((node, index) => {
      if (node.id === subtreeKey) {
        subtreeRootIndex = index
      }
      if (subtreeNodesIds[node.id]) {
        delete nodes[index]
      }
    })

    if (subtreeRootIndex != null) {
      nodes.splice(subtreeRootIndex, 0, ...subtreeNodes)
    }
    nodes = nodes.filter(Boolean)
  })

  return nodes
}

export const getLeavesHash = <T extends TestOccurrencesTreeLeaf | ProblemOccurrencesTreeLeaf>(
  treeLeaves: readonly T[] | undefined,
  sortedByDepthSubtreesKeys: ReadonlyArray<string>,
  getSubtreeLeaves: (key: string) => readonly T[] | undefined,
): Record<string, T> => {
  const subtreesLeaves: T[] = []

  sortedByDepthSubtreesKeys.forEach(subtreeNodeId => {
    const leaves = getSubtreeLeaves(subtreeNodeId) ?? []
    subtreesLeaves.push(...leaves)
  })

  return treeLeaves != null
    ? [...treeLeaves, ...subtreesLeaves].reduce(
        (acc, leaf) => ({...acc, ...keyValue(leaf.nodeId, leaf)}),
        {},
      )
    : getEmptyHash()
}
