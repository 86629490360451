/**
 * @generated SignedSource<<c3abf7be302aa3c2c14463f826c196a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueueSidebarQuery$variables = Record<PropertyKey, never>;
export type QueueSidebarQuery$data = {
  readonly agentPoolsRest: {
    readonly " $fragmentSpreads": FragmentRefs<"QueueSidebarPoolsFragment">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"QueueSidebarPoolIdsFragment">;
};
export type QueueSidebarQuery = {
  response: QueueSidebarQuery$data;
  variables: QueueSidebarQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueueSidebarQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "QueueSidebarPoolIdsFragment"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AgentPools",
        "kind": "LinkedField",
        "name": "agentPoolsRest",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "QueueSidebarPoolsFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QueueSidebarQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AgentPools",
        "kind": "LinkedField",
        "name": "agentPoolsRest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AgentPoolRest",
            "kind": "LinkedField",
            "name": "agentPool",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rawId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d10635eab555db3cabeb83a634b9eabf",
    "id": null,
    "metadata": {},
    "name": "QueueSidebarQuery",
    "operationKind": "query",
    "text": "query QueueSidebarQuery {\n  ...QueueSidebarPoolIdsFragment\n  agentPoolsRest {\n    ...QueueSidebarPoolsFragment\n  }\n}\n\nfragment QueueSidebarPoolIdsFragment on Query {\n  agentPoolsRest {\n    agentPool {\n      rawId\n      id\n    }\n  }\n}\n\nfragment QueueSidebarPoolsFragment on AgentPools {\n  agentPool {\n    rawId\n    name\n    id\n  }\n}\n"
  }
};

(node as any).hash = "bb1af71984a9c6b21121bec498ebe9cc";

export default node;
