/**
 * @generated SignedSource<<954d5c4dcc250cf06277cc4ea3620f4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BuildTypePageQuery$variables = {
  locator: string;
};
export type BuildTypePageQuery$data = {
  readonly buildType: {
    readonly project: {
      readonly internalId: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type BuildTypePageQuery = {
  response: BuildTypePageQuery$data;
  variables: BuildTypePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locator"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "btLocator",
    "variableName": "locator"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BuildTypePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BuildTypeRest",
        "kind": "LinkedField",
        "name": "buildType",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectRest",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BuildTypePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BuildTypeRest",
        "kind": "LinkedField",
        "name": "buildType",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectRest",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d067ce2a63f1343608be08f98dad82d2",
    "id": null,
    "metadata": {},
    "name": "BuildTypePageQuery",
    "operationKind": "query",
    "text": "query BuildTypePageQuery(\n  $locator: String!\n) {\n  buildType(btLocator: $locator) {\n    project {\n      internalId\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d7871f55e3b297e20e37a338a131924a";

export default node;
