import type {ListDataItem} from '@jetbrains/ring-ui/components/list/consts'
import {Type as ListItemType} from '@jetbrains/ring-ui/components/list/consts'
import {Fragment, useCallback, useMemo} from 'react'

import {useAppSelector} from '../../../../hooks/react-redux'
import {useSetUserProperty} from '../../../../hooks/useSetUserProperty'
import {getServerInfo} from '../../../../selectors'
import {BS, internalProps} from '../../../../types/BS_types'
import {truthy} from '../../../../utils/guards'
import {resolveHelpURL, resolveRelative} from '../../../../utils/url'
import {UserProperties} from '../../../../utils/userProperties'
import {useIsHintsModeEnabled} from '../../../packages/Hints/Hints.hooks'
import SvgIcon from '../../SvgIcon/SvgIcon'
import styles from '../HelpDropdown.css'

import {aboutSakuraUIHref, whatsNewHref} from './HelpDropdownPopup.consts'
import {getSupportUrl} from './HelpDropdownPopup.utils'

const supportLinkEnabled = internalProps['teamcity.ui.supportLink.enabled']

export function useHelpDropdownContent(): ReadonlyArray<ListDataItem> {
  const {isEnabled, isGuestOrRoot} = useIsHintsModeEnabled()
  const setUserProperty = useSetUserProperty()
  const toggleTipsMode = useCallback(() => {
    setUserProperty(UserProperties.TIPS_MODE, isEnabled ? 'false' : 'true')
  }, [setUserProperty, isEnabled])

  const {licenseIsCloud, licenseIsEnterpise, version} = useAppSelector(getServerInfo) || {}

  return useMemo(
    () =>
      [
        {
          label: 'Documentation',
          href: resolveHelpURL('?TeamCity Documentation'),
          rgItemType: ListItemType.LINK,
          rel: 'noreferrer',
        },
        {
          label: 'TeamCity Kotlin DSL',
          href: resolveRelative('/app/dsl-documentation/index.html'),
          rgItemType: ListItemType.LINK,
          rel: 'noreferrer',
        },
        supportLinkEnabled &&
          (licenseIsCloud || licenseIsEnterpise) && {
            label: 'Support',
            href: getSupportUrl(licenseIsCloud, licenseIsEnterpise, version),
            rgItemType: ListItemType.LINK,
            rel: 'noreferrer',
          },
        {
          label: 'Feedback',
          href: BS?.feedbackUrl,
          rgItemType: ListItemType.LINK,
          rel: 'noreferrer',
        },
        {
          label: 'Getting started',
          href: resolveHelpURL('?Configure and Run Your First Build'),
          rgItemType: ListItemType.LINK,
          rel: 'noreferrer',
        },
        !isGuestOrRoot && {
          key: 'enable-tips',
          rgItemType: ListItemType.LINK,
          label: `${isEnabled ? 'Hide' : 'Show'} hints`,
          onClick: toggleTipsMode,
        },
        {
          key: 'about-sakura-ui',
          className: styles.customLink,
          href: aboutSakuraUIHref,
          children: 'About Sakura UI',
          rel: 'noreferrer',
        },
        {
          key: 'whats-new',
          className: styles.customLink,
          href: whatsNewHref,
          children: (
            <Fragment>
              <SvgIcon icon="gift" className={styles.customLinkIcon} />
              {"What's new"}
            </Fragment>
          ),
          rel: 'noreferrer',
        },
      ].filter(truthy),
    [isEnabled, isGuestOrRoot, licenseIsCloud, version, licenseIsEnterpise, toggleTipsMode],
  )
}
