import type {FetchBaseQueryError} from '@reduxjs/toolkit/query'

import type {HTTPError} from '../types'
import {HTTPCodes, instanceOf} from '../types'

export function serializeError(error: unknown) {
  if (instanceOf<HTTPError>(error, 'statusCode')) {
    return error.statusCode !== HTTPCodes.NOT_FOUND ? error.toString() : 'Error: not found'
  } else if (instanceOf<FetchBaseQueryError>(error, 'status')) {
    switch (error.status) {
      case HTTPCodes.NOT_FOUND:
        return 'Error: not found'
      case 'FETCH_ERROR':
      case 'CUSTOM_ERROR':
        return error.error
      default:
        return String(error.data)
    }
  } else if (instanceOf<Error>(error, 'message')) {
    return error.message
  } else {
    return typeof error === 'string' ? error : 'Unknown error'
  }
}
