import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'
import {getRequest} from 'relay-runtime'

import {Policy} from '../../../rest/branches'
import {getProjectBuildTypeFilter} from '../../../types'
import {makeResource} from '../../../utils/makeResource'
import {queryToObject} from '../../../utils/queryParams'
import {
  branchesQuery,
  getBranchesQueryVariables,
} from '../../common/BranchSelect/BranchSelect.queries'

import type {TestHistoryPageOuterContainerComponent} from './TestHistoryPage.container'

export const TestHistoryPageEntryPoint: EntryPoint<
  TestHistoryPageOuterContainerComponent,
  LoaderFunctionArgs
> = {
  root: makeResource(
    'TestHistoryPage',
    () =>
      import(
        /* webpackChunkName: "TestHistoryPage", webpackPrefetch: true */ './TestHistoryPage.container'
      ),
  ),
  getPreloadProps: ({request}) => {
    const url = new URL(request.url)
    const query = queryToObject(url.search)
    const projectId = query.currentProjectId
    const buildTypeId = query.currentBuildTypeId
    return {
      queries: {
        branches: {
          parameters: getRequest(branchesQuery),
          variables: getBranchesQueryVariables({
            node: getProjectBuildTypeFilter({
              projectId,
              buildTypeId,
            }),
            policy: Policy.HISTORY_BRANCHES,
            includeSubprojects: buildTypeId == null,
          }),
        },
      },
    }
  },
}
