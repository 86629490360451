import {useContext} from 'react'
import {graphql, useFragment} from 'react-relay'

import {CurrentUserKeyContext} from '../contexts/currentUserKey'
import {getPropertyFromList} from '../utils/getProperty'

import type {useUserPropertyFragment$key} from './__generated__/useUserPropertyFragment.graphql'

const useUserPropertyFragment = graphql`
  fragment useUserPropertyFragment on User {
    properties {
      property {
        name
        value
      }
    }
  }
`

export function useUserProperty(name: string) {
  const userKey: useUserPropertyFragment$key | null = useContext(CurrentUserKeyContext)
  const user = useFragment(useUserPropertyFragment, userKey)
  return getPropertyFromList(name, user?.properties)
}

export function useBooleanUserProperty(name: string, defaultTrue?: boolean) {
  const value = useUserProperty(name)
  return defaultTrue ? value !== 'false' : value === 'true'
}
