import {graphql} from 'react-relay'

export const buildArtifactsQuery = graphql`
  query BuildArtifactsQuery($buildLocator: String!) {
    build(buildLocator: $buildLocator) {
      rawId
      state
      artifacts {
        count
      }
    }
  }
`
