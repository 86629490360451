import type {LinkProps} from '@jetbrains/ring-ui/components/link/link'
import RingLink from '@jetbrains/ring-ui/components/link/link'
import classNames from 'classnames'
import {memo} from 'react'

import {getIsSakuraUI} from '../../../selectors'
import {resolveRelative} from '../../../utils/url'

import styles from './Link.css'

import {useAppSelector} from 'src/hooks/react-redux'

type Props = LinkProps & {
  relative?: boolean
  supressConservative?: boolean
}

function Link({children, className, href, relative, supressConservative, ...restProps}: Props) {
  const isSakuraUI = useAppSelector(getIsSakuraUI)
  const classes = classNames(className, styles.link, {
    [styles.conservative]: !isSakuraUI && supressConservative !== true,
  })
  return (
    <RingLink
      {...restProps}
      href={relative === true && href != null ? resolveRelative(href) : href}
      className={classes}
    >
      {children}
    </RingLink>
  )
}

export default memo(Link)
