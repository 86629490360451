import type {File} from '../../../types'
import {getChildPath} from '../../../utils/fileTree'
import {objectToQuery} from '../../../utils/queryParams'

const TEAMCITY_DIRNAME = '.teamcity'

export const isHiddenArtifact = ({name}: File) =>
  window.internalProps['teamcity.hidden.artifacts.use.extended.names']
    ? name.startsWith(TEAMCITY_DIRNAME)
    : name === TEAMCITY_DIRNAME

export const isParent = (parent: string, child: string) =>
  child.indexOf(getChildPath(parent, '')) === 0

export const getLeftIndent = (level: number) => {
  const indent = 10
  const extraIndent = 5
  const paddingLeft = level ? (level + 2) * indent + extraIndent : 0

  return {paddingLeft}
}

export const getArtifactsHref = (
  buildUrl?: string | null,
  showAll?: boolean | null,
): string | undefined =>
  buildUrl != null
    ? `${buildUrl}&${objectToQuery({
        tab: 'artifacts',
        showAll: showAll === true ? 'true' : null,
      })}`
    : undefined
