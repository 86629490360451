import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {makeResource} from '../../../../../utils/makeResource'

import type {ProjectsSidebarOuterContainerComponent} from './ProjectsSidebar.container'
import {query} from './ProjectsSidebar.queries'

export const ProjectsSidebarEntryPoint: EntryPoint<ProjectsSidebarOuterContainerComponent> = {
  root: makeResource(
    'ProjectsSidebar',
    () =>
      import(
        /* webpackChunkName: "ProjectsSidebar", webpackPrefetch: true */ './ProjectsSidebar.container'
      ),
  ),
  getPreloadProps: () => ({
    queries: {
      main: {
        parameters: getRequest(query),
        variables: {},
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
    },
  }),
}
