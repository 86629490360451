/**
 * @generated SignedSource<<c790462c15299b881787c2a270f90f6d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AgentPoolPageMoveCloudImageToAgentPoolMutation$variables = {
  agentPoolRawId: string;
  agentTypeRawId: string;
};
export type AgentPoolPageMoveCloudImageToAgentPoolMutation$data = {
  readonly moveCloudImageToAgentPool: {
    readonly targetAgentPool: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
};
export type AgentPoolPageMoveCloudImageToAgentPoolMutation = {
  response: AgentPoolPageMoveCloudImageToAgentPoolMutation$data;
  variables: AgentPoolPageMoveCloudImageToAgentPoolMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "agentPoolRawId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "agentTypeRawId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "agentTypeRawId",
            "variableName": "agentTypeRawId"
          },
          {
            "kind": "Variable",
            "name": "targetAgentPoolRawId",
            "variableName": "agentPoolRawId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "MoveCloudImageToAgentPoolPayload",
    "kind": "LinkedField",
    "name": "moveCloudImageToAgentPool",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AgentPool",
        "kind": "LinkedField",
        "name": "targetAgentPool",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentPoolPageMoveCloudImageToAgentPoolMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AgentPoolPageMoveCloudImageToAgentPoolMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "ad783f4b310035177da6943b00c98af6",
    "id": null,
    "metadata": {},
    "name": "AgentPoolPageMoveCloudImageToAgentPoolMutation",
    "operationKind": "mutation",
    "text": "mutation AgentPoolPageMoveCloudImageToAgentPoolMutation(\n  $agentTypeRawId: ID!\n  $agentPoolRawId: ID!\n) {\n  moveCloudImageToAgentPool(input: {agentTypeRawId: $agentTypeRawId, targetAgentPoolRawId: $agentPoolRawId}) {\n    targetAgentPool {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1f27d1616115e3e3542926a458316d05";

export default node;
