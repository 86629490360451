import styles from './HealthItem.css'

export function getSeverityClassName(severity: 'Error' | 'Warning' | 'Info') {
  switch (severity) {
    case 'Error':
      return styles.error
    case 'Warning':
      return styles.warning
    case 'Info':
    default:
      return styles.info
  }
}
