import type Theme from '@jetbrains/ring-ui/components/global/theme'

import {getCurrentTheme} from '../utils/getCurrentTheme'

enum MessageTypes {
  CURRENT_THEME = 'CURRENT_THEME',
  CURRENT_THEME_REQUEST = 'CURRENT_THEME_REQUEST',
}
const kind = 'dark-theme-adapter-sync'

type MessageType = {
  kind: typeof kind
  type: MessageTypes
  payload: Theme
}

const iframePostMessage = (
  type: MessageTypes,
  payload: Theme,
  iframe?: Window | MessageEventSource | null,
) =>
  iframe?.postMessage({
    kind,
    type,
    payload,
  })
const sendChangeThemeRequest = (theme: Theme, destination?: Window) => {
  if (!destination) {
    const destinations = document.querySelectorAll<HTMLIFrameElement>(
      'iframe[data-tc-iframe-darktheme-adapter]',
    )
    destinations.forEach(dst => {
      const iframeWindow = dst.contentWindow

      iframePostMessage(MessageTypes.CURRENT_THEME, theme, iframeWindow)
    })
  }
}

const onThemeChangeRequests = (theme: Theme) => {
  if (!window.ReactUI) {
    return
  }

  window.ReactUI.setGlobalTheme(theme)
  sendChangeThemeRequest(theme)
}

const startListening = () => {
  const handler = (event: MessageEvent<MessageType>) => {
    const data = event.data
    if (data?.kind !== kind) {
      return
    }

    switch (data.type) {
      case MessageTypes.CURRENT_THEME:
        onThemeChangeRequests(data.payload)
        break
      case MessageTypes.CURRENT_THEME_REQUEST:
        if (event.source) {
          const theme = getCurrentTheme()

          iframePostMessage(MessageTypes.CURRENT_THEME, theme, event.source)
        }

        break
      default:
        break
    }
  }

  window.addEventListener('message', handler)

  return () => window.removeEventListener('message', handler)
}

startListening()

export {sendChangeThemeRequest}
