/**
 * @generated SignedSource<<d273f6b6620084188416aadfd15d5c53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AgentPoolPageUnassignProjectFromAgentPoolMutation$variables = {
  agentPoolRawId: string;
  projectRawId: string;
  recursively?: boolean | null | undefined;
};
export type AgentPoolPageUnassignProjectFromAgentPoolMutation$data = {
  readonly unassignProjectFromAgentPool: {
    readonly agentPool: {
      readonly projects: {
        readonly " $fragmentSpreads": FragmentRefs<"AgentPoolPageProjectsConnectionFragment">;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type AgentPoolPageUnassignProjectFromAgentPoolMutation = {
  response: AgentPoolPageUnassignProjectFromAgentPoolMutation$data;
  variables: AgentPoolPageUnassignProjectFromAgentPoolMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "agentPoolRawId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectRawId"
},
v2 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "recursively"
},
v3 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "agentPoolRawId",
        "variableName": "agentPoolRawId"
      },
      {
        "kind": "Variable",
        "name": "projectRawId",
        "variableName": "projectRawId"
      },
      {
        "kind": "Variable",
        "name": "recursive",
        "variableName": "recursively"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v4 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": {}
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rawId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "archived",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentPoolPageUnassignProjectFromAgentPoolMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "UnassignProjectFromAgentPoolPayload",
        "kind": "LinkedField",
        "name": "unassignProjectFromAgentPool",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AgentPool",
            "kind": "LinkedField",
            "name": "agentPool",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "AgentPoolProjectsConnection",
                "kind": "LinkedField",
                "name": "projects",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "AgentPoolPageProjectsConnectionFragment"
                  }
                ],
                "storageKey": "projects(filter:{})"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "AgentPoolPageUnassignProjectFromAgentPoolMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "UnassignProjectFromAgentPoolPayload",
        "kind": "LinkedField",
        "name": "unassignProjectFromAgentPool",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AgentPool",
            "kind": "LinkedField",
            "name": "agentPool",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "AgentPoolProjectsConnection",
                "kind": "LinkedField",
                "name": "projects",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AgentPoolProjectEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Project",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProjectPermissions",
                            "kind": "LinkedField",
                            "name": "permissions",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "manageAgentPoolsForProject",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProjectAgentPoolsConnection",
                            "kind": "LinkedField",
                            "name": "agentPools",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProjectAgentPoolEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                      },
                                      (v5/*: any*/),
                                      (v6/*: any*/),
                                      (v7/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProjectsConnection",
                            "kind": "LinkedField",
                            "name": "ancestorProjects",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProjectEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Project",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v5/*: any*/),
                                      (v6/*: any*/),
                                      (v7/*: any*/),
                                      (v8/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "projects(filter:{})"
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4c072c5bfc85f55f8be77f77a7ba5b1a",
    "id": null,
    "metadata": {},
    "name": "AgentPoolPageUnassignProjectFromAgentPoolMutation",
    "operationKind": "mutation",
    "text": "mutation AgentPoolPageUnassignProjectFromAgentPoolMutation(\n  $projectRawId: ID!\n  $agentPoolRawId: ID!\n  $recursively: Boolean = false\n) {\n  unassignProjectFromAgentPool(input: {projectRawId: $projectRawId, agentPoolRawId: $agentPoolRawId, recursive: $recursively}) {\n    agentPool {\n      projects(filter: {}) {\n        ...AgentPoolPageProjectsConnectionFragment\n      }\n      id\n    }\n  }\n}\n\nfragment AgentPoolPageProjectsConnectionFragment on AgentPoolProjectsConnection {\n  edges {\n    node {\n      id\n      rawId\n      name\n      archived\n      permissions {\n        manageAgentPoolsForProject\n      }\n      agentPools {\n        edges {\n          node {\n            __typename\n            id\n            rawId\n            name\n          }\n        }\n      }\n      ancestorProjects {\n        edges {\n          node {\n            id\n            rawId\n            name\n            archived\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b01d4826bbb56d137889ab74f8a61cee";

export default node;
