/**
 * @generated SignedSource<<642dba2ad0339f48cb549666085aae4a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BranchSelectBranchExistsQuery$variables = {
  btLocator: string;
  checkInBuildType: boolean;
  checkInProject: boolean;
  projectLocator: string;
  selectedLocator: string;
};
export type BranchSelectBranchExistsQuery$data = {
  readonly branchExistsInBuildType?: boolean | null | undefined;
  readonly branchExistsInProject?: boolean | null | undefined;
};
export type BranchSelectBranchExistsQuery = {
  response: BranchSelectBranchExistsQuery$data;
  variables: BranchSelectBranchExistsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "btLocator"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "checkInBuildType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "checkInProject"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectLocator"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "selectedLocator"
},
v5 = {
  "kind": "Variable",
  "name": "branchLocator",
  "variableName": "selectedLocator"
},
v6 = [
  {
    "condition": "checkInProject",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "alias": null,
        "args": [
          (v5/*: any*/),
          {
            "kind": "Variable",
            "name": "projectLocator",
            "variableName": "projectLocator"
          }
        ],
        "kind": "ScalarField",
        "name": "branchExistsInProject",
        "storageKey": null
      }
    ]
  },
  {
    "condition": "checkInBuildType",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "alias": null,
        "args": [
          (v5/*: any*/),
          {
            "kind": "Variable",
            "name": "btLocator",
            "variableName": "btLocator"
          }
        ],
        "kind": "ScalarField",
        "name": "branchExistsInBuildType",
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BranchSelectBranchExistsQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "BranchSelectBranchExistsQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "37eb5b5c23d2a49fcf907c134fd1bdaf",
    "id": null,
    "metadata": {},
    "name": "BranchSelectBranchExistsQuery",
    "operationKind": "query",
    "text": "query BranchSelectBranchExistsQuery(\n  $selectedLocator: String!\n  $projectLocator: String!\n  $btLocator: String!\n  $checkInProject: Boolean!\n  $checkInBuildType: Boolean!\n) {\n  branchExistsInProject(branchLocator: $selectedLocator, projectLocator: $projectLocator) @include(if: $checkInProject)\n  branchExistsInBuildType(branchLocator: $selectedLocator, btLocator: $btLocator) @include(if: $checkInBuildType)\n}\n"
  }
};
})();

(node as any).hash = "abfe5f7b77ebd07424172284b0f6a985";

export default node;
