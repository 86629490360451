import {useCallback, useState} from 'react'
import {useLazyLoadQuery} from 'react-relay'
import type {GraphQLTaggedNode, OperationType, VariablesOf} from 'relay-runtime'

type LazyLoadQueryOptions = Parameters<typeof useLazyLoadQuery>[2]

export function useRefetchableLazyLoadQuery<TQuery extends OperationType>(
  gqlQuery: GraphQLTaggedNode,
  variables: VariablesOf<TQuery>,
): [TQuery['response'], () => void] {
  const [refreshedQueryOptions, setRefreshedQueryOptions] = useState<LazyLoadQueryOptions>()
  const refetch = useCallback(() => {
    setRefreshedQueryOptions(prev => ({
      fetchKey: Number(prev?.fetchKey ?? 0) + 1,
      fetchPolicy: 'store-and-network',
      networkCacheConfig: {metadata: {isRefetch: true}},
    }))
  }, [])
  const data = useLazyLoadQuery(gqlQuery, variables, refreshedQueryOptions)
  return [data, refetch]
}
