/**
 * @generated SignedSource<<44609229d59c1680f10c9de8dde1284f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuildApprovalReviewers_approvalUser$data = {
  readonly name: string | null | undefined;
  readonly rawId: number | null | undefined;
  readonly username: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"AvatarFragment">;
  readonly " $fragmentType": "BuildApprovalReviewers_approvalUser";
};
export type BuildApprovalReviewers_approvalUser$key = {
  readonly " $data"?: BuildApprovalReviewers_approvalUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuildApprovalReviewers_approvalUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuildApprovalReviewers_approvalUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rawId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AvatarFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "20a55e607843e645e59c07c2dfe17acd";

export default node;
