/**
 * @generated SignedSource<<2f5e5b3d8fcf665ede24e7b548668354>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TestsPreviewPanelCountsQuery$variables = {
  locator: string;
};
export type TestsPreviewPanelCountsQuery$data = {
  readonly testOccurrences: {
    readonly testCounters: {
      readonly " $fragmentSpreads": FragmentRefs<"TestsPreviewPanelCountersFragment">;
    } | null | undefined;
  } | null | undefined;
};
export type TestsPreviewPanelCountsQuery = {
  response: TestsPreviewPanelCountsQuery$data;
  variables: TestsPreviewPanelCountsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locator"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "locator",
    "variableName": "locator"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TestsPreviewPanelCountsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TestOccurrences",
        "kind": "LinkedField",
        "name": "testOccurrences",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TestCounters",
            "kind": "LinkedField",
            "name": "testCounters",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TestsPreviewPanelCountersFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TestsPreviewPanelCountsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TestOccurrences",
        "kind": "LinkedField",
        "name": "testOccurrences",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TestCounters",
            "kind": "LinkedField",
            "name": "testCounters",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ignored",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "success",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "muted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "all",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d0da55e1c369ed6c4e1ca99f5b550e6f",
    "id": null,
    "metadata": {},
    "name": "TestsPreviewPanelCountsQuery",
    "operationKind": "query",
    "text": "query TestsPreviewPanelCountsQuery(\n  $locator: String!\n) {\n  testOccurrences(locator: $locator) {\n    testCounters {\n      ...TestsPreviewPanelCountersFragment\n    }\n  }\n}\n\nfragment TestsPreviewPanelCountersFragment on TestCounters {\n  ignored\n  success\n  muted\n  all\n}\n"
  }
};
})();

(node as any).hash = "81a5b36940ef488bfd4e3b56d633c795";

export default node;
