import type {AppThunk} from '../../../actions/types'
import {getExtensionEndpoint} from '../../../selectors'
import {restApi} from '../../../services/rest'
import type {BuildId} from '../../../types'

import {getFullLogState, getMessagesLoadStates} from './BuildLog.selectors'
import {settings} from './BuildLog.slices'
import type {FetchMessagesParams} from './BuildLog.types'
import {getKeyForBuildLogData} from './BuildLog.utils'

export const fetchBuildLogMessages =
  (params: FetchMessagesParams): AppThunk<any> =>
  (dispatch, getState) => {
    const {target, options} = params
    const filter = target != null ? getFullLogState(getState(), target).filter : null
    const defaultOptions = {
      filter,
    }
    const allOptions = {...defaultOptions, ...options}
    const {endpoint} = getExtensionEndpoint(getState(), 'fetchExpandedBuildLogMessage') ?? {}
    if (endpoint) {
      dispatch(
        restApi.endpoints.getBuildLogMessages.initiate(
          {...params, options: allOptions, endpoint},
          {forceRefetch: true},
        ),
      )
    }
  }

export const fetchBuildLogPreview =
  (buildId: BuildId, messagesCount: number): AppThunk<void> =>
  (dispatch, getState) => {
    const state = getState()
    const buildLogKey = getKeyForBuildLogData({
      type: 'preview',
      id: buildId,
    })
    const loadState = getMessagesLoadStates(state, buildLogKey)?.tail

    if (loadState?.loading === true) {
      return
    }

    dispatch(
      fetchBuildLogMessages({
        options: {
          target: 'tail',
          count: [0, -messagesCount * 2],
          //Load more lines because some may be empty
          expandAll: true,
        },
        buildLogKey,
        buildId,
      }),
    )
  }
export const setSoftWrapLinesInBuildlog = settings.actions.setSoftWrapLines
export const setRelativeTimeInBuildlog = settings.actions.setRelativeTime
