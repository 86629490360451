import Button from '@jetbrains/ring-ui/components/button/button'
import * as React from 'react'

import {restApi} from '../../../../services/rest'
import HelpLink from '../../../common/HelpLink/HelpLink'
import IconButton from '../../../common/IconButton/IconButton'
import {initDslOptions, showDsl, hideDsl} from '../Dsl.actions'
import {getDslOptions} from '../Dsl.selectors'

import {useIsDslLoaded} from './ShowDsl.hooks'

import {useAppDispatch, useAppSelector} from 'src/hooks/react-redux'
import type {BuildTypeId} from 'src/types'

type Props = {
  helpUrl: string
  controlId: string
  isFragment?: boolean
  buildTypeOrTemplateId?: BuildTypeId
  onShowDsl?: () => unknown
  onHideDsl?: () => unknown
}

function ShowDsl({
  helpUrl,
  controlId,
  isFragment,
  buildTypeOrTemplateId,
  onShowDsl,
  onHideDsl,
}: Props) {
  const dispatch = useAppDispatch()

  const isDslMode = useAppSelector(state => getDslOptions(state, controlId)?.show ?? false)
  const isDslLoaded = useIsDslLoaded(controlId, buildTypeOrTemplateId, isFragment)
  const title = isDslMode ? 'Edit in UI' : 'View as code'

  const toggleDslMode = React.useCallback(() => {
    if (isDslMode) {
      dispatch(hideDsl(controlId))
      onHideDsl?.()
      return
    }

    dispatch(showDsl(controlId))
    onShowDsl?.()
  }, [dispatch, isDslMode, controlId, onHideDsl, onShowDsl])

  const {portable, version} = restApi.endpoints.getDslOptions.useQuery(undefined, {
    selectFromResult: ({data}) => {
      const defaultOptionFromBackend =
        data != null ? data.kotlin.find(options => options.current) || data.kotlin[0] : null
      return {
        portable: defaultOptionFromBackend ? defaultOptionFromBackend.portable : true,
        version: defaultOptionFromBackend ? defaultOptionFromBackend.version : 'v2018_2',
      }
    },
  })
  React.useEffect(() => {
    dispatch(initDslOptions({controlId, options: {show: false, portable, version}}))
  }, [dispatch, controlId, portable, version])

  return (
    <span>
      <Button onClick={toggleDslMode} disabled={isDslMode && !isDslLoaded}>
        {title}
      </Button>
      <HelpLink url={helpUrl} Component={IconButton} icon="help" />
    </span>
  )
}

export default ShowDsl
