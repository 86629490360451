/**
 * @generated SignedSource<<851cec8c91280dbae999b2e41cfe3505>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuildApprovalControls_approvalInfo$data = {
  readonly canBeApprovedByCurrentUser: boolean | null | undefined;
  readonly status: string | null | undefined;
  readonly timeoutTimestamp: string | null | undefined;
  readonly " $fragmentType": "BuildApprovalControls_approvalInfo";
};
export type BuildApprovalControls_approvalInfo$key = {
  readonly " $data"?: BuildApprovalControls_approvalInfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuildApprovalControls_approvalInfo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuildApprovalControls_approvalInfo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canBeApprovedByCurrentUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeoutTimestamp",
      "storageKey": null
    }
  ],
  "type": "ApprovalInfo",
  "abstractKey": null
};

(node as any).hash = "74c2e19d2f773e8fbd9b5943fdd0d79b";

export default node;
