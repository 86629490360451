import {useEffect} from 'react'
import {graphql} from 'react-relay'

import {getProjectPermissionsLocator} from '../rest/permission'
import type {Permission, ProjectId} from '../types'
import {stringifyId} from '../types'
import {subscribeOnUserEvents} from '../utils/subscriber'
import * as SubscriptionEvents from '../utils/subscriptionEvents'

import type {usePermissionQuery} from './__generated__/usePermissionQuery.graphql'
import {useRefetchableLazyLoadQuery} from './relay/useRefetchableLazyLoadQuery'
import useMyId from './useMyId'

export const PermissionQuery = graphql`
  query usePermissionQuery($locator: String, $skip: Boolean = false) {
    projectsRest(locator: $locator) @skip(if: $skip) {
      count
    }
  }
`

export function usePermission(
  permission: Permission,
  projectId: ProjectId | null | undefined,
): boolean {
  const [{projectsRest}, refetch] = useRefetchableLazyLoadQuery<usePermissionQuery>(
    PermissionQuery,
    {
      locator: projectId && getProjectPermissionsLocator(permission, projectId),
      skip: projectId == null,
    },
  )
  const myId = useMyId()
  useEffect(
    () =>
      myId != null && projectId != null
        ? subscribeOnUserEvents(
            `${permission}:${stringifyId(projectId)}`,
            myId,
            [SubscriptionEvents.USER_PERMISSIONS_CHANGED],
            refetch,
          )
        : undefined,
    [myId, permission, projectId, refetch],
  )
  return Boolean(projectsRest?.count)
}
