import {createReducer} from '@reduxjs/toolkit'
import {combineReducers} from 'redux'

import {restApi} from '../../../../../services/rest'
import {toProjectId} from '../../../../../types'

import type {ProjectIdByInternalId} from './PipelinesSidebar.types'

export const pipelinesSidebar = combineReducers({
  projectIdByInternalId: createReducer<ProjectIdByInternalId>({}, builder => {
    builder.addMatcher(
      restApi.endpoints.getAllProjectsNormalized.matchFulfilled,
      (state, action) => {
        const {projects} = action.payload.entities ?? {}

        if (projects == null) {
          return
        }

        for (const [id, project] of Object.entries(projects)) {
          if (project?.internalId != null) {
            state[project.internalId] = toProjectId(id)
          }
        }
      },
    )
  }),
})
