import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {makeResource} from '../../../../utils/makeResource'

import type {EditFederationComponent} from './EditFederation'
import {query} from './EditFederation.queries'

export const EditFederationEntryPoint: EntryPoint<EditFederationComponent> = {
  root: makeResource(
    'EditFederation',
    () =>
      import(/* webpackChunkName: "EditFederation", webpackPrefetch: true */ './EditFederation'),
  ),
  getPreloadProps: () => ({
    queries: {
      main: {
        parameters: getRequest(query),
        variables: {},
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
    },
  }),
}
