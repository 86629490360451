import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import type {FetchArgs} from '@reduxjs/toolkit/src/query/fetchBaseQuery'

import processResponse from '../rest/processResponse'
import request, {getHeaders} from '../rest/request'

type RestFetchArgs = FetchArgs & {
  withAuth?: boolean
  withJsonError?: boolean
}

const baseQuery = ({responseHandler, withAuth = true}: RestFetchArgs) =>
  fetchBaseQuery({
    baseUrl: window.base_uri,
    credentials: 'include',
    prepareHeaders: headers => {
      if (responseHandler === 'text') {
        headers.set('Accept', 'text/plain')
      }
      return new Headers(getHeaders({headers}, withAuth))
    },
    fetchFn(input, init) {
      const options = typeof input === 'string' ? init : input
      const url = typeof input === 'string' ? input : input.url
      return request(url, null, options, withAuth)
    },
  })

export const restBaseApi = createApi({
  reducerPath: 'restApi',
  baseQuery: (arg: RestFetchArgs, ...args) =>
    baseQuery(arg)(
      {
        responseHandler: response => {
          const responseClone = response.clone()

          return processResponse(response)
            .then(
              data => data,
              () => (arg.withJsonError ? responseClone.json() : responseClone.text()),
            )
            .catch(error => error)
        },
        validateStatus: response => response.ok,
        ...arg,
      },
      ...args,
    ),
  tagTypes: ['Build', 'Agent', 'Pipeline', 'Overview', 'CleanupRules', 'CleanupPolicies'],
  endpoints: () => ({}),
  refetchOnMountOrArgChange: 5,
})
