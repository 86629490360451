import {graphql} from 'react-relay'

export const query = graphql`
  query BuildTypePageQuery($locator: String!) {
    buildType(btLocator: $locator) {
      project {
        internalId
      }
    }
  }
`
