import {createReducer, isAnyOf} from '@reduxjs/toolkit'

import {setError} from './HttpsConfigurationPage.actions'
import httpsConfigurationApi from './HttpsConfigurationPage.api'
import type {HttpsState} from './HttpsConfigurationPage.types'

const defaultState: HttpsState = {
  error: null,
}

const httpsConfigurationPageReducer = createReducer<HttpsState>(defaultState, builder => {
  builder.addCase(setError, (state, action) => {
    state.error = action.payload
  })
  builder.addMatcher(
    isAnyOf(
      httpsConfigurationApi.endpoints.fetchCertificateFromAcme.matchPending,
      httpsConfigurationApi.endpoints.triggerAcmeChallenge.matchPending,
      httpsConfigurationApi.endpoints.cancelAcmeChallenge.matchPending,
      httpsConfigurationApi.endpoints.uploadCertificate.matchPending,
    ),
    state => {
      state.error = null
    },
  )
  builder.addMatcher(
    isAnyOf(
      httpsConfigurationApi.endpoints.fetchCertificateFromAcme.matchRejected,
      httpsConfigurationApi.endpoints.triggerAcmeChallenge.matchRejected,
      httpsConfigurationApi.endpoints.cancelAcmeChallenge.matchRejected,
      httpsConfigurationApi.endpoints.getTermsOfService.matchRejected,
      httpsConfigurationApi.endpoints.uploadCertificate.matchRejected,
    ),
    (state, action) => {
      if (action.meta.condition) {
        // request was aborted due to condition (another query already running)
        return
      }
      state.error = action.payload as HttpsState['error']
    },
  )
})

export default httpsConfigurationPageReducer
