import {graphql} from 'react-relay'

export const queueActionsBuildsQuery = graphql`
  query QueueActionsBuildsQuery($locator: String!) {
    queuedBuilds(locator: $locator) {
      build {
        rawId
      }
    }
  }
`
