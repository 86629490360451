import type {GetFilesListForSubpathOfBuildApiArg} from '../services/rest'
import type {BuildId} from '../types'

export const getArtifactsArg = (
  buildId: BuildId,
  path: string,
  withHidden?: boolean,
): GetFilesListForSubpathOfBuildApiArg => ({
  buildLocator: `id:${buildId}`,
  path: path.split('/').map(encodeURIComponent).join('/'),
  fields: 'file(name,size)',
  locator: `hidden:${withHidden === true ? 'any' : 'false'}`,
})
