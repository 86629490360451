/**
 * @generated SignedSource<<6cc2553f23cfb4ec2edd0f29dfac58ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type pipelineHeadFragment$data = {
  readonly buildTypes: {
    readonly buildType: ReadonlyArray<{
      readonly internalId: string | null | undefined;
      readonly parameters: {
        readonly property: ReadonlyArray<{
          readonly name: string | null | undefined;
        }> | null | undefined;
      } | null | undefined;
      readonly rawId: string | null | undefined;
    }> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "pipelineHeadFragment";
};
export type pipelineHeadFragment$key = {
  readonly " $data"?: pipelineHeadFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"pipelineHeadFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "pipelineHeadFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BuildTypes",
      "kind": "LinkedField",
      "name": "buildTypes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BuildTypeRest",
          "kind": "LinkedField",
          "name": "buildType",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rawId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "internalId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Properties",
              "kind": "LinkedField",
              "name": "parameters",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Property",
                  "kind": "LinkedField",
                  "name": "property",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectRest",
  "abstractKey": null
};

(node as any).hash = "ec2cce527fc202f9362d7b231f640bab";

export default node;
