import {mapProps} from 'recompose'

import compose from 'lodash/flowRight'
import {connect} from 'react-redux'

import type {State} from '../../../reducers/types'
import {getBuildType} from '../../../selectors'
import type {BuildTypeId} from '../../../types'
import type {MiddleEllipsisProps} from '../MiddleEllipsis/MiddleEllipsis'
import MiddleEllipsis from '../MiddleEllipsis/MiddleEllipsis'

type OwnProps = MiddleEllipsisProps & {
  buildTypeId: BuildTypeId
}
type StateProps = {
  children: string | null | undefined
}
export default compose(
  connect(
    (state: State, {buildTypeId}: OwnProps): StateProps => ({
      children: getBuildType(state, buildTypeId)?.name,
    }),
    {},
  ),
  mapProps(({buildTypeId, ...restProps}: OwnProps & StateProps) => restProps),
)(MiddleEllipsis)
