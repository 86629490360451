import {graphql} from 'react-relay'

import type {BuildTypeId} from '../../../types'
import {parseBranch} from '../../../utils/branchNames'
import {getPermalinkLocator} from '../../../utils/permalinks'

import type {PermalinksQuery$variables} from './__generated__/PermalinksQuery.graphql'
import {pinnedLink, successfulLink, finishedLink} from './Permalinks.consts'

export const permalinksQuery = graphql`
  query PermalinksQuery(
    $pinnedLocator: String
    $successfulLocator: String
    $finishedLocator: String
  ) {
    pinned: builds(locator: $pinnedLocator) {
      count
    }
    successful: builds(locator: $successfulLocator) {
      count
    }
    finished: builds(locator: $finishedLocator) {
      count
    }
  }
`

export function getPermalinksQueryVariables(
  buildTypeId: BuildTypeId,
  branchString?: string | null,
): PermalinksQuery$variables {
  const branch = parseBranch(branchString)
  return {
    pinnedLocator: getPermalinkLocator(pinnedLink.type, buildTypeId, branch),
    successfulLocator: getPermalinkLocator(successfulLink.type, buildTypeId, branch),
    finishedLocator: getPermalinkLocator(finishedLink.type, buildTypeId, branch),
  }
}
