import Dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown'
import {Directions} from '@jetbrains/ring-ui/components/popup/popup.consts'
import classnames from 'classnames'
import memoize from 'memoize-one'
import * as React from 'react'

import Measure from '../../../containers/Measure'
import {stringifyId} from '../../../types'
import type {BuildTypeId, FullPath, PathItem, ProjectId} from '../../../types'
import {getSandbox} from '../../../utils/fastdom'
import {objectEntries} from '../../../utils/object'
import type {KeyValue, WritableKeyValue} from '../../../utils/object'
import Popup from '../Popup/Popup.lazy'

import type {Props} from './BuildPath.types'
import BuildPathLink from './BuildPathLink'

import styles from './BuildPath.css'

export const SEPARATOR = ' / '
const ELLIPSIS = '...'
type Data = {
  displayPath: FullPath
  filterPath: FullPath
  rootPath: FullPath
  collapsedPath: FullPath
  noOneFit: boolean
}
const emptyData: Data = {
  displayPath: [],
  filterPath: [],
  rootPath: [],
  collapsedPath: [],
  noOneFit: false,
}
export const getItemType = (item: PathItem): 'project' | 'buildType' =>
  'parentProjectId' in item ? 'project' : 'buildType'
export const getItemKey = (item: PathItem): string =>
  `${getItemType(item)}_${stringifyId(item.id)}_${item.name ?? ''}}`

const getItemProps = (item: PathItem) => {
  const idType = `${getItemType(item)}Id`
  return {
    [idType]: item.id,
    name: item.name ?? '',
    itemKey: getItemKey(item),
  }
}

type State = KeyValue<string, number>

class BuildPath extends React.PureComponent<Props, State> {
  state: State = {}
  linkElements: WritableKeyValue<string, HTMLElement | null> = {}
  separatorElement: HTMLElement | null = null
  ellipsisElement: HTMLElement | null = null
  prevData: Data = emptyData
  sandbox = getSandbox()
  calculateData: (
    path: FullPath,
    nodeType: 'all' | 'project' | 'bt' | 'template' | undefined,
    id: BuildTypeId | ProjectId | null | undefined,
    withCollapsing: boolean | undefined,
    calculatedWidth: number | null | undefined,
    hideFilterPath: boolean | null | undefined,
    hideIfSameAsFilter: boolean | null | undefined, // need to reset cache after measurements
    state: State,
  ) => Data = memoize(
    (
      path: FullPath,
      nodeType,
      id,
      withCollapsing,
      calculatedWidth,
      hideFilterPath,
      hideIfSameAsFilter,
    ) => {
      if (!path || path.length === 0) {
        return emptyData
      }

      let filteredPath = path
      let filterPath: FullPath = []

      if ((nodeType === 'bt' || hideIfSameAsFilter === true) && path[path.length - 1].id === id) {
        return emptyData
      }

      if (nodeType === 'project') {
        const filterIndex = path.slice(0, -1).findIndex(item => item.id === id)

        if (filterIndex > -1) {
          filteredPath = path.slice(filterIndex + 1)

          if (hideFilterPath !== true) {
            filterPath = path.slice(0, filterIndex + 1)
          }
        }
      }

      return calculatedWidth != null &&
        withCollapsing &&
        filteredPath.length > 1 &&
        this.separatorElement &&
        this.ellipsisElement
        ? this.calculatePathsWithCollapsing(filterPath, filteredPath, calculatedWidth)
        : {
            displayPath: filteredPath,
            rootPath: [],
            collapsedPath: [],
            filterPath,
            noOneFit: false,
          }
    },
  )

  componentDidMount() {
    this.measureAll()
  }

  componentDidUpdate(prevProps: Props) {
    const {className, path} = this.props

    if (className !== prevProps.className) {
      this.measureAll()
    } else if (path !== prevProps.path) {
      this.measureAll(true)
    }
  }

  componentWillUnmount() {
    this.sandbox.clear()
  }

  setSeparatorRef = (el: HTMLElement | null) => {
    this.separatorElement = el
  }

  setEllipsisRef = (el: HTMLElement | null) => {
    this.ellipsisElement = el
  }

  setLinkRef = (el: HTMLElement | null, key: string) => {
    this.linkElements[key] = el
  }

  measureAll(skipStaticElements = false) {
    this.sandbox.measure(() => {
      const measurements: WritableKeyValue<string, number> = {}

      function measure(key: string, element?: HTMLElement | null) {
        if (element != null) {
          measurements[key] = Math.ceil(element.getBoundingClientRect().width)
        }
      }

      if (!skipStaticElements) {
        measure('separator', this.separatorElement)
        measure('ellipsis', this.ellipsisElement)
      }

      for (const [key, element] of objectEntries(this.linkElements)) {
        measure(`link-${key}`, element)
      }

      this.sandbox.mutate(() => this.setState(measurements))
    })
  }

  getDerivedData(calculatedWidth: number | null | undefined): Data {
    const {
      path,
      projectOrBuildTypeNode,
      withCollapsing,
      hideFilterPath,
      hideIfSameAsFilter,
      isVisible = true,
    } = this.props

    if (isVisible) {
      const {nodeType, id} = projectOrBuildTypeNode ?? {}
      this.prevData = this.calculateData(
        path,
        nodeType,
        id,
        withCollapsing,
        calculatedWidth,
        hideFilterPath,
        hideIfSameAsFilter,
        this.state,
      )
    }

    return this.prevData
  }

  calculatePathsWithCollapsing(
    filterPath: FullPath,
    filteredPath: FullPath,
    calculatedWidth: number,
  ): {
    collapsedPath: FullPath
    displayPath: FullPath
    filterPath: FullPath
    noOneFit: boolean
    rootPath: FullPath
  } {
    const lastLink = filteredPath[filteredPath.length - 1]
    const displayPath = [lastLink]
    const rootPath = []
    let collapsedPath = []
    let noOneFit = false
    const separatorWidth = this.getElementWidth('separator')
    const ellipsisWidth = this.getElementWidth('ellipsis')
    let width = separatorWidth + this.getLinkWidth(lastLink)

    if (width + ellipsisWidth > calculatedWidth) {
      noOneFit = true
    }

    let onlyOneVisible = width >= calculatedWidth

    if (!onlyOneVisible) {
      const firstLink = filteredPath[0]
      width += separatorWidth + this.getLinkWidth(firstLink)

      if (filteredPath.length > 2) {
        width += ellipsisWidth + separatorWidth
      }

      if (width <= calculatedWidth) {
        rootPath.push(firstLink)

        for (let i = filteredPath.length - 2; i > 0; i--) {
          width += this.getLinkWidth(filteredPath[i])

          if (width < calculatedWidth) {
            displayPath.unshift(filteredPath[i])
          } else {
            width = calculatedWidth
            collapsedPath.unshift(filteredPath[i])
          }
        }
      } else {
        onlyOneVisible = true
      }
    }

    if (onlyOneVisible) {
      collapsedPath = filteredPath.slice(0, -1)
    }

    if (filterPath.length > 0 && collapsedPath.length > 0 && rootPath.length === 0) {
      return {
        filterPath: [],
        collapsedPath: filterPath.concat(collapsedPath),
        displayPath,
        rootPath,
        noOneFit,
      }
    }

    return {
      filterPath,
      rootPath,
      collapsedPath,
      displayPath,
      noOneFit,
    }
  }

  getLinkWidth(item: PathItem): number {
    const key = getItemKey(item)
    return this.getElementWidth(`link-${key}`)
  }

  getElementWidth(key: string): number {
    return this.state[key] ?? 0
  }

  renderPath(pathPart: FullPath, showTitle = false): React.ReactNode {
    const {
      withIcons,
      withLeafIcon,
      withLeafStatusIcon,
      linkClassName,
      buildId,
      path,
      secondary,
      showPausedInStatus,
      showInvestigationInStatus,
      withPopups,
    } = this.props
    const leafId = path[path.length - 1].id
    return pathPart.map<any>((item, i) => (
      <React.Fragment key={getItemKey(item)}>
        {i > 0 && <span className={styles.separator}>{SEPARATOR}</span>}
        <BuildPathLink
          {...getItemProps(item)}
          buildId={buildId}
          withIcon={withIcons === true || (withLeafIcon === true && item.id === leafId)}
          withStatusIcon={withLeafStatusIcon === true && item.id === leafId}
          secondary={secondary}
          showTitle={showTitle}
          showPausedInStatus={showPausedInStatus}
          showInvestigationInStatus={showInvestigationInStatus}
          className={linkClassName}
          withPopup={withPopups}
        />
      </React.Fragment>
    ))
  }

  renderHiddenPath(path: FullPath): React.ReactNode {
    return (
      <Dropdown
        className={styles.pathItemDropDown}
        anchor={
          <span className={styles.hiddenPathAnchor}>
            <span className={styles.ellipsis}>{ELLIPSIS}</span>
            <span className={styles.separator}>{SEPARATOR}</span>
          </span>
        }
        hoverShowTimeOut={300}
        hoverHideTimeOut={300}
        clickMode={false}
        hoverMode
      >
        <Popup left={-1} directions={[Directions.TOP_RIGHT, Directions.BOTTOM_RIGHT]}>
          <div className={styles.hiddenPathPopup}>{this.renderPath(path)}</div>
        </Popup>
      </Dropdown>
    )
  }

  renderInvisible(): React.ReactNode {
    const {path, buildId, withPopups} = this.props

    if (!path) {
      return null
    }

    return (
      <span className={styles.invisible}>
        {path.map(item => (
          <BuildPathLink
            {...getItemProps(item)}
            buildId={buildId}
            key={getItemKey(item)}
            setRef={this.setLinkRef}
            withPopup={withPopups}
          />
        ))}

        <span ref={this.setSeparatorRef} className={styles.separator}>
          {SEPARATOR}
        </span>

        <span ref={this.setEllipsisRef} className={styles.ellipsis}>
          {ELLIPSIS}
        </span>
      </span>
    )
  }

  renderVisible(calculatedWidth?: number | null): React.ReactNode {
    const {withCollapsing} = this.props
    const {displayPath, filterPath, rootPath, collapsedPath, noOneFit} =
      this.getDerivedData(calculatedWidth)
    const showPath = !withCollapsing || calculatedWidth != null
    return showPath ? (
      <React.Fragment>
        {filterPath.length > 0 && this.renderHiddenPath(filterPath)}
        {rootPath.length > 0 && (
          <React.Fragment>
            {this.renderPath(rootPath)}
            <span className={styles.separator}>{SEPARATOR}</span>
          </React.Fragment>
        )}
        {collapsedPath.length > 0 && this.renderHiddenPath(collapsedPath)}
        {displayPath.length > 0 && this.renderPath(displayPath, noOneFit)}
      </React.Fragment>
    ) : (
      <span>&nbsp;</span>
    )
  }

  render(): React.ReactNode {
    const {className, buildPathClassName, withCollapsing, path, loading} = this.props

    if (path == null) {
      return null
    }

    const containerClasses = classnames(styles.container, className, {
      [styles.loading]: loading,
    })
    const classes = classnames(styles.buildPath, buildPathClassName, {
      [styles.withCollapsing]: withCollapsing,
    })
    return (
      <div className={containerClasses}>
        {withCollapsing ? (
          <Measure bounds>
            {({measureRef, contentRect}) => (
              <div className={classes} ref={measureRef}>
                {this.renderVisible(contentRect.bounds?.width)}
                {this.renderInvisible()}
              </div>
            )}
          </Measure>
        ) : (
          <div className={classes}>{this.renderVisible()}</div>
        )}
      </div>
    )
  }
}

export default BuildPath
