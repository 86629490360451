import {castDraft} from 'immer'

import {createReducer} from '@reduxjs/toolkit'
import * as Redux from 'redux'

import {receiveBuildChanges} from '../../../../actions/builds'
import {toBuildId} from '../../../../types'
import type {BuildId, ChangeId, InexactCountable} from '../../../../types'
import type {KeyValue} from '../../../../utils/object'

const changesDropdown = Redux.combineReducers({
  buildChangesByBuildId: createReducer<KeyValue<BuildId, ReadonlyArray<ChangeId>>>({}, builder => {
    builder.addCase(receiveBuildChanges, (state, action) => {
      const {builds} = action.payload.entities

      if (builds == null) {
        return
      }

      for (const [buildId, build] of Object.entries(builds)) {
        state[toBuildId(buildId)] = castDraft(build?.changes?.change)
      }
    })
  }),

  artifactDependencyChangesCount: createReducer<KeyValue<BuildId, InexactCountable>>(
    {},
    builder => {
      builder.addCase(receiveBuildChanges, (state, action) => {
        const {builds} = action.payload.entities

        if (builds == null) {
          return
        }

        for (const [buildId, build] of Object.entries(builds)) {
          state[toBuildId(buildId)] = castDraft(build?.artifactDependencyChanges)
        }
      })
    },
  ),
})

export default changesDropdown
