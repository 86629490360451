import Theme from '@jetbrains/ring-ui/components/global/theme'
import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

export const initialTheme = createSlice({
  name: 'initialTheme',
  initialState: Theme.AUTO,
  reducers: {
    set: (_, action: PayloadAction<Theme>) => action.payload,
  },
})
