import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

import {ParameterCategory, ParameterGroup} from './Parameters.types'

export const parameterGroups = createSlice({
  name: 'parameters',
  initialState: {
    expanded: {
      [ParameterCategory.ACTUAL_ON_AGENT]: {
        [ParameterGroup.DEPENDENCIES]: true,
        [ParameterGroup.CONFIGURATION]: true,
        [ParameterGroup.SYSTEM]: true,
        [ParameterGroup.ENVIRONMENT]: true,
      },
      [ParameterCategory.ACTUAL]: {
        [ParameterGroup.DEPENDENCIES]: true,
        [ParameterGroup.CONFIGURATION]: true,
        [ParameterGroup.SYSTEM]: true,
        [ParameterGroup.ENVIRONMENT]: true,
      },
      [ParameterCategory.USER_DEFINED]: {
        [ParameterGroup.DEPENDENCIES]: true,
        [ParameterGroup.CONFIGURATION]: true,
        [ParameterGroup.SYSTEM]: true,
        [ParameterGroup.ENVIRONMENT]: true,
      },
      [ParameterCategory.AGENT]: {
        [ParameterGroup.DEPENDENCIES]: true,
        [ParameterGroup.CONFIGURATION]: true,
        [ParameterGroup.SYSTEM]: true,
        [ParameterGroup.ENVIRONMENT]: true,
      },
      [ParameterCategory.AGENT_TYPE]: {
        [ParameterGroup.DEPENDENCIES]: true,
        [ParameterGroup.CONFIGURATION]: true,
        [ParameterGroup.SYSTEM]: true,
        [ParameterGroup.ENVIRONMENT]: true,
      },
    },
  },
  reducers: {
    toggleGroup: (
      state,
      action: PayloadAction<{category: ParameterCategory; group: ParameterGroup}>,
    ) => {
      const {category, group} = action.payload
      state.expanded[category][group] = !state.expanded[category][group]
    },
    collapseAll: state => {
      state.expanded = {
        [ParameterCategory.ACTUAL]: {
          [ParameterGroup.DEPENDENCIES]: false,
          [ParameterGroup.CONFIGURATION]: false,
          [ParameterGroup.SYSTEM]: false,
          [ParameterGroup.ENVIRONMENT]: false,
        },
        [ParameterCategory.ACTUAL_ON_AGENT]: {
          [ParameterGroup.DEPENDENCIES]: false,
          [ParameterGroup.CONFIGURATION]: false,
          [ParameterGroup.SYSTEM]: false,
          [ParameterGroup.ENVIRONMENT]: false,
        },
        [ParameterCategory.USER_DEFINED]: {
          [ParameterGroup.DEPENDENCIES]: false,
          [ParameterGroup.CONFIGURATION]: false,
          [ParameterGroup.SYSTEM]: false,
          [ParameterGroup.ENVIRONMENT]: false,
        },
        [ParameterCategory.AGENT]: {
          [ParameterGroup.DEPENDENCIES]: false,
          [ParameterGroup.CONFIGURATION]: false,
          [ParameterGroup.SYSTEM]: false,
          [ParameterGroup.ENVIRONMENT]: false,
        },
        [ParameterCategory.AGENT_TYPE]: {
          [ParameterGroup.DEPENDENCIES]: false,
          [ParameterGroup.CONFIGURATION]: false,
          [ParameterGroup.SYSTEM]: false,
          [ParameterGroup.ENVIRONMENT]: false,
        },
      }
    },
    expandAll: state => {
      state.expanded = {
        [ParameterCategory.ACTUAL]: {
          [ParameterGroup.DEPENDENCIES]: true,
          [ParameterGroup.CONFIGURATION]: true,
          [ParameterGroup.SYSTEM]: true,
          [ParameterGroup.ENVIRONMENT]: true,
        },
        [ParameterCategory.ACTUAL_ON_AGENT]: {
          [ParameterGroup.DEPENDENCIES]: true,
          [ParameterGroup.CONFIGURATION]: true,
          [ParameterGroup.SYSTEM]: true,
          [ParameterGroup.ENVIRONMENT]: true,
        },
        [ParameterCategory.USER_DEFINED]: {
          [ParameterGroup.DEPENDENCIES]: true,
          [ParameterGroup.CONFIGURATION]: true,
          [ParameterGroup.SYSTEM]: true,
          [ParameterGroup.ENVIRONMENT]: true,
        },
        [ParameterCategory.AGENT]: {
          [ParameterGroup.DEPENDENCIES]: true,
          [ParameterGroup.CONFIGURATION]: true,
          [ParameterGroup.SYSTEM]: true,
          [ParameterGroup.ENVIRONMENT]: true,
        },
        [ParameterCategory.AGENT_TYPE]: {
          [ParameterGroup.DEPENDENCIES]: true,
          [ParameterGroup.CONFIGURATION]: true,
          [ParameterGroup.SYSTEM]: true,
          [ParameterGroup.ENVIRONMENT]: true,
        },
      }
    },
  },
})
