import type {GraphQLTaggedNode} from 'react-relay'
import {graphql} from 'react-relay'
import {getRequest} from 'relay-runtime'

import type {AgentsPagesAgentTypesQuery} from './__generated__/AgentsPagesAgentTypesQuery.graphql'

export const poolsAuthorizePermissionsQuery: GraphQLTaggedNode = graphql`
  query AgentsPagesPoolsAuthorizePermissionsQuery {
    agentPools {
      edges {
        node {
          id
          rawId
          name
          permissions {
            authorizeAgents
            manageAgents
          }
        }
      }
    }
  }
`

export const poolAuthorizePermissionsQuery: GraphQLTaggedNode = graphql`
  query AgentsPagesPoolAuthorizePermissionsQuery($agentPoolId: ID!) {
    agentPool(id: $agentPoolId) {
      id
      permissions {
        authorizeAgents
        manageAgents
      }
    }
  }
`

export const agentTypesQuery = graphql`
  query AgentsPagesAgentTypesQuery {
    agentPoolsRest {
      agentPool {
        id
        rawId
        name
        agentTypes {
          agentType {
            rawId
            name
            isCloud
            environment {
              osType
              osName
            }
          }
        }
      }
    }
  }
`

export const NativeAgentTypesQuery = graphql`
  query AgentsPagesNativeAgentTypesQuery($skip: Boolean = true) {
    agentTypes @skip(if: $skip) {
      edges {
        node {
          rawId
          name
          isCloud
          agentPool {
            rawId
            name
          }
          cloudImage {
            id
            name
          }
          environment {
            os {
              type
            }
          }
        }
      }
    }
  }
`

export const agentsPagesQueries = {
  agentTypes: {
    parameters: getRequest(agentTypesQuery),
    variables: {},
    options: {networkCacheConfig: {metadata: {essential: true}}},
  },
}

export type AgentsPagesQueries = {
  agentTypes: AgentsPagesAgentTypesQuery
}
