import {usePreloadedQuery} from 'react-relay'
import type {PreloadedQuery} from 'react-relay/relay-hooks/EntryPointTypes'

import {restApi} from '../../../../services/rest'
import {getPropertyFromList} from '../../../../utils/getProperty'

import type {BuildArtifactStorageInfoFeatureIdQuery} from './__generated__/BuildArtifactStorageInfoFeatureIdQuery.graphql'
import {featureIdQuery} from './BuildArtifactStorageInfo.queries'

const STORAGE_FEATURE_ID = 'teamcity.storage.feature.id'

export function useArtifactStorage(
  featureIdQueryRef: PreloadedQuery<BuildArtifactStorageInfoFeatureIdQuery>,
) {
  const {build} = usePreloadedQuery(featureIdQuery, featureIdQueryRef)
  const featureId = getPropertyFromList(STORAGE_FEATURE_ID, build?.resultingProperties)

  return restApi.endpoints.getFeature.useQuery(
    {
      projectLocator: `projectFeature:id:${featureId}`,
      featureLocator: `id:${featureId}`,
      fields: 'properties(property(name,value))',
    },
    {
      skip: featureId == null,
      selectFromResult: ({data}) => ({
        name: getPropertyFromList('storage.name', data?.properties) ?? '',
        type: getPropertyFromList('storage.type', data?.properties) ?? '',
      }),
    },
  )
}
