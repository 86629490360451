import type {Branch} from '../../../../services/rest'

export const Modes = {
  BRANCHES: 'branches',
  BUILDS: 'builds',
}

export const defaultMode = Modes.BUILDS

export const isForbiddenCombo = (
  branch: Branch | null | undefined,
  mode: string | null | undefined,
): boolean => mode === Modes.BRANCHES && branch != null && !branch.groupFlag
