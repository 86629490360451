import type {PopupAttrs} from '@jetbrains/ring-ui/components/popup/popup'
import * as React from 'react'
import type {ConnectedProps} from 'react-redux'
import {connect} from 'react-redux'

import RunningBuildUpdater from '../../../containers/RunningBuildUpdater'
import type {State} from '../../../reducers/types'
import {getBuild} from '../../../selectors'
import type {BuildId} from '../../../types'

import BuildDuration from './BuildDuration'

type Props = {
  buildId: BuildId
  subscribe?: boolean
  children?: React.ReactNode
  popupProps?: PopupAttrs
  className?: string
  exact?: boolean
}

const mapStateToProps = (state: State, props: Props) => {
  const build = getBuild(state, props.buildId)
  return {
    elapsed: build?.id,
    started: build?.startDate,
    finished: build?.finishDate,
  }
}

const connector = connect(mapStateToProps)

function DurationContainer(props: Props & ConnectedProps<typeof connector>) {
  const {subscribe, ...restProps} = props
  return (
    <React.Fragment>
      {subscribe === true && <RunningBuildUpdater buildId={props.buildId} />}
      <BuildDuration {...restProps} />
    </React.Fragment>
  )
}

export default connector(DurationContainer)
