import {graphql} from 'react-relay'

import {getCountersLocator} from './BuildTestsTab.utils'

export const countsQuery = graphql`
  query BuildTestsTabCountsQuery($locator: String!) {
    testOccurrences(locator: $locator) {
      testCounters {
        ...TestScopesViewCountsFragment
        ...TestsFilterCountsFragment
      }
    }
  }
`

export const getCountsVariables = (buildLocator: string) => ({
  locator: getCountersLocator(buildLocator),
})
