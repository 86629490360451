import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {getBuildLocator} from '../../../../../rest/locators'
import type {BuildId} from '../../../../../types'
import {makeResource} from '../../../../../utils/makeResource'
import {
  getCountsVariables,
  testsPreviewPanelCountsQuery,
} from '../../../../packages/Tests/TestsPreviewPanel/TestsPreviewPanel.queries'

import type {DetailsContentType} from './BuildDetailsContent'

export const BuildDetailsContentEntryPoint: EntryPoint<DetailsContentType, BuildId> = {
  root: makeResource(
    'BuildDetailsContent',
    () =>
      import(
        /* webpackChunkName: "BuildDetailsContent", webpackPrefetch: true */ './BuildDetailsContent'
      ),
  ),
  getPreloadProps: buildId => ({
    queries: {
      testCounts: {
        parameters: getRequest(testsPreviewPanelCountsQuery),
        variables: getCountsVariables(getBuildLocator(buildId)),
      },
    },
  }),
}
