import {connect} from 'react-redux'

import type {State} from '../../../reducers/types'
import {getBuild, getHasArtifacts} from '../../../selectors'
import {resolveWebEntityLink, WebEntityType} from '../../../utils/entityLinks'

import type {OwnProps, StateProps} from './BuildArtifactsTree.types'

const mapStateToProps = (state: State, {buildId, level = 0}: OwnProps): StateProps => {
  const build = getBuild(state, buildId)
  const hasArtifacts = getHasArtifacts(state, buildId)
  return {
    level,
    buildUrl: resolveWebEntityLink(WebEntityType.BUILD, buildId),
    buildType: build?.buildType,
    hasArtifacts,
  }
}

export default connect<StateProps, {}, OwnProps, State>(mapStateToProps)
