import {createReducer, isAnyOf} from '@reduxjs/toolkit'
import * as Redux from 'redux'

import {restApi} from '../../../../../services/rest'
import type {KeyValue} from '../../../../../utils/object'

import type {ErrorAnswer} from './Rules.types'
import {getKey} from './Rules.utils'

const errorReducer = createReducer<ErrorAnswer | null | undefined>(null, builder => {
  builder.addMatcher(
    isAnyOf(
      restApi.endpoints.getCleanupRules.matchPending,
      restApi.endpoints.getCleanupRules.matchFulfilled,
      restApi.endpoints.deleteCleanupRule.matchPending,
      restApi.endpoints.deleteCleanupRule.matchFulfilled,
      restApi.endpoints.createCleanupRule.matchPending,
      restApi.endpoints.createCleanupRule.matchFulfilled,
      restApi.endpoints.updateCleanupRule.matchPending,
      restApi.endpoints.updateCleanupRule.matchFulfilled,
    ),
    () => null,
  )

  builder.addMatcher(
    isAnyOf(
      restApi.endpoints.getCleanupRules.matchRejected,
      restApi.endpoints.deleteCleanupRule.matchRejected,
      restApi.endpoints.createCleanupRule.matchRejected,
      restApi.endpoints.updateCleanupRule.matchRejected,
    ),
    (state, action) => {
      if (action.meta.condition) {
        // request was aborted due to condition (another query already running)
        return null
      }
      return 'data' in action.error ? (action.error.data as ErrorAnswer) : null
    },
  )
})

const isAnyActionPerformingReducer = createReducer<KeyValue<string, boolean>>({}, builder => {
  builder.addMatcher(
    isAnyOf(
      restApi.endpoints.getCleanupRules.matchPending,
      restApi.endpoints.deleteCleanupRule.matchPending,
      restApi.endpoints.createCleanupRule.matchPending,
      restApi.endpoints.updateCleanupRule.matchPending,
    ),
    (state, action) => {
      state[getKey(action.meta.arg.originalArgs.holderNode)] = true
    },
  )
  builder.addMatcher(
    isAnyOf(
      restApi.endpoints.getCleanupRules.matchFulfilled,
      restApi.endpoints.getCleanupRules.matchRejected,
      restApi.endpoints.deleteCleanupRule.matchFulfilled,
      restApi.endpoints.deleteCleanupRule.matchRejected,
      restApi.endpoints.createCleanupRule.matchFulfilled,
      restApi.endpoints.createCleanupRule.matchRejected,
      restApi.endpoints.updateCleanupRule.matchFulfilled,
      restApi.endpoints.updateCleanupRule.matchRejected,
    ),
    (state, action) => {
      state[getKey(action.meta.arg.originalArgs.holderNode)] = false
    },
  )
})
const cleanupReducer = Redux.combineReducers({
  errors: errorReducer,
  isAnyActionPerforming: isAnyActionPerformingReducer,
})
export default cleanupReducer
