import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline'
import {skipToken} from '@reduxjs/toolkit/query'
import classNames from 'classnames'
import * as React from 'react'
import {Suspense} from 'react'
import {shallowEqual} from 'react-redux'

import {useAppSelector} from '../../../hooks/react-redux'
import {useBooleanQueryParamState} from '../../../hooks/routes'
import {getArtifactsArg} from '../../../rest/artifacts'
import {getExtensionEndpointsByKind} from '../../../selectors'
import {buildArtifactsApi} from '../../../services/buildArtifacts'
import {restApi} from '../../../services/rest'
import {stringifyId} from '../../../types'
import {focusSelfOrChildLink} from '../../../utils/dom'
import {emptyArray} from '../../../utils/empty'
import {useSandbox} from '../../../utils/fastdom'
import {getChildPath, getType, isOnlyFolder} from '../../../utils/fileTree'
import {escapeFilePathForURL} from '../../../utils/url'
import Link from '../Link/Link'

import ArtifactsDownloadAll from './ArtifactsDownloadAll/ArtifactsDownloadAll'
import BuildArtifactStorageInfo from './BuildArtifactStorageInfo/BuildArtifactStorageInfo'
import connect from './BuildArtifactsTree.container'
import type {Props} from './BuildArtifactsTree.types'
import {
  getArtifactsHref,
  getLeftIndent,
  isHiddenArtifact,
  isParent,
} from './BuildArtifactsTree.utils'
import FileTreeNode, {ITEM_SELECTOR, MIN_OFFSET, STEP} from './FileTreeNode/FileTreeNode'

import styles from './BuildArtifactsTree.css'

const ConnectedTree = connect(function BuildArtifactsTree({
  buildId,
  buildUrl,
  path = '',
  buildType,
  level,
  labelledBy,
  showToggleHidden,
  showDownloadLink,
  showStorageInfo = false,
  storageFeatureIdQueryRef,
  hasArtifacts,
  canSelectDirs,
  autoWidth,
  compact = true,
  expandedNodes,
  timeStamp,
  onSelect,
  onExpand,
  urlSync = false,
}: Props) {
  const showHiddenStateReact = React.useState(false)
  const showHiddenStateUrl = useBooleanQueryParamState('showAll')
  const [showHidden, setShowHidden] = urlSync ? showHiddenStateUrl : showHiddenStateReact
  const expandedNodeArray = React.useRef(
    urlSync ? location.hash.slice(1).split(';').map(decodeURIComponent) : expandedNodes,
  )
  const handleExpand = React.useCallback(
    (pathToExpand: string, expanded: boolean | null | undefined) => {
      if (urlSync) {
        const currentExpandedNodeArray = expanded
          ? // remove parent paths to avoid duplication
            expandedNodeArray.current
              ?.filter(node => !isParent(node, pathToExpand))
              .concat(pathToExpand)
          : // remove path and its children
            expandedNodeArray.current?.filter(
              node => node !== pathToExpand && !isParent(pathToExpand, node),
            )
        const isExpandedNodeEqual =
          currentExpandedNodeArray?.join('') === expandedNodeArray.current?.join('')

        if (!isExpandedNodeEqual) {
          expandedNodeArray.current = currentExpandedNodeArray

          window.location.hash = currentExpandedNodeArray?.map(encodeURIComponent).join(';') ?? ''
        }
      } else if (onExpand != null) {
        onExpand(pathToExpand, expanded)
      }
    },
    [onExpand, urlSync],
  )
  const handleToggleHidden = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.stopPropagation()
      setShowHidden(!showHidden)
    },
    [setShowHidden, showHidden],
  )
  const ref = React.useRef<HTMLUListElement>(null)
  const hiddenRef = React.useRef()
  const isRoot = level === 0
  const {loading, files} = restApi.endpoints.getFilesListForSubpathOfBuild.useQuery(
    getArtifactsArg(buildId, path, isRoot),
    {selectFromResult: ({data}) => ({loading: data == null, files: data?.file ?? emptyArray})},
  )
  const {artifactsSize} = buildArtifactsApi.endpoints.getArtifactsSize.useQuery(
    isRoot ? {buildId, showAll: showHidden} : skipToken,
    {selectFromResult: ({data}) => ({artifactsSize: data?.size})},
  )
  const shouldFocusFirst = isRoot && files.length > 0
  const sandbox = useSandbox()
  React.useEffect(() => {
    if (shouldFocusFirst) {
      const element = ref.current

      if (element == null) {
        return
      }

      const firstItem = element.querySelector(ITEM_SELECTOR)
      sandbox.mutate(() => {
        const {documentElement} = document
        const prevScroll = documentElement?.scrollTop
        focusSelfOrChildLink(firstItem)

        if (documentElement && prevScroll != null) {
          documentElement.scrollTop = prevScroll
        }
      })
    }
  }, [sandbox, shouldFocusFirst])
  React.useEffect(() => {
    if (showHidden) {
      focusSelfOrChildLink(hiddenRef.current)
    }
  }, [showHidden])
  const onlyFolder = isOnlyFolder(files)
  const noUserArtifacts = hasArtifacts === false
  const userArtifacts = files.filter(file => !isHiddenArtifact(file))
  const hasHiddenArtifacts = files.some(isHiddenArtifact)
  const filesToRender = showHidden ? files : userArtifacts
  const artifactsHref = getArtifactsHref(buildUrl, true)
  const extensions = useAppSelector(
    state => getExtensionEndpointsByKind(state, 'artifacts'),
    shallowEqual,
  )
  const leftLevelIndentStyles = React.useMemo(() => getLeftIndent(level), [level])

  return (
    <div className={styles.container}>
      {loading ? (
        !noUserArtifacts && (
          <div className={styles.loader} style={{paddingLeft: MIN_OFFSET + level * STEP}}>
            <LoaderInline />
            {' Loading files...'}
          </div>
        )
      ) : (
        <>
          {isRoot && (
            <div className={styles.header}>
              <div>
                {(noUserArtifacts || (hasHiddenArtifacts && userArtifacts.length === 0)) && (
                  <div className={styles.noArtifacts}>
                    <div>{'No user-defined artifacts in this build. '}</div>
                  </div>
                )}
                {artifactsSize != null && (
                  <div className={styles.artifactsSize}>{`Total size: ${artifactsSize}`}</div>
                )}
                {showStorageInfo && storageFeatureIdQueryRef && (
                  <Suspense>
                    <BuildArtifactStorageInfo
                      buildId={buildId}
                      storageFeatureIdQueryRef={storageFeatureIdQueryRef}
                    />
                  </Suspense>
                )}
                {hasHiddenArtifacts && (
                  <div className={styles.noteHidden} style={leftLevelIndentStyles}>
                    {showHidden
                      ? 'Hidden artifacts from the .teamcity directory are displayed '
                      : null}
                    {(showToggleHidden || userArtifacts.length === 0) && artifactsHref != null && (
                      <Link
                        href={artifactsHref}
                        className={styles.toggleHidden}
                        onPlainLeftClick={handleToggleHidden}
                        style={leftLevelIndentStyles}
                      >
                        {showHidden ? 'Hide' : 'Show hidden artifacts'}
                      </Link>
                    )}
                  </div>
                )}
              </div>
              {showDownloadLink && filesToRender.length > 0 && (
                <ArtifactsDownloadAll
                  buildId={buildId}
                  buildType={buildType}
                  showHidden={showHidden}
                  className={styles.downloadLink}
                />
              )}
            </div>
          )}
          {filesToRender.length > 0 && (
            <ul
              className={classNames(styles.tree, {[styles.autoWidth]: autoWidth})}
              role={isRoot ? 'tree' : 'group'}
              aria-labelledby={labelledBy}
              ref={ref}
            >
              {filesToRender.map(file => {
                const {name, size} = file
                const type = getType(file)
                const childPath = getChildPath(path, name)
                const encodedChildPath = escapeFilePathForURL(childPath)
                const defaultExpanded =
                  onlyFolder ||
                  expandedNodeArray.current?.some(
                    node => node === childPath || node.startsWith(getChildPath(childPath, '')),
                  )
                return (
                  <FileTreeNode
                    key={name}
                    name={name}
                    expandable={type !== 'file'}
                    defaultExpanded={defaultExpanded}
                    path={childPath}
                    type={type}
                    icon={type}
                    size={size}
                    level={level}
                    href={
                      type === 'folder'
                        ? undefined
                        : `/repository/download/${stringifyId(buildType)}/${stringifyId(
                            buildId,
                          )}:id${encodedChildPath}`
                    }
                    extensions={extensions}
                    buildId={buildId}
                    itemRef={isHiddenArtifact(file) ? hiddenRef : null}
                    compact={compact}
                    onSelect={canSelectDirs === true || type === 'file' ? onSelect : null}
                    onExpand={handleExpand}
                  >
                    {type !== 'file' && (
                      <ConnectedTree
                        buildId={buildId}
                        path={childPath}
                        level={level + 1}
                        canSelectDirs={canSelectDirs}
                        expandedNodes={expandedNodeArray.current}
                        timeStamp={timeStamp}
                        compact={compact}
                        onSelect={onSelect}
                        onExpand={handleExpand}
                      />
                    )}
                  </FileTreeNode>
                )
              })}
            </ul>
          )}
        </>
      )}
    </div>
  )
})
export default ConnectedTree
