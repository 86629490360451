import {defaultValidationError} from './validators.consts'
import type {
  ValidationErrorMessage,
  GetValidatorErrorMessageProps,
  GetValidationErrorMessageProps,
} from './validators.types'

export const isValueNotEmptyMessage = ({value}: GetValidatorErrorMessageProps) => Boolean(value)

export const isValueObjectNotDuplicate = ({
  compareObject = {},
  value,
}: GetValidatorErrorMessageProps) => Boolean(value && compareObject && !(value in compareObject))

export const isValueArrayNotDuplicate = ({
  compareArray = [],
  value,
}: GetValidatorErrorMessageProps) =>
  !compareArray?.length || Boolean(value && compareArray?.length && !compareArray.includes(value))

export const isValueNotLong =
  (maxLength: number) =>
  ({value}: GetValidatorErrorMessageProps) =>
    Boolean(value && value.length < maxLength)

export const isValueNotValidSymbol =
  (regExp: RegExp) =>
  ({value = ''}: GetValidatorErrorMessageProps) =>
    regExp.test(value)

export const getValidationErrorMessage = (
  {validators, ...props}: GetValidationErrorMessageProps,
  spotId?: string,
): ValidationErrorMessage => {
  for (const {validator, message} of validators) {
    const isValid = validator(props)

    if (!isValid) {
      return {id: spotId, message}
    }
  }

  return defaultValidationError
}
