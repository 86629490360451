export enum GlobalSearchKind {
  SIDEBAR = 'Sidebar',
  PARAMETERS = 'Parameters',
  DEPENDENCIES_SEARCH = 'DEPENDENCIES_SEARCH',
}

interface GlobarSearchState {
  count: number
}

export interface ShortcutState {
  globalSearch: Record<GlobalSearchKind, GlobarSearchState>
}
