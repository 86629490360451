import {useEffect, useCallback} from 'react'

import {setSyncStorageValue} from '../actions'
import type {State} from '../reducers/types'
import {safeLocalStorage} from '../utils/safeStorages'

import {useAppDispatch, useAppSelector} from 'src/hooks/react-redux'

export enum SyncStorageKey {
  COMPARE_BUILD_SOURCE = 'jb.teamcity.sync.compare_build_source',
}
export default function useStorageSyncValue(
  key: SyncStorageKey,
): [string | null, (newValue: string | null) => unknown] {
  const value: string | null = useAppSelector(
    (state: State) => state.syncStorageValues[key] ?? null,
  )
  const dispatch = useAppDispatch()
  const setValue = useCallback(
    (newValue: string | null) => {
      dispatch(setSyncStorageValue(key, newValue))

      if (newValue != null) {
        safeLocalStorage.setItem(key, newValue)
      } else {
        safeLocalStorage.removeItem(key)
      }
    },
    [dispatch, key],
  )
  useEffect(() => {
    const onStorageChange = (event: StorageEvent) => {
      if (event.key === key) {
        dispatch(setSyncStorageValue(key, event.newValue))
      }
    }

    window.addEventListener('storage', onStorageChange, false)
    return () => window.removeEventListener('storage', onStorageChange)
  }, [key, dispatch])
  return [value, setValue]
}
