import {restBaseApi} from '../../../services/restBase'

import type {InvestigationHistory} from './InvestigationHistory.types'

export const investigationHistoryApi = restBaseApi.injectEndpoints({
  endpoints: build => ({
    get: build.query<readonly InvestigationHistory[], string>({
      query: search => ({url: `viewInvestigationHistory.html?${search}`}),
    }),
  }),
})
