import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline'
import * as React from 'react'
import {Suspense} from 'react'
import {graphql, useLazyLoadQuery} from 'react-relay'

import {ApprovalStatuses} from '../../../../services/rest'
import type {BuildId} from '../../../../types'
import {useCheckIfUserCanViewApprovalInfo} from '../BuildApproval.hooks'
import BuildApprovalReviewers from '../BuildApprovalReviewers'

import type {BuildApprovalInlineQuery} from './__generated__/BuildApprovalInlineQuery.graphql'
import ApprovalControls from './BuildApprovalControls/BuildApprovalControls'

const query = graphql`
  query BuildApprovalInlineQuery($locator: String!) {
    approvalInfo(buildLocator: $locator) {
      status
      ...BuildApprovalReviewers_canViewApprovalInfo
      ...BuildApprovalReviewers_approvalInfo
      ...BuildApprovalControls_approvalInfo
    }
  }
`

type Props = {
  buildId: BuildId
}

function BuildApprovalInline({buildId}: Props) {
  const {approvalInfo = null} = useLazyLoadQuery<BuildApprovalInlineQuery>(query, {
    locator: `id:${buildId}`,
  })
  const title = approvalInfo?.status === ApprovalStatuses.APPROVED ? 'Approved' : 'Approval'
  const canViewApprovalInfo = useCheckIfUserCanViewApprovalInfo(approvalInfo)

  return (
    <>
      <tr>
        <th>{title}</th>
        <td>
          {canViewApprovalInfo ? (
            <BuildApprovalReviewers approvalInfoKey={approvalInfo} />
          ) : (
            <ApprovalControls approvalInfoKey={approvalInfo} buildId={buildId} />
          )}
        </td>
      </tr>
      <tr>
        <td />
        <td>
          {canViewApprovalInfo ? (
            <ApprovalControls approvalInfoKey={approvalInfo} buildId={buildId} />
          ) : null}
        </td>
      </tr>
    </>
  )
}

export default React.memo(function BuildApprovalContainer(props: Props) {
  return (
    <Suspense
      fallback={
        <tr>
          <th>{'Approval'}</th>
          <td>
            <span>
              {'Loading '}
              <LoaderInline />
            </span>
          </td>
        </tr>
      }
    >
      <BuildApprovalInline {...props} />
    </Suspense>
  )
})
