import Button from '@jetbrains/ring-ui/components/button/button'
import Dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown'
import type {ReactElement} from 'react'
import {memo, Suspense, useEffect} from 'react'
import {EntryPointContainer} from 'react-relay'

import {useEntryPointLoaderWithEnvironment} from '../../../hooks/relay/useEntryPointLoaderWithEnvironment'
import {getBuildType, getProject} from '../../../selectors'
import type {BuildTypeId, ProjectId} from '../../../types'
import {getProjectBuildTypeFilter} from '../../../types'
import {resolveRelative} from '../../../utils/url'
import Popup from '../Popup/Popup.lazy'
import {ProjectsPopupEntryPoint} from '../ProjectsPopup/ProjectsPopup.entryPoint'

import styles from './BreadcrumbsDropdown.css'

import {useAppSelector} from 'src/hooks/react-redux'

type Props = {
  buildTypeId?: BuildTypeId
  projectId?: ProjectId
  icon: ReactElement
}

function BreadcrumbsDropdown({buildTypeId, projectId, icon}: Props) {
  const parentId = useAppSelector(state =>
    buildTypeId != null
      ? getBuildType(state, buildTypeId)?.projectId
      : getProject(state, projectId)?.parentProjectId,
  )
  const activeItem = getProjectBuildTypeFilter({buildTypeId, projectId})
  const [entryPoint, loadEntryPoint, disposeEntryPoint] =
    useEntryPointLoaderWithEnvironment(ProjectsPopupEntryPoint)
  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      // load entrypoint for storyshots
      loadEntryPoint(true)
    }
  }, [loadEntryPoint])

  return (
    <Dropdown
      anchor={<Button className={styles.popupIcon} title="View siblings" icon={() => icon} />}
      onShow={() => loadEntryPoint(true)}
      onHide={disposeEntryPoint}
    >
      <Popup>
        {entryPoint != null && (
          <Suspense>
            <EntryPointContainer
              entryPointReference={entryPoint}
              props={{
                parentProjectId: parentId,
                activeItem: activeItem && {
                  ...activeItem,
                  group: 'search',
                },
                buildTypeUrlFormat: resolveRelative('/buildConfiguration/{id}'),
                projectUrlFormat: resolveRelative('/project/{id}'),
              }}
            />
          </Suspense>
        )}
      </Popup>
    </Dropdown>
  )
}
export default memo(BreadcrumbsDropdown)
