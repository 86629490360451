export const pinnedLink = {
  title: 'pinned',
  type: 'lastPinned',
}
export const successfulLink = {
  title: 'successful',
  type: 'lastSuccessful',
}
export const finishedLink = {
  title: 'finished',
  type: 'lastFinished',
}
