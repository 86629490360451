import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

export const toolPanelFooter = createSlice({
  name: 'toolPanelFooter',
  initialState: {
    height: 0,
    isOpen: false,
  },
  reducers: {
    open(state, action: PayloadAction<number>) {
      state.isOpen = true
      state.height = action.payload
    },
    close(state) {
      state.isOpen = false
      state.height = 0
    },
    resize(state, action: PayloadAction<number>) {
      state.height = action.payload
    },
  },
})
