import type {TestOccurrence} from '../../../services/rest'
import type {BuildTypeInternalId, TestId, TestOccurrenceId, TestsTreeNodeId} from '../../../types'

export enum TestStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  UNKNOWN = 'UNKNOWN',
}

export enum OrderByField {
  STATUS = 'status',
  NAME = 'name',
  DURATION = 'duration',
  RUN_ORDER = 'runOrder',
  NEW_FAILURE = 'newFailure',
  COUNT = 'count',
  SUCCESS = 'success', // Sorting by these fields is not supported yet.
  FAILURE = 'failure', // ...
  MUTED = 'muted', // ...
  IGNORED = 'ignored', // ...
  BUILD_START_DATE = 'buildStartDate',
}

export enum OrderDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum TestScopesKey {
  SUITE = 'suite',
  PACKAGE = 'package',
  CLASS = 'class',
}

export enum TreeEntityType {
  PROJECT = 'PROJECT',
  BUILD_TYPE = 'BUILD_TYPE',
  BUILD = 'BUILD',
  SUITE = 'SUITE',
  PACKAGE = 'PACKAGE',
  CLASS = 'CLASS',
}

export type RequestTestOccurrenceOptionsParams = {
  withNewFailure?: boolean
  withMetadataCount?: boolean
  withUpdatePager?: boolean
  withNextFixed?: boolean
  withFirstFailed?: boolean
  withRunOrder?: boolean
  withMuteInfo?: boolean
  withInvocationsCounters?: boolean
  withInvestigationInfo?: boolean
  withBuildInfo?: boolean
}
export type TestOccurrencesCounts = {
  all?: number
  failed?: number
  success?: number
  muted?: number
  ignored?: number
  newFailed?: number
  duration?: number
}
type BaseTestOccurrences<T> = {
  testCounters?: TestOccurrencesCounts
  testOccurrence?: ReadonlyArray<T>
}
type TestOccurrences = BaseTestOccurrences<TestOccurrence>
type NormalizedTestOccurrences = BaseTestOccurrences<TestOccurrenceId>
export type TestOccurrenceInvocations = {
  id: TestOccurrenceId
  invocations?: NormalizedTestOccurrences
}
type BaseTestOccurrencesTreeLeaf<T> = {
  nodeId: TestsTreeNodeId
  testOccurrences?: T
}
export type TestOccurrencesTreeLeaf = BaseTestOccurrencesTreeLeaf<TestOccurrences>
export type TestOccurrencesTreeNode = {
  id: TestsTreeNodeId
  parentId?: TestsTreeNodeId
  name: string
  childrenCount?: number
  testCounters?: TestOccurrencesCounts
  type: TreeEntityType
}
type BaseTestOccurrencesTree<T> = {
  leaf?: ReadonlyArray<BaseTestOccurrencesTreeLeaf<T>>
  node?: ReadonlyArray<TestOccurrencesTreeNode>
}
export type TestOccurrencesTree = BaseTestOccurrencesTree<TestOccurrences>
export type TestScopeType = {
  suite?: string
  package?: string
  class?: string
  testOccurrences?: TestOccurrences
}
export type TestScope = {
  suite: string | null | undefined
  package: string | null | undefined
  class: string | null | undefined
}
export type TestFlaky = {
  id: TestId
  isFlaky: boolean
  title?: string
  descriptions?: ReadonlyArray<string>
}
export type RequestFlakyTests = ReadonlyArray<{
  testName: TestId | null | undefined
  buildTypeId: BuildTypeInternalId | null | undefined
}>
