/**
 * @generated SignedSource<<2b6e780da3edb45ecab358ecdb0cde3f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuildApprovalDetailsPopup_anchor$data = {
  readonly status: string | null | undefined;
  readonly " $fragmentType": "BuildApprovalDetailsPopup_anchor";
};
export type BuildApprovalDetailsPopup_anchor$key = {
  readonly " $data"?: BuildApprovalDetailsPopup_anchor$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuildApprovalDetailsPopup_anchor">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuildApprovalDetailsPopup_anchor",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "ApprovalInfo",
  "abstractKey": null
};

(node as any).hash = "e45f53aa479e3e8f4ebe814b751c846c";

export default node;
