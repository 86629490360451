import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

export const settings = createSlice({
  name: 'buildLog.settings',
  initialState: {
    softWrapLines: false,
    relativeTime: false,
  },
  reducers: {
    setSoftWrapLines(state, action: PayloadAction<boolean>) {
      state.softWrapLines = action.payload
    },
    setRelativeTime(state, action: PayloadAction<boolean>) {
      state.relativeTime = action.payload
    },
  },
})
