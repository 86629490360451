import {graphql} from 'react-relay'

export const featureIdQuery = graphql`
  query BuildArtifactStorageInfoFeatureIdQuery($buildLocator: String!) {
    build(buildLocator: $buildLocator) {
      resultingProperties(locator: "name:teamcity.storage.feature.id") {
        property {
          name
          value
        }
      }
    }
  }
`
