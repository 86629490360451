/**
 * @generated SignedSource<<22516c9809efa8439430a6544baf2189>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TagsListBuildTypeTagsQuery$variables = {
  btLocator: string;
};
export type TagsListBuildTypeTagsQuery$data = {
  readonly buildTypeBuildTags: {
    readonly tag: ReadonlyArray<{
      readonly name: string | null | undefined;
    }> | null | undefined;
  } | null | undefined;
};
export type TagsListBuildTypeTagsQuery = {
  response: TagsListBuildTypeTagsQuery$data;
  variables: TagsListBuildTypeTagsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "btLocator"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "btLocator",
        "variableName": "btLocator"
      }
    ],
    "concreteType": "Tags",
    "kind": "LinkedField",
    "name": "buildTypeBuildTags",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Tag",
        "kind": "LinkedField",
        "name": "tag",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TagsListBuildTypeTagsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TagsListBuildTypeTagsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "23f393c9c9af482f1bf4a0ef4af7fcef",
    "id": null,
    "metadata": {},
    "name": "TagsListBuildTypeTagsQuery",
    "operationKind": "query",
    "text": "query TagsListBuildTypeTagsQuery(\n  $btLocator: String!\n) {\n  buildTypeBuildTags(btLocator: $btLocator) {\n    tag {\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1c8947b79a1c5e9f9f8dccd385310c79";

export default node;
