/*
 * Fast Deep Merge
 *
 * the lodash merge method with optimization performance
 * accepts only two argument
 */

const isObject = (value: unknown): boolean =>
  Object.prototype.toString.call(value) === '[object Object]'
const isArray = Array.isArray

function assignMergeValue<T extends object, K extends keyof T, V extends T[K]>(
  object: T,
  key: K,
  value: V,
) {
  if ((value !== undefined && object[key] !== value) || (value === undefined && !(key in object))) {
    object[key] = value
  }
}

function fastDeepBaseMerge<T extends object>(object: T, source: T): T {
  const objectKeys = Object.keys(source) as Array<keyof T>

  objectKeys.forEach(key => {
    const srcValue = source[key]

    if (isObject(srcValue) || isArray(srcValue)) {
      const objValue = object[key]
      let newValue: T[keyof T] | object = srcValue
      let isCommon = true

      if (isCommon) {
        const isArr = isArray(srcValue)

        if (isArr) {
          if (isArray(objValue)) {
            newValue = objValue
          } else {
            newValue = []
          }
        } else if (isObject(srcValue)) {
          newValue = objValue

          if (!isObject(objValue)) {
            newValue = {}
          }
        } else {
          isCommon = false
        }
      }
      if (isCommon) {
        fastDeepBaseMerge(newValue as object, srcValue as object)
      }

      assignMergeValue(object, key, newValue as T[keyof T])
    } else {
      assignMergeValue(object, key, srcValue)
    }
  })

  return object
}

const fastDeepMerge = <T = unknown>(object: T, source: T) =>
  fastDeepBaseMerge(Object(object), Object(source))

export default fastDeepMerge
