import {graphql} from 'react-relay'

import type {FetchBranchesParams} from '../../../rest/branches'
import {getBranchesLocator} from '../../../rest/branches'

import type {BranchSelectBranchesQuery$variables} from './__generated__/BranchSelectBranchesQuery.graphql'

export const branchesQuery = graphql`
  query BranchSelectBranchesQuery(
    $locator: String
    $projectLocator: String!
    $btLocator: String!
    $isProject: Boolean!
    $isBuildType: Boolean!
  ) {
    branches(projectLocator: $projectLocator, locator: $locator) @include(if: $isProject) {
      ...BranchSelect_branches
    }
    branchesOfBuildType(btLocator: $btLocator, locator: $locator) @include(if: $isBuildType) {
      ...BranchSelect_branches
    }
  }
`

export const branchExistsQuery = graphql`
  query BranchSelectBranchExistsQuery(
    $selectedLocator: String!
    $projectLocator: String!
    $btLocator: String!
    $checkInProject: Boolean!
    $checkInBuildType: Boolean!
  ) {
    branchExistsInProject(branchLocator: $selectedLocator, projectLocator: $projectLocator)
      @include(if: $checkInProject)
    branchExistsInBuildType(branchLocator: $selectedLocator, btLocator: $btLocator)
      @include(if: $checkInBuildType)
  }
`

export const getBranchesQueryVariables = (
  params: FetchBranchesParams,
): BranchSelectBranchesQuery$variables => {
  const locator = getBranchesLocator(params)
  const projectId = params.node?.nodeType === 'project' ? params.node.id : null
  const buildTypeId = params.node?.nodeType === 'bt' ? params.node.id : null
  return {
    locator,
    projectLocator: `id:${projectId}`,
    btLocator: `id:${buildTypeId}`,
    isProject: projectId != null,
    isBuildType: buildTypeId != null,
  }
}
