/**
 * @generated SignedSource<<8c2ee5418bd4f11b4e038520b7c21a6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AgentsSidebarQuery$variables = Record<PropertyKey, never>;
export type AgentsSidebarQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AgentsPagesCanViewAgentDetailsFragment">;
};
export type AgentsSidebarQuery = {
  response: AgentsSidebarQuery$data;
  variables: AgentsSidebarQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentsSidebarQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AgentsPagesCanViewAgentDetailsFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AgentsSidebarQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AgentPools",
        "kind": "LinkedField",
        "name": "agentPoolsRest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "22ba5bae115a57cf74c0dd42da49204a",
    "id": null,
    "metadata": {},
    "name": "AgentsSidebarQuery",
    "operationKind": "query",
    "text": "query AgentsSidebarQuery {\n  ...AgentsPagesCanViewAgentDetailsFragment\n}\n\nfragment AgentsPagesCanViewAgentDetailsFragment on Query {\n  agentPoolsRest {\n    count\n  }\n}\n"
  }
};

(node as any).hash = "a73c49ebcfa75605a2a76b10ceb6032b";

export default node;
