import type {EntryPoint} from 'react-relay'

import type {ThinNestedEntryPointParamsObject} from '../../../../types/entryPoints'
import {makeResource} from '../../../../utils/makeResource'

import {BuildParametersKindTab} from './BuildParameters.types'
import type {NestedEntryPoints} from './BuildParametersTab'
import type {BuildParametersComponent} from './Parameters/BuildParameters'
import {BuildParametersEntryPoint} from './Parameters/BuildParameters.entryPoint'

type Params = {
  buildLocator: string
  buildParametersTab?: string | null
}

export const BuildParametersTabEntryPoint: EntryPoint<BuildParametersComponent, Params> = {
  root: makeResource(
    'BuildParametersTab',
    () =>
      import(
        /* webpackChunkName: "BuildParametersTab", webpackPrefetch: true */ './BuildParametersTab'
      ),
  ),
  getPreloadProps: ({buildLocator, buildParametersTab}) => {
    const entryPoints: ThinNestedEntryPointParamsObject<NestedEntryPoints> = {}
    if (buildParametersTab === BuildParametersKindTab.PARAMETERS || buildParametersTab == null) {
      entryPoints.parameters = {
        entryPoint: BuildParametersEntryPoint,
        entryPointParams: buildLocator,
      }
    }
    return {entryPoints}
  },
}
