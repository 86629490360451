/**
 * @generated SignedSource<<2d9665e103261374a97aa374d21f9754>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BuildTypeHistoryQuery$variables = {
  locator: string;
};
export type BuildTypeHistoryQuery$data = {
  readonly builds: {
    readonly count: number | null | undefined;
  } | null | undefined;
};
export type BuildTypeHistoryQuery = {
  response: BuildTypeHistoryQuery$data;
  variables: BuildTypeHistoryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locator"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "locator",
        "variableName": "locator"
      }
    ],
    "concreteType": "Builds",
    "kind": "LinkedField",
    "name": "builds",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BuildTypeHistoryQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BuildTypeHistoryQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "26d7fa9264af9301a67feb5b344d4dd9",
    "id": null,
    "metadata": {},
    "name": "BuildTypeHistoryQuery",
    "operationKind": "query",
    "text": "query BuildTypeHistoryQuery(\n  $locator: String!\n) {\n  builds(locator: $locator) {\n    count\n  }\n}\n"
  }
};
})();

(node as any).hash = "c34f13acdedc37301a70d1f1961e206e";

export default node;
