/**
 * @generated SignedSource<<312aeb199046d7f27f9ba35761fa37af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuildApprovalDetailsPopupContent_approvalInfo$data = {
  readonly canBeApprovedByCurrentUser: boolean | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"BuildApprovalReviewers_approvalInfo">;
  readonly " $fragmentType": "BuildApprovalDetailsPopupContent_approvalInfo";
};
export type BuildApprovalDetailsPopupContent_approvalInfo$key = {
  readonly " $data"?: BuildApprovalDetailsPopupContent_approvalInfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuildApprovalDetailsPopupContent_approvalInfo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuildApprovalDetailsPopupContent_approvalInfo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canBeApprovedByCurrentUser",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BuildApprovalReviewers_approvalInfo"
    }
  ],
  "type": "ApprovalInfo",
  "abstractKey": null
};

(node as any).hash = "2a2d0bf50385eb6cac8bfa74b8646ae5";

export default node;
