export enum ParameterGroup {
  CONFIGURATION = 'Configuration',
  SYSTEM = 'System',
  ENVIRONMENT = 'Environment',
  DEPENDENCIES = 'Dependencies',
}

export enum ItemType {
  CATEGORY_HEADER = 'CategoryHeader',
  GROUP_HEADER = 'GroupHeader',
  GROUP_DESCRIPTION = 'GroupDescription',
  TABLE_HEADER = 'TableHeader',
  PARAMETER = 'Parameter',
  ADDED_PARAMETER = 'AddedParameter',
  MODIFIED_PARAMETER = 'ModifiedParameter',
  EMPTY = 'Empty',
}

export enum ParameterCategory {
  AGENT = 'Agent',
  AGENT_TYPE = 'AgentType',
  USER_DEFINED = 'UserDefined',
  ACTUAL = 'Actual',
  ACTUAL_ON_AGENT = 'ActualOnAgent',
}

export interface CategoryItem {
  type: ItemType.CATEGORY_HEADER
  key: string

  category: ParameterCategory
}

export interface GroupItem {
  type: ItemType.GROUP_HEADER
  key: string

  group: ParameterGroup
  category: ParameterCategory

  matches?: number
}

export interface GroupDescriptionItem {
  type: ItemType.GROUP_DESCRIPTION
  key: string

  group: ParameterGroup
}

export interface TableHeaderItem {
  type: ItemType.TABLE_HEADER
  key: string

  name?: string
  value?: string
}

export interface ParameterItem {
  type: ItemType.PARAMETER
  key: string

  name: string
  value: string

  defaultValue?: string
  isAddedParameter?: boolean
  highlight: string
}

export interface ModifiedParameterItem {
  type: ItemType.MODIFIED_PARAMETER
  key: string

  name: string
  value: string

  defaultValue: string

  highlight: string
}

export interface AddedParameterItem {
  type: ItemType.ADDED_PARAMETER
  key: string

  name: string
  value: string

  highlight: string
}

interface EmptyItem {
  type: ItemType.EMPTY
  key: string
}

export type VirtualizerItem =
  | CategoryItem
  | GroupItem
  | GroupDescriptionItem
  | TableHeaderItem
  | EmptyItem
  | ParameterItem
  | ModifiedParameterItem
  | AddedParameterItem
