import type {Fetchable} from '../types'

import type {KeyValue} from './object'

export const emptyObject = Object.freeze({})
export const getEmptyHash = <K extends PropertyKey, V>(): KeyValue<K, V> => emptyObject
export const emptyArray: ReadonlyArray<any> = Object.freeze([])
export const emptySet: Set<any> = Object.freeze(new Set())
export const emptyMap = new Map()

function emptyFetchable<T>(data: T): Fetchable<T> {
  return {
    data,
    ready: false,
    loading: false,
    backgroundLoading: false,
    inited: false,
  }
}

export const emptyArrayFetchable: Fetchable<ReadonlyArray<any>> = emptyFetchable(emptyArray)

export const noop = () => {}

export const noopAsync = async () => {}
