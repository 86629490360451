import {createReducer} from '@reduxjs/toolkit'
import type * as Redux from 'redux'

import {
  defaultAgentsPagesState,
  agentsTreeSearchState as agentsTreeSearchStateSlice,
} from './AgentsPages.slices'
import type {AgentsPagesState, AgentsTreeExpandState} from './AgentsPages.types'
import {
  setAgentsTreeItemExpandStateAction,
  setFavoriteAgentsTreeItemExpandStateAction,
} from './AgentsSidebar/AgentsSidebar.actions'

const agentsTreeExpandStateReducer = createReducer<AgentsTreeExpandState>({}, builder => {
  builder.addCase(setAgentsTreeItemExpandStateAction, (state, action) => {
    const {agentItemType, agentItemId, expanded} = action.payload
    state[agentItemType] ??= {}
    state[agentItemType]![agentItemId!] = expanded
  })
})

const favoriteAgentsTreeExpandStateReducer = createReducer<AgentsTreeExpandState>({}, builder => {
  builder.addCase(setFavoriteAgentsTreeItemExpandStateAction, (state, action) => {
    const {agentItemType, agentItemId, expanded} = action.payload
    state[agentItemType] ??= {}
    state[agentItemType]![agentItemId!] = expanded
  })
})

export const agentsPagesReducers: Redux.Reducer<AgentsPagesState> = (
  {
    agentsTreeExpandState,
    agentsTreeSearchExpandState,
    agentsTreeSearchState,
    favoriteAgentsTreeExpandState,
    favoriteAgentsTreeSearchExpandState,
  }: AgentsPagesState = defaultAgentsPagesState,
  action,
): AgentsPagesState => {
  const {searchQuery} = agentsTreeSearchState
  return {
    favoriteAgentsTreeExpandState:
      searchQuery === ''
        ? favoriteAgentsTreeExpandStateReducer(favoriteAgentsTreeExpandState, action)
        : favoriteAgentsTreeExpandState,
    favoriteAgentsTreeSearchExpandState:
      searchQuery !== ''
        ? favoriteAgentsTreeExpandStateReducer(favoriteAgentsTreeSearchExpandState, action)
        : defaultAgentsPagesState.favoriteAgentsTreeSearchExpandState,
    agentsTreeExpandState:
      searchQuery === ''
        ? agentsTreeExpandStateReducer(agentsTreeExpandState, action)
        : agentsTreeExpandState,
    agentsTreeSearchExpandState:
      searchQuery !== ''
        ? agentsTreeExpandStateReducer(agentsTreeSearchExpandState, action)
        : defaultAgentsPagesState.agentsTreeSearchExpandState,
    agentsTreeSearchState: agentsTreeSearchStateSlice.reducer(agentsTreeSearchState, action),
  }
}
