import type {PlaceId} from '@jetbrains/teamcity-api'

import {restBaseApi} from '../../../services/restBase'

import type {DummyPlugin, PluginLocationContext} from './Plugin.types'
import {getPluginUIContext} from './Plugin.utils'

type GetPlaceIdAvailablePluginsArg = {
  placeId: PlaceId
  activeEntities: PluginLocationContext
}

type GetPluginContentArg = {
  pluginUrl: string
  activeEntities: PluginLocationContext
}

export const pluginsApi = restBaseApi.injectEndpoints({
  endpoints: build => ({
    getPlaceIdAvailablePlugins: build.query<
      ReadonlyArray<DummyPlugin>,
      GetPlaceIdAvailablePluginsArg
    >({
      query: ({placeId, activeEntities}) => ({
        url: `/app/placeId/${placeId}`,
        params: {pluginUIContext: getPluginUIContext(activeEntities)},
      }),
    }),
    getPluginContent: build.query<string, GetPluginContentArg>({
      query: ({pluginUrl, activeEntities}) => ({
        url: pluginUrl,
        params: {pluginUIContext: getPluginUIContext(activeEntities)},
        responseHandler: 'text',
      }),
    }),
  }),
})
