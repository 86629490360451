import oboe from 'oboe'

import HTTPError from './errors/HTTPError'
import {checkRequestPossibility, processErrorCodes, getHeaders} from './request'
import type {RestRequestOptions} from './request'

export default function stream<T, N>(
  url: string,
  pattern: string,
  onProgress: (arg0: N) => unknown,
  options?: RestRequestOptions,
): Promise<T> {
  return new Promise((resolve, reject) => {
    try {
      checkRequestPossibility()
    } catch (error) {
      reject(error)
    }

    function request(isRetry = false) {
      const headers = getHeaders(options)
      const csrfToken = headers['X-TC-CSRF-Token']
      oboe({
        url,
        headers,
        withCredentials: true,
      })
        .node(pattern, onProgress)
        .done(resolve)
        .fail(async ({thrown, statusCode, body}) => {
          const shouldRetry =
            !isRetry &&
            (await processErrorCodes({
              url,
              statusCode,
              getMessage: () => body ?? '',
              retryCallback: () => request(true),
              csrfToken,
            }))

          if (!shouldRetry) {
            reject(
              thrown ??
                new HTTPError(
                  body || `Network Error when loading builds, status code is ${statusCode}`,
                  statusCode,
                  url,
                ),
            )
          }
        })
    }

    request()
  })
}
