import * as React from 'react'

export type ReactModule<P> = {
  default: React.ComponentType<P>
}

export function wrappedLazy<P>(
  factory: () => Promise<ReactModule<P>>,
  fallback: React.ReactNode = '',
): React.ComponentType<React.PropsWithRef<P> & React.JSX.IntrinsicAttributes> {
  const Component = React.lazy(factory)

  return function WrappedLazy(props) {
    return (
      <React.Suspense fallback={fallback}>
        <Component {...props} />
      </React.Suspense>
    )
  }
}
