/**
 * @generated SignedSource<<35bb78a3e5ea58728f4e8e2bc340aca2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuildParametersTabParametersQuery$variables = {
  buildLocator: string;
};
export type BuildParametersTabParametersQuery$data = {
  readonly build: {
    readonly " $fragmentSpreads": FragmentRefs<"useQueryBuildParametersBuild">;
  } | null | undefined;
};
export type BuildParametersTabParametersQuery = {
  response: BuildParametersTabParametersQuery$data;
  variables: BuildParametersTabParametersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "buildLocator"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "buildLocator",
    "variableName": "buildLocator"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "locator",
    "value": "name:(value:(^dep\\..*),matchType:does-not-match)"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Property",
  "kind": "LinkedField",
  "name": "property",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  },
  (v3/*: any*/)
],
v5 = [
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BuildParametersTabParametersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Build",
        "kind": "LinkedField",
        "name": "build",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useQueryBuildParametersBuild"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BuildParametersTabParametersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Build",
        "kind": "LinkedField",
        "name": "build",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "composite",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Properties",
            "kind": "LinkedField",
            "name": "resultingProperties",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": "resultingProperties(locator:\"name:(value:(^dep\\..*),matchType:does-not-match)\")"
          },
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Properties",
            "kind": "LinkedField",
            "name": "startProperties",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": "startProperties(locator:\"name:(value:(^dep\\..*),matchType:does-not-match)\")"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Properties",
            "kind": "LinkedField",
            "name": "properties",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Properties",
            "kind": "LinkedField",
            "name": "originalProperties",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2d1112021085e87466311261ee856b5b",
    "id": null,
    "metadata": {},
    "name": "BuildParametersTabParametersQuery",
    "operationKind": "query",
    "text": "query BuildParametersTabParametersQuery(\n  $buildLocator: String!\n) {\n  build(buildLocator: $buildLocator) {\n    ...useQueryBuildParametersBuild\n    id\n  }\n}\n\nfragment useQueryBuildParametersBuild on Build {\n  composite\n  resultingProperties(locator: \"name:(value:(^dep\\\\..*),matchType:does-not-match)\") {\n    count\n    property {\n      name\n      value\n    }\n  }\n  startProperties(locator: \"name:(value:(^dep\\\\..*),matchType:does-not-match)\") {\n    property {\n      name\n      value\n    }\n  }\n  properties {\n    count\n    property {\n      name\n      value\n    }\n  }\n  originalProperties {\n    property {\n      name\n      value\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "154a176a9621e112e1f9d946f5d552f3";

export default node;
