/**
 * @generated SignedSource<<c876c8c8f3f86874c72cfb6a5878b7e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppContainerQuery$variables = Record<PropertyKey, never>;
export type AppContainerQuery$data = {
  readonly user: {
    readonly properties: {
      readonly property: ReadonlyArray<{
        readonly name: string | null | undefined;
        readonly value: string | null | undefined;
      }> | null | undefined;
    } | null | undefined;
    readonly rawId: number | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"AppFragment">;
  } | null | undefined;
};
export type AppContainerQuery = {
  response: AppContainerQuery$data;
  variables: AppContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "userLocator",
    "value": "current"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rawId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Properties",
  "kind": "LinkedField",
  "name": "properties",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Property",
      "kind": "LinkedField",
      "name": "property",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AppFragment"
          }
        ],
        "storageKey": "user(userLocator:\"current\")"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AppContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAvatars",
            "kind": "LinkedField",
            "name": "avatars",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlToSize20",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlToSize28",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlToSize32",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlToSize40",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlToSize56",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlToSize64",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlToSize80",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": "user(userLocator:\"current\")"
      }
    ]
  },
  "params": {
    "cacheID": "8f2329a562bc48ab5e92a5074974d158",
    "id": null,
    "metadata": {},
    "name": "AppContainerQuery",
    "operationKind": "query",
    "text": "query AppContainerQuery {\n  user(userLocator: \"current\") {\n    rawId\n    properties {\n      property {\n        name\n        value\n      }\n    }\n    ...AppFragment\n    id\n  }\n}\n\nfragment AllUserSelectFragment on User {\n  rawId\n  avatars {\n    urlToSize40\n  }\n  name\n  username\n}\n\nfragment AppFragment on User {\n  ...currentUserKeyFragment\n  ...AllUserSelectFragment\n}\n\nfragment AvatarFragment on User {\n  rawId\n  name\n  username\n  avatars {\n    urlToSize20\n    urlToSize28\n    urlToSize32\n    urlToSize40\n    urlToSize56\n    urlToSize64\n    urlToSize80\n  }\n}\n\nfragment HeaderUserFragment on User {\n  rawId\n  name\n  username\n  ...AvatarFragment\n}\n\nfragment currentUserKeyFragment on User {\n  ...useMyIdFragment\n  ...useMyUsernameFragment\n  ...useUserPropertyFragment\n  ...HeaderUserFragment\n  ...AllUserSelectFragment\n}\n\nfragment useMyIdFragment on User {\n  rawId\n}\n\nfragment useMyUsernameFragment on User {\n  username\n}\n\nfragment useUserPropertyFragment on User {\n  properties {\n    property {\n      name\n      value\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0e3a29c5b0185809fe7b6a0a6a2541a5";

export default node;
