/**
 * @generated SignedSource<<f3ece88785fe83a41917fe2810125e33>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueuePageQuery$variables = {
  locator: string;
  skip: boolean;
};
export type QueuePageQuery$data = {
  readonly agentPoolOfAgentPool?: {
    readonly " $fragmentSpreads": FragmentRefs<"QueuePageHeader_agentPool">;
  } | null | undefined;
};
export type QueuePageQuery = {
  response: QueuePageQuery$data;
  variables: QueuePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locator"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "agentPoolLocator",
    "variableName": "locator"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QueuePageQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AgentPoolRest",
            "kind": "LinkedField",
            "name": "agentPoolOfAgentPool",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "QueuePageHeader_agentPool"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QueuePageQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AgentPoolRest",
            "kind": "LinkedField",
            "name": "agentPoolOfAgentPool",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "c5045ce66f719fa83144b0d15b0d6856",
    "id": null,
    "metadata": {},
    "name": "QueuePageQuery",
    "operationKind": "query",
    "text": "query QueuePageQuery(\n  $locator: String!\n  $skip: Boolean!\n) {\n  agentPoolOfAgentPool(agentPoolLocator: $locator) @skip(if: $skip) {\n    ...QueuePageHeader_agentPool\n    id\n  }\n}\n\nfragment QueuePageHeader_agentPool on AgentPoolRest {\n  name\n}\n"
  }
};
})();

(node as any).hash = "eefa6552b37c31d06fccd565a9d432b2";

export default node;
