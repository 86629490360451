import type {GraphQLResponseWithData, PayloadError} from 'relay-runtime'

import type {KeyValue} from '../utils/object'

import HTTPError, {HTTPCodesEnum} from './errors/HTTPError'

export async function processErrors(response: Response) {
  if (!response.ok) {
    const message = await response.clone().text()
    throw new HTTPError(message, response.status, response.url, response)
  }
}

const ERROR_STATUSES_MAP: KeyValue<string, HTTPCodesEnum> = {
  NOT_FOUND: HTTPCodesEnum.NOT_FOUND,
  ACCESS_DENIED: HTTPCodesEnum.FORBIDDEN,
  SERVER_ERROR: HTTPCodesEnum.INTERNAL_SERVER_ERROR,
  OPERATION_FAILED: HTTPCodesEnum.BAD_REQUEST,
}

type PayloadErrorWithExtensions = PayloadError & {
  extensions?:
    | {
        classification: string
        [key: string]: string
      }
    | undefined
}

export function processGraphQLErrors(response: GraphQLResponseWithData) {
  if (response.errors != null) {
    const error = response.errors[0] as PayloadErrorWithExtensions

    if (
      error?.extensions?.classification != null &&
      ERROR_STATUSES_MAP[error.extensions.classification] != null
    ) {
      throw new HTTPError(error.message, ERROR_STATUSES_MAP[error.extensions.classification])
    }

    throw new HTTPError(error.message, HTTPCodesEnum.OK)
  }
}

export default async function processResponse<T>(response: Response): Promise<T> {
  await processErrors(response)

  return response.json()
}
export async function processTextResponse(response: Response): Promise<string> {
  await processErrors(response)

  return response.text()
}
