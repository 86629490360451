/**
 * @generated SignedSource<<e92e4da14b7a1ce2f08d674a1ddac2c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuildApprovalReviewers_canViewApprovalInfo$data = {
  readonly configurationValid: boolean | null | undefined;
  readonly groupApprovals: {
    readonly count: number | null | undefined;
  } | null | undefined;
  readonly userApprovals: {
    readonly count: number | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "BuildApprovalReviewers_canViewApprovalInfo";
};
export type BuildApprovalReviewers_canViewApprovalInfo$key = {
  readonly " $data"?: BuildApprovalReviewers_canViewApprovalInfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuildApprovalReviewers_canViewApprovalInfo">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuildApprovalReviewers_canViewApprovalInfo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "configurationValid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserApprovals",
      "kind": "LinkedField",
      "name": "userApprovals",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GroupApprovals",
      "kind": "LinkedField",
      "name": "groupApprovals",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "ApprovalInfo",
  "abstractKey": null
};
})();

(node as any).hash = "d5f31f369e9ab0d260e8ee560c3dde60";

export default node;
