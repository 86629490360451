import {Size} from '@jetbrains/ring-ui/components/avatar/avatar'
import type {ReactElement} from 'react'

import {useIsHighlightMyChangesAndInvestigations} from '../../../hooks/useIsHighlightMyChangesAndInvestigations'
import useMyId from '../../../hooks/useMyId'
import type {User} from '../../../services/rest'
import type {AvatarSize} from '../../../types'
import {getAvatarSizeKey} from '../../../types'
import {resolveRelative} from '../../../utils/url'

import {Avatar} from './Avatar'

export type AvatarBaseProps = {
  vcsUserName?: string
  size: Size
  srcSize?: AvatarSize
  src2xSize?: AvatarSize
  className?: string
  avatarClassName?: string
  displayPlaceholder?: boolean
  withOutline?: boolean
  relative?: boolean
}

type Props = AvatarBaseProps & {
  user: Partial<User> | null | undefined
}

function AvatarContainer({
  user,
  vcsUserName,
  size,
  srcSize = getAvatarSizeKey(size),
  src2xSize = getAvatarSizeKey(2 * size),
  className,
  avatarClassName,
  displayPlaceholder = true,
  withOutline = true,
  relative = true,
}: Props): ReactElement | null {
  const myId = useMyId()
  const isMine = user?.id != null && user.id === myId
  const isHighlightMyChanges = useIsHighlightMyChangesAndInvestigations()
  const avatars: Record<string, string | null> | null | undefined = user?.avatars
  const maybeAvatar = avatars?.[srcSize]
  const avatar = maybeAvatar && relative ? resolveRelative(maybeAvatar) : maybeAvatar
  const maybeAvatar2x = avatars?.[src2xSize]
  const avatar2x = maybeAvatar2x && relative ? resolveRelative(maybeAvatar2x) : maybeAvatar2x
  const username = user?.name ?? user?.username ?? vcsUserName

  return (displayPlaceholder && username != null) || avatar2x ? (
    <Avatar
      className={className}
      withOutline={withOutline}
      mine={isMine}
      highlightMyChanges={isHighlightMyChanges}
      size={size}
      avatarClassName={avatarClassName}
      avatar2x={avatar2x}
      avatar={avatar}
      username={username}
    />
  ) : null
}

export {Size}
export default AvatarContainer
