import {fetchQuery} from 'react-relay'
import {createOperationDescriptor, getRequest} from 'relay-runtime'
import type {GraphQLTaggedNode} from 'relay-runtime/lib/query/RelayModernGraphQLTag'
import type {Environment} from 'relay-runtime/lib/store/RelayStoreTypes'
import type {
  CacheConfig,
  FetchQueryFetchPolicy,
  OperationType,
} from 'relay-runtime/lib/util/RelayRuntimeTypes'

export async function fetchQueryOnce<T extends OperationType>(
  environment: Environment,
  taggedNode: GraphQLTaggedNode,
  variables: T['variables'],
  cacheConfig?: {
    networkCacheConfig?: CacheConfig | null | undefined
    fetchPolicy?: FetchQueryFetchPolicy | null | undefined
  } | null,
): Promise<T['response']> {
  const disposable = environment.retain(
    createOperationDescriptor(getRequest(taggedNode), variables),
  )
  const result = await fetchQuery(environment, taggedNode, variables, cacheConfig).toPromise()
  disposable.dispose()
  return result
}
