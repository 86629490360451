import type {
  PlaceId,
  TeamCityAPIType,
  PluginRegistry as PluginRegistryInterface,
} from '@jetbrains/teamcity-api'

import type {KeyValue, WritableKeyValue} from '../utils/object'
import {objectValues} from '../utils/object'

type Plugin = InstanceType<TeamCityAPIType['Plugin']>
type Handler = () => void

let handlers: Array<Handler> = []
const store: WritableKeyValue<PlaceId, Array<Plugin>> = {}

class PluginRegistry implements PluginRegistryInterface {
  subscribe(handler: Handler) {
    handlers.push(handler)
  }

  unsubscribe(handler: Handler) {
    handlers = handlers.filter(fn => handler !== fn)
  }

  getStore(): KeyValue<PlaceId, Array<Plugin>> {
    return store
  }

  add(placeId: PlaceId, plugin: Plugin) {
    if (!store[placeId]) {
      store[placeId] = []
    }

    const place = store[placeId]

    if (Array.isArray(place)) {
      if (place.some(item => item.name === plugin.name)) {
        throw Error(`Plugin ${plugin.name} has been already registered in PlaceId ${placeId}. `)
      }

      place.push(plugin)
    }

    handlers.forEach(hander => hander())
  }

  remove(placeId: PlaceId, plugin: Plugin) {
    const place = store[placeId]
    if (place == null) {
      return
    }
    const index = place.indexOf(plugin)

    if (index > -1) {
      place.splice(index, 1)
    }

    handlers.forEach(hander => hander())
  }

  searchByPlaceId(
    placeId: PlaceId,
    pluginName?: string,
  ): (Plugin | null | undefined) | Array<Plugin> {
    const place = store[placeId]

    if (place && !pluginName) {
      return place
    }

    return place?.find(plugin => plugin.name === pluginName) || null
  }

  findUniquePlugin(placeId: PlaceId, pluginName: string): Plugin | null | undefined {
    if (!placeId || !pluginName) {
      return null
    }

    const result = this.searchByPlaceId(placeId, pluginName)

    if (!Array.isArray(result)) {
      return result
    } else {
      return null
    }
  }

  search(pluginName: string): Array<Plugin> {
    return objectValues(store)
      .flat()
      .filter(plugin => plugin.name === pluginName)
  }
}

const pluginRegistry: PluginRegistry = new PluginRegistry()
export default pluginRegistry
