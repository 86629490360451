import type {BuildId, BuildTypeId, Id, LinkType, WebLinks} from '../types'

import {resolveRelative} from './url'

const makeWebEntityLinkResolver =
  (targetType: LinkType) =>
  (entity: WebLinks | null | undefined): string | null | undefined => {
    const links = entity?.links
    const link = links?.link?.find(({type}) => type === targetType)
    return link?.relativeUrl ? resolveRelative(link.relativeUrl) : undefined
  }

export enum WebEntityType {
  BUILD,
  BUILD_TYPE,
  PROJECT,
  AGENT,
}

export const resolveWebEntityLink = (
  entityType: WebEntityType,
  id: Id | null | undefined,
): string | null => {
  if (id == null) {
    return null
  }
  switch (entityType) {
    case WebEntityType.BUILD:
      return resolveRelative(`/viewLog.html?buildId=${id}`)
    case WebEntityType.BUILD_TYPE:
      return resolveRelative(`/viewType.html?buildTypeId=${id}`)
    case WebEntityType.PROJECT:
      return resolveRelative(`/project.html?projectId=${id}`)
    case WebEntityType.AGENT:
      return resolveRelative(`/agentDetails.html?id=${id}`)
    default:
      return null
  }
}
export const resolveWebEntityEditLink: (
  entity: WebLinks | null | undefined,
) => string | null | undefined = makeWebEntityLinkResolver('webEdit')
export const resolveWebEntitySettingsLink: (
  entity: WebLinks | null | undefined,
) => string | null | undefined = makeWebEntityLinkResolver('webViewSettings')

export const getOldWebBuildTypeLink = (buildTypeId: BuildTypeId | null | undefined) =>
  resolveRelative(`/viewType.html?buildTypeId=${buildTypeId}`)
export const getOldWebBuildLink = (buildId: BuildId | null | undefined, isQueued?: boolean) =>
  resolveRelative(
    isQueued ? `/viewQueued.html?itemId=${buildId}` : `/viewLog.html?buildId=${buildId}`,
  )
