import type {Middleware} from '@reduxjs/toolkit'
import {isAction} from '@reduxjs/toolkit'
import memoize from 'memoize-one'

import type {State} from '../reducers/types'
import {internalProps} from '../types/BS_types'

const loadStatisticsChunk = memoize(
  () =>
    import(
      /* webpackChunkName: "statistics", webpackPrefetch: true */
      '.'
    ),
)
const canUseStatistics = internalProps['teamcity.ui.statistics.enabled'] === true
const middleware: Middleware<{}, State> = store => next => action => {
  if (canUseStatistics && isAction(action)) {
    const state = store.getState()
    loadStatisticsChunk().then(({track}) => track(state, action))
  }

  return next(action)
}

export default middleware
