import type {Middleware, PayloadAction} from '@reduxjs/toolkit'
import {configureStore, isFulfilled, isPending, isRejected} from '@reduxjs/toolkit'
import type {QueryThunkArg} from '@reduxjs/toolkit/dist/query/core/buildThunks'
import {setupListeners} from '@reduxjs/toolkit/query'

import {toParametersGroupState} from '../components/packages/Parameters/utils/toPreloadedState'
import reducer from '../reducers'
import type {State} from '../reducers/types'
import {getStateToPersist} from '../selectors/toPersist'
import {restApi} from '../services/rest'
import statistics from '../statistics/middleware'
import {hasPerformanceActionLog} from '../utils/logger'

import {autobatchMiddleware} from './autobatchMiddleware'
import {collectActionsMiddleware} from './collectActionsMiddleware'
import {meassureActionPerformaceMiddleware} from './meassureActionPerformaceMiddleware'
import {loadState, saveState} from './persistState'

const logMe: Middleware<{}, State> = store => next => action => {
  if (process.env.NODE_ENV === 'development') {
    const castedAction = action as PayloadAction<unknown, string, {arg: QueryThunkArg}, unknown>
    const {meta, payload, error} = castedAction
    if (meta?.arg?.endpointName != null) {
      const {endpointName, originalArgs} = meta.arg
      let type = ''
      if (isPending(castedAction)) {
        type = 'pending'
      } else if (isFulfilled(castedAction)) {
        type = 'fulfilled'
      } else if (isRejected(castedAction)) {
        type = 'rejected'
      }
      // eslint-disable-next-line no-console
      console.log(`${type}:`, endpointName, originalArgs, payload, error)
    }
    // eslint-disable-next-line no-console
    console.log('dispatching:', action, store.getState())
  }

  return next(action)
}

const preloadedState = loadState()
export const createStore = (
  logicMiddleWare = IS_STORYBOOK && require('./getLogicMiddleware').getLogicMiddleware(),
) =>
  configureStore({
    reducer,
    preloadedState: preloadedState && {
      ...preloadedState,
      parameterGroups: toParametersGroupState(preloadedState?.parameterGroups),
    },
    devTools: process.env.NODE_ENV === 'development',
    middleware: getDefaultMiddleware => {
      const defaultMiddleware = getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      })
      return defaultMiddleware.concat(
        [
          restApi.middleware,
          logicMiddleWare,
          logMe,
          autobatchMiddleware(),
          ...(hasPerformanceActionLog
            ? [collectActionsMiddleware(), meassureActionPerformaceMiddleware()]
            : []),
          statistics,
        ].filter(Boolean),
      )
    },
    enhancers: getDefaultEnhancers => getDefaultEnhancers({autoBatch: !IS_STORYBOOK}),
  })
const store = createStore()
store.subscribe(() => {
  saveState(getStateToPersist(store.getState()))
})
setupListeners(store.dispatch)
export default store
export type Store = typeof store
