const successRequestsInterval = 20 //ms
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const minFailedRequestsInterval = successRequestsInterval * 10
export const defaultRequestPoolSize = 5
interface Task {
  id: string
  refCount: number
  request: () => Promise<any>
  failedInterval: number
  failedHandler?: number
}

class Worker {
  task: Task | undefined

  constructor(private pool: RequestsPool) {
    this.pool = pool
  }

  async exec(task: Task): Promise<void> {
    this.task = task

    try {
      await task.request()
      this.pool.handleResult(task)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error, task)
      this.pool.handleError(task)
    }

    this.task = undefined
  }
}

export class RequestsPool {
  queue: Array<Task> = []
  tasks: Record<string, Task> = {}
  workers: Array<Worker> = []

  constructor(
    size: number,
    private invokeImmediately: boolean = process.env.NODE_ENV === 'test',
  ) {
    for (let i = 0; i < size; i++) {
      this.workers.push(new Worker(this))
    }
  }

  add(id: string, request: () => Promise<unknown>) {
    if (this.invokeImmediately) {
      request()
      return
    }

    const task = this.get(id)

    if (task) {
      task.refCount += 1
    } else {
      const newTask = {
        id,
        request,
        refCount: 1,
        failedInterval: minFailedRequestsInterval,
      }

      this.tasks[id] = newTask
      this.queue.push(newTask)
      this.flush()
    }
  }

  get(id: string): Task | undefined {
    return this.tasks[id]
  }

  async flush() {
    const worker = this.workers.find(worker => worker.task === undefined)

    if (!worker) {
      return
    }

    const task = this.queue.shift()

    if (!task) {
      return
    }

    await worker.exec(task)
  }

  cancel(id: string) {
    const task = this.get(id)

    if (task) {
      task.refCount -= 1

      if (task.refCount === 0) {
        window.clearTimeout(task.failedHandler)
        delete this.tasks[task.id]
        this.queue = this.queue.filter(pendingTask => pendingTask.id !== task.id)
      }
    }
  }

  handleError(task: Task) {
    task.failedHandler = window.setTimeout(() => {
      task.failedInterval *= 2
      task.failedHandler = undefined

      this.queue.push(task)
      this.flush()
    }, task.failedInterval)

    this.flush()
  }

  handleResult(task: Task) {
    delete this.tasks[task.id]

    this.flush()
  }
}
