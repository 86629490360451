import type {State} from '../../../reducers/types'
import {stringifyId} from '../../../types'
import type {BuildTypeId, DslOptions} from '../../../types'

import type {DslCodeFragment, DslCodeFragmentJsonKeys} from './Dsl.slices'

export const getDslOptions = (state: State, controlId: string): DslOptions | undefined =>
  state.dslOptions[controlId]
export const getDslRequestEndpoint: (arg0: State, arg1: BuildTypeId, arg2: string) => string = (
  state,
  buildTypeOrTemplateId,
  controlId,
) => {
  const options = getDslOptions(state, controlId)
  return `generateSettings.html?id=${stringifyId(
    buildTypeOrTemplateId,
  )}&showDSLVersion=${options?.version}&showDSLPortable=${options?.portable ? 'true' : 'false'}`
}
export const getFragmentDsl: (arg0: State) => DslCodeFragment | null | undefined = state =>
  state.dslFragment

const extractTextMapToJsonField: Record<string, DslCodeFragmentJsonKeys> = {
  entityData: 'entityConfig',
  beforeData: 'before',
  afterData: 'after',
  error: 'error',
}

export const extractText = (
  data: DslCodeFragment | null | undefined,
  name: string,
): string | null | undefined => {
  if (!data) {
    return null
  }

  const jsonName = extractTextMapToJsonField[name]

  if (typeof data === 'object' && jsonName && jsonName in data) {
    return data[jsonName]
  }

  if (typeof data === 'string') {
    const xml: Document = new window.DOMParser().parseFromString(data, 'text/xml')
    const nodes = xml.getElementsByTagName(name)

    if (!nodes) {
      return null
    }

    const node = nodes[0]

    if (!node) {
      return null
    }

    const content = node.textContent ?? ''
    return content.length > 0 ? content : null
  }

  return null
}
