import type {GetBuildProblemTreeApiArg} from '../../../../services/rest'
import type {FilterQueryParams} from '../Problems.hooks'

export const getBuildProblemsLocator = (
  baseLocator: string,
  {investigated, muted, assignee}: FilterQueryParams,
  onlyInvestigated = false,
  currentUser?: string | null,
) => {
  let investigatedLocator = investigated ? `currentlyInvestigated:${investigated}` : ''
  let assigneeLocator = assignee ? `assignee:${assignee}` : ''

  const mutedLocator = muted ? `currentlyMuted:${muted}` : ''
  if (onlyInvestigated) {
    investigatedLocator = 'currentlyInvestigated:true'
    assigneeLocator = `assignee:${currentUser}`
  }

  return [baseLocator, investigatedLocator, mutedLocator, assigneeLocator].filter(Boolean).join(',')
}

const PROBLEM_OCCURRENCE_FIELDS = 'id,details,build(id,statusText),problem(id),newFailure,logAnchor'

export const getBuildProblemsTreeFields = () =>
  `node(**),leaf(nodeId,problemEntries(count,entry(problemOccurrence(${PROBLEM_OCCURRENCE_FIELDS}),build(id,buildTypeId,number,status,state,statusText),mutes(count,mute(*,assignment(*,user(*)),scope(*,buildTypes(*)))),investigations(count,investigation(*,assignment(*,user(*)),scope(*,buildTypes(*)))))))`

export const getBuildProblemsArg = (locator: string): GetBuildProblemTreeApiArg => ({
  locator,
  treeLocator: locator,
})
