import type {DumpOptions} from 'js-yaml'
import {load, dump} from 'js-yaml'

import type {Properties} from '../../../../services/rest'
import type {NewIntegrationDto} from '../services/pipelinesApi.types'
import type {Dependency, PipelineSettings} from '../types'
import {ParameterNames} from '../types'

export const hasPipelineHeadParameter = (parameters: Properties | null | undefined) =>
  parameters?.property?.some(property => property.name === ParameterNames.PIPELINE_HEAD)

export const parsePipeline = load as (yaml: string) => PipelineSettings
export const stringifyPipeline: (pipeline: PipelineSettings, opts?: DumpOptions) => string = dump

export const getDependencyName = (dependency: Dependency) =>
  typeof dependency === 'string' ? dependency : Object.keys(dependency)[0]

export const getPreformattingSettings = (key: string, value: any) => {
  const isJobsSection = key === 'jobs'
  const isValueString = typeof value === 'string'

  if (isValueString) {
    return value.trim()
  }

  if (isJobsSection && typeof value === 'object') {
    return Object.keys(value).reduce((accumulator, jobName) => {
      const jobValue = value[jobName]
      const jobNameFormatted = jobName.trim()

      return {
        ...accumulator,
        [jobNameFormatted]: jobValue,
      }
    }, {})
  }

  return value
}

export const getPreformattingIntegrations = (draft: NewIntegrationDto[]) => {
  draft?.forEach(({id, parameters}, index) => {
    const isNewIntegration = id === parameters.displayName

    if (isNewIntegration) {
      delete draft[index].id
    }

    for (const [key, value] of Object.entries(parameters)) {
      if (value == null) {
        delete parameters[key]
      }
    }
  })
}
