import {castDraft} from 'immer'

import {createReducer} from '@reduxjs/toolkit'
import * as Redux from 'redux'

import {restApi} from '../../../../services/rest'
import {FullLogTarget} from '../BuildLog.types'
import type {FullLogState} from '../BuildLog.types'
import {getKeyForBuildLogData} from '../BuildLog.utils'

import {
  resetLogView,
  setFullLogScrollBarInfoAction,
  setLogFilter,
  updateFullLogShowed,
  updateFullLogState,
} from './FullBuildLog.actions'

const defaultFullLogState: FullLogState = {
  buildId: null,
  focusLine: null,
  expandState: null,
  expandAll: false,
  showedFirstDate: null,
  showedFirstId: null,
  showedLastDate: null,
  showedLastId: null,
  scrollBarInfo: null,
  messagesExists: false,
  filter: null,
  lastLoadingDirection: null,
  logView: null,
}

const fullLogReducer = (fullLogTarget: FullLogTarget) =>
  createReducer(defaultFullLogState, builder => {
    builder.addCase(updateFullLogState, (state, action) => {
      const {target, buildId, focusLine, expandState, expandAll} = action.payload
      if (target === fullLogTarget || (buildId != null && buildId === state.buildId)) {
        if (buildId !== undefined) {
          state.buildId = buildId
        }
        if (focusLine !== undefined) {
          state.focusLine = focusLine
        }
        if (expandState !== undefined) {
          state.expandState = castDraft(expandState)
        }
        if (expandAll !== undefined) {
          state.expandAll = expandAll
        }
        if (target === fullLogTarget && buildId !== undefined && buildId !== state.buildId) {
          state.showedFirstDate = null
          state.showedFirstId = null
          state.showedLastDate = null
          state.showedLastId = null
          state.scrollBarInfo = null
          state.lastLoadingDirection = null
          state.logView = null
        }
        // eslint-disable-next-line eqeqeq
        if (buildId === null) {
          state.filter = null
        }
      }
    })
    builder.addCase(updateFullLogShowed, (state, action) => {
      const {target, firstDate, firstId, lastDate, lastId} = action.payload
      if (target === fullLogTarget) {
        state.showedFirstDate = firstDate
        state.showedFirstId = firstId
        state.showedLastDate = lastDate
        state.showedLastId = lastId
      }
    })
    builder.addCase(setFullLogScrollBarInfoAction, (state, action) => {
      const {target, info} = action.payload
      if (target === fullLogTarget) {
        if (state.scrollBarInfo != null) {
          Object.assign(state.scrollBarInfo, info)
        } else {
          state.scrollBarInfo = info
        }
      }
    })
    builder.addCase(setLogFilter, (state, action) => {
      state.filter = action.payload
    })
    builder.addCase(resetLogView, state => {
      state.logView = null
    })
    builder.addMatcher(restApi.endpoints.getBuildLogMessages.matchPending, (state, action) => {
      const {target, invalidate, options, buildId} = action.meta.arg.originalArgs
      if (target === fullLogTarget && invalidate === true) {
        state.focusLine =
          options?.logAnchor != null && options.logAnchor !== 0 ? options.logAnchor : null
        state.filter = options != null ? options.filter : state.filter
        state.expandState = castDraft(options?.expandState ?? null)
        state.expandAll = options?.expandAll === true
        state.showedFirstDate = null
        state.showedFirstId = null
        state.showedLastDate = null
        state.showedLastId = null
        state.scrollBarInfo = null
        state.buildId = buildId
        state.lastLoadingDirection = null
        state.logView = null
      }
    })
    builder.addMatcher(restApi.endpoints.getBuildLogMessages.matchFulfilled, (state, action) => {
      const {buildLogKey, options, invalidate} = action.meta.arg.originalArgs
      const {logView, data, focusLine} = action.payload
      if (
        state.buildId != null &&
        buildLogKey ===
          getKeyForBuildLogData({
            type: 'full',
            target: fullLogTarget,
          })
      ) {
        if (logView != null) {
          state.logView = logView
        }

        const count = options?.count
        state.lastLoadingDirection =
          // https://github.com/microsoft/TypeScript/issues/53395
          count != null && (Array.isArray(count) ? count.every(i => i <= 0) : (count as number) < 0)
            ? 'up'
            : 'down'

        if (invalidate === true) {
          state.messagesExists ||= data.messages.length > 1
          if (focusLine != null) {
            state.focusLine = focusLine
          }
          if (data.expandedMessagesIndices != null) {
            state.expandState = data.expandedMessagesIndices.filter(i => i !== 0)
          }
        } else if (options?.logAnchor === -1 && focusLine != null && focusLine !== -1) {
          state.focusLine = focusLine
        }

        if (options?.expandSubtree != null && data.expandedMessagesIndices != null) {
          state.expandState = data.expandedMessagesIndices.filter(i => i !== 0)
        }
      }
    })
    builder.addMatcher(restApi.endpoints.getBuildLogMessages.matchRejected, (state, action) => {
      if (action.meta.condition) {
        // request was aborted due to condition (another query already running)
        return
      }
      const {buildLogKey, options} = action.meta.arg.originalArgs
      if (
        state.buildId != null &&
        buildLogKey ===
          getKeyForBuildLogData({
            type: 'full',
            target: fullLogTarget,
          })
      ) {
        const count = options?.count
        state.lastLoadingDirection =
          // https://github.com/microsoft/TypeScript/issues/53395
          count != null && (Array.isArray(count) ? count.every(i => i <= 0) : (count as number) < 0)
            ? 'up'
            : 'down'
      }
    })
  })

export const fullLogStates = Redux.combineReducers({
  page: fullLogReducer(FullLogTarget.PAGE),
  popup: fullLogReducer(FullLogTarget.POPUP),
})
