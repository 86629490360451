import {useCallback} from 'react'
import {graphql, useMutation} from 'react-relay'
import type {SelectorStoreUpdater} from 'relay-runtime/lib/store/RelayStoreTypes'

import type {UserId} from '../types'

import type {
  useSetUserPropertyMutation,
  useSetUserPropertyMutation$data,
} from './__generated__/useSetUserPropertyMutation.graphql'
import useMyId from './useMyId'

export const useSetUserPropertyMutationDefinition = graphql`
  mutation useSetUserPropertyMutation($name: String!, $value: String) {
    setUserProperty(userLocator: "current", name: $name, input: $value)
  }
`

export const getSetUserPropertyUpdater =
  (name: string, value: string): SelectorStoreUpdater<useSetUserPropertyMutation$data> =>
  store => {
    const currentUser = store.getRoot().getLinkedRecord('user', {userLocator: 'current'})
    const properties = currentUser?.getLinkedRecord('properties')
    const property = properties?.getLinkedRecords('property')
    const item = property?.find(item => item.getValue('name') === name)

    if (item != null) {
      item.setValue(value, 'value')
    } else {
      const propertiesDataID = properties?.getDataID()
      const length = property?.length ?? 0
      const newPropertyDataID = `${propertiesDataID}:property:${length + 1}`
      const newProperty = store
        .create(newPropertyDataID, 'Property')
        .setValue(name, 'name')
        .setValue(value, 'value')

      properties?.setLinkedRecords([...(property ?? []), newProperty], 'property')
    }
  }

export function useSetUserProperty(myIdFromProps?: UserId | null) {
  const [setUserProperty] = useMutation<useSetUserPropertyMutation>(
    useSetUserPropertyMutationDefinition,
  )
  const myIdFromContext = useMyId()
  const myId = myIdFromProps ?? myIdFromContext

  return useCallback(
    (name: string, value: string) => {
      if (myId != null && myId >= 0) {
        const updater = getSetUserPropertyUpdater(name, value)

        setUserProperty({
          variables: {name, value},
          optimisticUpdater: updater,
          updater,
        })
      }
    },
    [myId, setUserProperty],
  )
}
