import Button from '@jetbrains/ring-ui/components/button/button'
import Dropdown, {Anchor} from '@jetbrains/ring-ui/components/dropdown/dropdown'
import {H1} from '@jetbrains/ring-ui/components/heading/heading'
import * as React from 'react'
import {useEffect} from 'react'
import {useLocation, useRouteError} from 'react-router-dom'

import image from '../../../../../web-webapp/webapp/img/errorPages/404.png'
import image2x from '../../../../../web-webapp/webapp/img/errorPages/404@2x.png'
import EntityLink from '../../../containers/EntityLink'
import {
  useBooleanQueryParamState,
  useBuildId,
  useBuildTypeId,
  usePrevLocation,
  useQueryParams,
} from '../../../hooks/routes'
import {extractErrorFromRelay} from '../../../relay/extractErrorFromRelay'
import HTTPError, {HTTPCodesEnum} from '../../../rest/errors/HTTPError'
import {
  getAgentsOverviewHref,
  getAgentTypeHref,
  getFavoriteAgentPoolsHref,
  isAgentsScreen,
} from '../../../routes'
import {toAgentTypeId} from '../../../types'
import BuildTypeNamePlain from '../BuildTypeName/BuildTypeName.container'
import Link from '../Link/Link'
import Popup from '../Popup/Popup.lazy'
import RouterLink from '../RouterLink/RouterLink'

import styles from './ErrorPage.css'

const reload = () => window.location.reload()

export default function ErrorPage() {
  const queryParams = useQueryParams()
  const buildTypeId = useBuildTypeId()
  const buildId = useBuildId()
  const location = useLocation()
  const prevLocation = usePrevLocation()
  const routeError = useRouteError() as Error | null | undefined
  const error = routeError && extractErrorFromRelay(routeError)
  const statusCode = error instanceof HTTPError ? error.statusCode : null
  let errorMessage: string = error?.message ?? ''
  const isNotFound = statusCode === HTTPCodesEnum.NOT_FOUND

  if (isNotFound) {
    if (errorMessage === '') {
      errorMessage = 'Page not found'
    }
  }

  const isHtml = /^</.test(errorMessage.trim())

  const [isReloaded, setIsReloaded] = useBooleanQueryParamState('isReloaded')
  useEffect(() => {
    if (error?.name === 'ChunkLoadError' && !isReloaded) {
      setIsReloaded(true)
      window.location.reload()
    }
  }, [error?.name, isReloaded, setIsReloaded])

  const referrer = IS_STORYBOOK ? '/' : document.referrer

  return (
    <div className={styles.errorPage}>
      <img alt="404" src={image} srcSet={`${image2x} 2x`} className={styles.image} title="Error" />
      <div className={styles.text}>
        {statusCode != null && (
          <H1 caps className={styles.code}>
            {statusCode}
          </H1>
        )}
        <H1 className={styles.title}>
          {isHtml ? (
            <Dropdown
              hoverMode
              anchor={<Anchor className={styles.details}>{'Network error'}</Anchor>}
            >
              <Popup className={styles.detailsPopup}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: errorMessage,
                  }}
                />
              </Popup>
            </Dropdown>
          ) : error instanceof HTTPError ? null : (
            'Something went wrong'
          )}
        </H1>
        {error instanceof HTTPError || (
          <>
            <Dropdown hoverMode anchor={<Anchor className={styles.details}>{'Details'}</Anchor>}>
              <Popup className={styles.detailsPopup}>{errorMessage}</Popup>
            </Dropdown>
            <br />
            <br />
          </>
        )}
        <p className={styles.description}>
          {error instanceof HTTPError && !isHtml && (
            <>
              {errorMessage}
              <br />
              <br />
            </>
          )}
          {isNotFound && 'Check that\u00a0the\u00a0URL is\u00a0correct.'}
          {!isNotFound && <Button onClick={reload}>{'Reload page'}</Button>}
          <br />
          <br />
          {isAgentsScreen(location.pathname) && (
            <>
              <RouterLink to={getAgentsOverviewHref()}>{'Agents overview'}</RouterLink>
              <br />
              <RouterLink to={getFavoriteAgentPoolsHref()}>{'Favorite agents pools'}</RouterLink>
              <br />
              {queryParams.agentTypeId && (
                <>
                  <RouterLink to={getAgentTypeHref(toAgentTypeId(queryParams.agentTypeId))}>
                    {'Cloud image'}
                  </RouterLink>
                  <br />
                </>
              )}
            </>
          )}
          {buildId != null && buildTypeId != null && (
            <>
              <EntityLink buildTypeId={buildTypeId}>
                <BuildTypeNamePlain buildTypeId={buildTypeId} />
              </EntityLink>
              <br />
            </>
          )}
          {prevLocation ? (
            <RouterLink to={`${prevLocation.pathname}${prevLocation.search}`}>
              {'Previous page'}
            </RouterLink>
          ) : (
            referrer && <Link href={referrer}>{'Previous page'}</Link>
          )}
        </p>
      </div>
    </div>
  )
}
