import {base_uri, BS} from '../types/BS_types'

import {objectToQuery} from './queryParams'

export const resolveRelative = (relative: string, params?: {}, hash?: string): string =>
  base_uri +
  relative +
  /* $FlowFixMe This comment suppresses an error found when upgrading Flow to
   * v0.142.0. To view the error, delete this comment and run Flow. */
  (params ? `?${objectToQuery(params)}` : '') +
  (hash != null ? `#${hash}` : '')
export const resolveRelativeCustomBase = (base: string, relative: string, params?: {}): string =>
  /* $FlowFixMe This comment suppresses an error found when upgrading Flow to
   * v0.142.0. To view the error, delete this comment and run Flow. */
  base + relative + (params ? `?${objectToQuery(params)}` : '')
export const escapeFilePathForURL: (arg0: string) => string = path =>
  path.split('/').map(encodeURIComponent).join('/')
export const parseURL = (url: string): HTMLAnchorElement => {
  const parser = document.createElement('a')
  parser.href = url
  return parser
}
const helpUrlPrefix = (BS?.helpUrlPrefix ?? '').replace(/\?$/, '')
export const resolveHelpURL = (page: string): string => `${helpUrlPrefix}${page}`

export function isRelativeRootUrl(url: string | undefined) {
  return url?.startsWith('/')
}
