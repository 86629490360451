/**
 * @generated SignedSource<<eca67b39340d8d9354427ffca7ea4b0e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AgentPoolPageQuery$variables = Record<PropertyKey, never>;
export type AgentPoolPageQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AgentsPagesCanViewAgentDetailsFragment">;
};
export type AgentPoolPageQuery = {
  response: AgentPoolPageQuery$data;
  variables: AgentPoolPageQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentPoolPageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AgentsPagesCanViewAgentDetailsFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AgentPoolPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AgentPools",
        "kind": "LinkedField",
        "name": "agentPoolsRest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dcaab8b1c0185a2f65b6164757065fcc",
    "id": null,
    "metadata": {},
    "name": "AgentPoolPageQuery",
    "operationKind": "query",
    "text": "query AgentPoolPageQuery {\n  ...AgentsPagesCanViewAgentDetailsFragment\n}\n\nfragment AgentsPagesCanViewAgentDetailsFragment on Query {\n  agentPoolsRest {\n    count\n  }\n}\n"
  }
};

(node as any).hash = "cdd3cfe7e51015cd64fa047d27ff3181";

export default node;
