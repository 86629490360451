import Dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown'
import type {PopupAttrs} from '@jetbrains/ring-ui/components/popup/popup'
import {Directions} from '@jetbrains/ring-ui/components/popup/popup.consts'
import {memo} from 'react'

import type {BuildId} from '../../../types'
import Popup from '../Popup/Popup.lazy'

import BuildDurationAnchor from './BuildDurationAnchor/BuildDurationAnchor.container'
import BuildDurationDetails from './BuildDurationDetails/BuildDurationDetails.container'

type Props = {
  buildId: BuildId
  className?: string
  exact?: boolean
  finished?: string | null | undefined
  low?: boolean
  popupProps?: Partial<PopupAttrs>
  showAgent?: boolean
  started?: string | null | undefined
  withBorder?: boolean
}

const DEFAULT_POPUP_PROPS = {
  directions: [Directions.LEFT_BOTTOM],
  left: -8,
} as const

function BuildDuration({
  buildId,
  className,
  exact = false,
  finished,
  low,
  popupProps = DEFAULT_POPUP_PROPS,
  showAgent,
  started,
  withBorder,
}: Props) {
  const anchor = (
    <BuildDurationAnchor
      buildId={buildId}
      className={className}
      exact={exact}
      low={low}
      withBorder={withBorder}
    />
  )

  if (started == null || (exact && finished != null)) {
    return anchor
  }

  return (
    <Dropdown
      anchor={anchor}
      hoverShowTimeOut={300}
      hoverHideTimeOut={300}
      clickMode={false}
      hoverMode
    >
      <Popup {...popupProps}>
        <BuildDurationDetails buildId={buildId} showAgent={showAgent} />
      </Popup>
    </Dropdown>
  )
}

export default memo(BuildDuration)
