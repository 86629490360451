import alert from '@jetbrains/ring-ui/components/alert-service/alert-service'
import {graphql} from 'react-relay'

import {fetchStatusesWithThrottle} from '../../../actions/statuses'
import type {AppThunk} from '../../../actions/types'
import {createAppAsyncThunk} from '../../../reducers/fetchable'
import {fetchQueryOnce} from '../../../relay/fetchQueryOnce'
import getEnvironment from '../../../relay/getEnvironment'
import {getPromoteOptions} from '../../../rest/builds'
import type {TriggerBuildOptions} from '../../../rest/builds'
import {getBuildLocator} from '../../../rest/locators'
import {getStatusKey} from '../../../rest/schemata'
import {shouldAutoExpandQueued} from '../../../selectors'
import type {Branch, RunBuildResponse} from '../../../services/rest'
import {restApi} from '../../../services/rest'
import {queuedTogglerAutoExpand} from '../../../slices'
import {getBuildTypeStatusRequest, stringifyId} from '../../../types'
import type {BuildId, BuildTypeId} from '../../../types'
import {internalProps, BS} from '../../../types/BS_types'
import {noop} from '../../../utils/empty'

import type {RunBuildIsCompositeQuery} from './__generated__/RunBuildIsCompositeQuery.graphql'
import {runBuildApi} from './RunBuild.rest'

const WARNING_TIMEOUT = 5000

const warnIfNoAgents =
  (buildId: BuildId): AppThunk<any> =>
  async dispatch => {
    if (internalProps['teamcity.ui.showNoCompatibleAgentsPopup'] === false) {
      return
    }

    const {build} = await fetchQueryOnce<RunBuildIsCompositeQuery>(
      getEnvironment(),
      graphql`
        query RunBuildIsCompositeQuery($buildLocator: String!) {
          build(buildLocator: $buildLocator) {
            composite
          }
        }
      `,
      {
        buildLocator: getBuildLocator(buildId),
      },
    )

    if (build?.composite) {
      return
    }

    const result = await dispatch(runBuildApi.endpoints.getBuildCanBeRun.initiate(buildId))

    if ('data' in result && result.data != null && !result.data.canRun) {
      alert.warning(
        'No enabled compatible agents for this build configuration.\nPlease register a build agent or tweak build configuration requirements.',
        WARNING_TIMEOUT,
      )
    }
  }

type TriggerBuildArg = {
  buildTypeId: BuildTypeId
  branch: Branch | null | undefined
  options: TriggerBuildOptions
  isCustom?: boolean
}
export function getTriggerBuildKey({buildTypeId, branch}: TriggerBuildArg) {
  const statusRequest = getBuildTypeStatusRequest(buildTypeId, branch)
  return getStatusKey(statusRequest)
}
export const triggerBuild =
  (
    buildTypeId: BuildTypeId,
    branch: Branch | null | undefined,
    options: TriggerBuildOptions,
  ): AppThunk<Promise<RunBuildResponse>> =>
  async (dispatch, getState) => {
    const statusRequest = getBuildTypeStatusRequest(buildTypeId, branch)
    const key = getStatusKey(statusRequest)

    const result = await dispatch(
      restApi.endpoints.runBuild.initiate({buildTypeId, branch, options}),
    )

    if ('error' in result) {
      throw result.error
    }

    const {queuedBuildId} = result.data

    if (queuedBuildId != null) {
      dispatch(warnIfNoAgents(queuedBuildId))
      dispatch(fetchStatusesWithThrottle([statusRequest]))
    }

    if (shouldAutoExpandQueued(getState(), key)) {
      dispatch(queuedTogglerAutoExpand.actions.expand(key))
    }

    return result.data
  }

export const runCustomBuildAction = createAppAsyncThunk<RunBuildResponse, TriggerBuildArg>(
  'runCustomBuild',
  () => new Promise(noop),
)
export const runCustomBuild =
  (
    buildTypeId: BuildTypeId,
    branch: Branch | null | undefined,
    onTriggerBuild: (buildId: BuildId | null) => void,
    openTab: string | null | undefined,
    options: TriggerBuildOptions,
  ): AppThunk<any> =>
  (dispatch, getState) => {
    const {promoteId, stateKey, init, initFromBuild} = options
    const statusRequest = getBuildTypeStatusRequest(buildTypeId, branch)
    const key = getStatusKey(statusRequest)
    const defaultBranch: boolean = branch?.default === true || branch?.groupFlag === true
    const branchName = defaultBranch ? null : branch?.name
    const arg = {buildTypeId, branch, options, isCustom: true}
    BS?.RunBuild?.runCustomBuild(buildTypeId, {
      branchName,

      beforeTrigger() {
        dispatch(runCustomBuildAction.pending('', arg))
      },

      onError(error: Error) {
        dispatch(runCustomBuildAction.rejected(error, '', arg))
        BS?.Log?.error('Something went wrong: ', error)
      },

      isCustomRunDialogForRunButton: openTab != null,
      afterShowDialog:
        openTab != null
          ? () => {
              BS?.RunBuildDialog?.showTab(openTab)
            }
          : undefined,
      init: init === true ? 'true' : null,
      initFromBuild: stringifyId(initFromBuild),
      stateKey,
      ...getPromoteOptions(promoteId),
      onSuccess(itemId) {
        onTriggerBuild(itemId)
        dispatch(
          runCustomBuildAction.fulfilled(
            {
              queuedBuildId: itemId,
              showDialog: false,
            },
            '',
            arg,
          ),
        )
        if (shouldAutoExpandQueued(getState(), key)) {
          dispatch(queuedTogglerAutoExpand.actions.expand(key))
        }

        if (itemId != null) {
          dispatch(warnIfNoAgents(itemId))
          dispatch(fetchStatusesWithThrottle([statusRequest]))
        }
      },
    })
  }
