/**
 * @generated SignedSource<<3a525cedfbbde9bb4c10d679efec39cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuildTestsTabCountsQuery$variables = {
  locator: string;
};
export type BuildTestsTabCountsQuery$data = {
  readonly testOccurrences: {
    readonly testCounters: {
      readonly " $fragmentSpreads": FragmentRefs<"TestScopesViewCountsFragment" | "TestsFilterCountsFragment">;
    } | null | undefined;
  } | null | undefined;
};
export type BuildTestsTabCountsQuery = {
  response: BuildTestsTabCountsQuery$data;
  variables: BuildTestsTabCountsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locator"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "locator",
    "variableName": "locator"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BuildTestsTabCountsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TestOccurrences",
        "kind": "LinkedField",
        "name": "testOccurrences",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TestCounters",
            "kind": "LinkedField",
            "name": "testCounters",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TestScopesViewCountsFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TestsFilterCountsFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BuildTestsTabCountsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TestOccurrences",
        "kind": "LinkedField",
        "name": "testOccurrences",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TestCounters",
            "kind": "LinkedField",
            "name": "testCounters",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "success",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "failed",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "muted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ignored",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "all",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newFailed",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "71db2cb047326114a00195699c91d3cc",
    "id": null,
    "metadata": {},
    "name": "BuildTestsTabCountsQuery",
    "operationKind": "query",
    "text": "query BuildTestsTabCountsQuery(\n  $locator: String!\n) {\n  testOccurrences(locator: $locator) {\n    testCounters {\n      ...TestScopesViewCountsFragment\n      ...TestsFilterCountsFragment\n    }\n  }\n}\n\nfragment TestScopesViewCountsFragment on TestCounters {\n  success\n  failed\n  muted\n  ignored\n}\n\nfragment TestsFilterCountsFragment on TestCounters {\n  all\n  ignored\n  success\n  muted\n  failed\n  newFailed\n}\n"
  }
};
})();

(node as any).hash = "660ac9c8a88dfdce47c3fdd7989da902";

export default node;
