import Dropdown, {Anchor} from '@jetbrains/ring-ui/components/dropdown/dropdown'
import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline'
import classNames from 'classnames'
import * as React from 'react'
import {Suspense} from 'react'
import {graphql, useFragment, useLazyLoadQuery} from 'react-relay'

import {ApprovalStatuses} from '../../../../services/rest'
import type {BuildId} from '../../../../types'
import {useCheckIfUserCanViewApprovalInfo} from '../BuildApproval.hooks'

import type {BuildApprovalDetailsPopup_anchor$key} from './__generated__/BuildApprovalDetailsPopup_anchor.graphql'
import type {BuildApprovalDetailsPopupQuery} from './__generated__/BuildApprovalDetailsPopupQuery.graphql'
import BuildApprovalDetailsPopupContent from './BuildApprovalDetailsPopupContent/BuildApprovalDetailsPopupContent'

import styles from './BuildApprovalDetailsPopup.css'

const anchorFragment = graphql`
  fragment BuildApprovalDetailsPopup_anchor on ApprovalInfo {
    status
  }
`

function BuildApprovalAnchor({
  approvalInfoKey,
}: {
  approvalInfoKey: BuildApprovalDetailsPopup_anchor$key | null
}) {
  const approvalInfo = useFragment(anchorFragment, approvalInfoKey)
  return (
    <Anchor
      className={classNames({
        [styles.awaits]: approvalInfo?.status === ApprovalStatuses.WAITING_FOR_APPROVAL,
        [styles.refused]: approvalInfo?.status === ApprovalStatuses.TIMED_OUT,
      })}
    >
      <span>{'Requires approval'}</span>
    </Anchor>
  )
}

const query = graphql`
  query BuildApprovalDetailsPopupQuery($locator: String!) {
    approvalInfo(buildLocator: $locator) {
      status
      ...BuildApprovalReviewers_canViewApprovalInfo
      ...BuildApprovalDetailsPopup_anchor
      ...BuildApprovalDetailsPopupContent_approvalInfo
    }
  }
`

type Props = {
  buildId: BuildId
}

function BuildApprovalDetailsPopup({buildId}: Props) {
  const {approvalInfo = null} = useLazyLoadQuery<BuildApprovalDetailsPopupQuery>(query, {
    locator: `id:${buildId}`,
  })
  const canViewApprovalInfo = useCheckIfUserCanViewApprovalInfo(approvalInfo)

  if (approvalInfo?.status === ApprovalStatuses.APPROVED) {
    return <div>{'Build has been approved'}</div>
  }

  if (!canViewApprovalInfo) {
    return <span className={styles.readonly}>{'Requires approval'}</span>
  }

  return (
    <div>
      <Dropdown hoverMode anchor={<BuildApprovalAnchor approvalInfoKey={approvalInfo} />}>
        <BuildApprovalDetailsPopupContent buildId={buildId} approvalInfoKey={approvalInfo} />
      </Dropdown>
    </div>
  )
}

export default function BuildApprovalDetailsPopupContainer(props: Props) {
  return (
    <Suspense
      fallback={
        <span>
          {'Loading '}
          <LoaderInline />
        </span>
      }
    >
      <BuildApprovalDetailsPopup {...props} />
    </Suspense>
  )
}
