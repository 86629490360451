import {useEffect} from 'react'
import type {To} from 'react-router'
import {useNavigate} from 'react-router-dom'

type Props = {
  to: To
  replace?: boolean
}
export default function Redirect({to, replace}: Props) {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(to, {replace})
  })
  return null
}
