import {AlertType} from '@jetbrains/ring-ui/components/alert/alert'
import type {ReactNode} from 'react'
import * as React from 'react'
import {useState} from 'react'
import {ErrorBoundary as ReactErrorBoundary} from 'react-error-boundary'
import type {ErrorBoundaryPropsWithRender} from 'react-error-boundary/dist/declarations/src/types'

import type {ServiceMessageType} from '../ServiceMessage/ServiceMessage'
import ServiceMessage from '../ServiceMessage/ServiceMessage'

type Props = Partial<ErrorBoundaryPropsWithRender> & {
  children?: ReactNode
  messageProps?: ServiceMessageType
}

export default function ErrorBoundary({
  fallbackRender,
  children,
  messageProps,
  onError,
  ...restProps
}: Props) {
  const [showMessage, setShowMessage] = useState(false)

  return (
    <ReactErrorBoundary
      fallbackRender={
        fallbackRender ??
        (({error}) =>
          showMessage && (
            <ServiceMessage
              type={AlertType.ERROR}
              message={error.message}
              onCloseRequest={() => setShowMessage(false)}
              {...messageProps}
            />
          ))
      }
      onError={(error, info) => {
        setShowMessage(true)
        onError?.(error, info)
      }}
      {...restProps}
    >
      {children}
    </ReactErrorBoundary>
  )
}
