import {restBaseApi} from '../../../services/restBase'
import type {BuildId} from '../../../types'

type GetBuildCanBeRunResponse = {canRun: boolean}

export const runBuildApi = restBaseApi.injectEndpoints({
  endpoints: build => ({
    getBuildCanBeRun: build.query<GetBuildCanBeRunResponse, BuildId>({
      query: buildId => ({
        url: '/overview',
        params: {buildCanBeRun: true, buildId},
      }),
    }),
  }),
})
