import {castDraft} from 'immer'

import {createReducer} from '@reduxjs/toolkit'
import * as Redux from 'redux'

import {restApi} from '../../../../services/rest'

import type {BuildProblemsTree} from './BuildProblems.types'

export type BuildProblemsSubtree = {
  depth?: number
  data: BuildProblemsTree
}

const buildProblemsReducer = Redux.combineReducers({
  buildProblemsSubtree: createReducer<Record<string, Record<string, BuildProblemsSubtree>>>(
    {},
    builder => {
      builder.addMatcher(restApi.endpoints.getBuildProblemsTree.matchFulfilled, (state, action) => {
        const {treeLocator, subTreeKey, depth} = action.meta.arg.originalArgs
        if (treeLocator != null && subTreeKey != null) {
          state[treeLocator] ??= {}
          state[treeLocator]![subTreeKey] = {depth, data: castDraft(action.payload.tree)}
        }
      })
    },
  ),
})
export default buildProblemsReducer
